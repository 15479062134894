/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createEnrichM2MResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type EnrichM2MResponse, type ErrorResponse, type InternalErrorResponse } from '../../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/auth/m2m/enrich
 */
export interface EnrichRequestBuilder extends BaseRequestBuilder<EnrichRequestBuilder> {
    /**
     * This endpoint is responsible for enriching M2M data. It returns an organization id.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<EnrichM2MResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<EnrichM2MResponse | undefined>;
    /**
     * This endpoint is responsible for enriching M2M data. It returns an organization id.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const EnrichRequestBuilderUriTemplate = "{+baseurl}/api/auth/m2m/enrich";
/**
 * Metadata for all the requests in the request builder.
 */
export const EnrichRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: EnrichRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createEnrichM2MResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
