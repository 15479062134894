import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { useAuthStore } from '@/stores/authStore';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'e-commerce',
                    meta: {
                        breadcrumb: ['Dashboard'],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/dashboards/Ecommerce.vue'),
                },
                {
                    path: '/customers',
                    name: 'customers',
                    meta: {
                        breadcrumb: ['Customers'],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/customers/Customers.vue'),
                },
                {
                    path: '/customers/details/:customerNumber',
                    name: 'customer-details',
                    meta: {
                        breadcrumb: ['Customers', 'Details'],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/customers/CustomerDetails.vue'),
                },
                {
                    path: '/products',
                    name: 'products',
                    meta: {
                        breadcrumb: ['Products'],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/products/Products.vue'),
                },
                {
                    path: '/sales-reps',
                    name: 'sales-reps',
                    meta: {
                        breadcrumb: ['Sales Reps'],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/sales-reps/SalesReps.vue'),
                },
                {
                    path: '/api-access',
                    name: 'api-access',
                    meta: {
                        breadcrumb: ['API Access'],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/api-access/ApiAccess.vue'),
                },

                {
                    path: 'data',
                    name: 'data',
                    meta: {
                        breadcrumb: ['Manage Data'],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/data/ImportData.vue'),
                },

    
                {
                    path: '/pages/empty',
                    name: 'empty',
                    component: () => import('@/views/pages/Empty.vue')
                },

            ]
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/views/pages/Landing.vue')
        },
        {
            path: '/sign-up',
            name: 'sign-up',
            component: () => import('@/views/pages/Sign-Up.vue')
        },
        {
            path: '/pages/notfound',
            name: 'notfound',
            component: () => import('@/views/pages/NotFound.vue')
        },
        {
            path: '/auth/callback',
            name: 'auth-callback',
            component: () => import('@/views/pages/auth/AuthCallback.vue')
        },
        {
            path: '/auth/login',
            name: 'login',
            component: () => import('@/views/pages/auth/Login.vue')
        },
        {
            path: '/auth/access',
            name: 'accessDenied',
            component: () => import('@/views/pages/auth/Access.vue')
        },
        {
            path: '/auth/download-app',
            name: 'downloadApp',
            component: () => import('@/views/pages/auth/DownloadApp.vue')
        },
        {
            path: '/auth/register',
            name: 'register',
            component: () => import('@/views/pages/auth/Register.vue')
        },
        {
            path: '/auth/forgotpassword',
            name: 'forgotpassword',
            component: () => import('@/views/pages/auth/ForgotPassword.vue')
        },
        {
            path: '/auth/newpassword',
            name: 'newpassword',
            component: () => import('@/views/pages/auth/NewPassword.vue')
        },
        {
            path: '/auth/verification',
            name: 'verification',
            component: () => import('@/views/pages/auth/Verification.vue')
        },
        {
            path: '/auth/lockscreen',
            name: 'lockscreen',
            component: () => import('@/views/pages/auth/LockScreen.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'notfound',
            component: () => import('@/views/pages/NotFound.vue')
        }
    ],
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});


// Navigation Guard
router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    if (to.meta.requiresAuth && !authStore.isAuthenticated) {
        return next('/home'); // Redirect to login if not authenticated
        // return authStore.login();
    }
    if (to.meta.requiresAdmin && !authStore.admin) {
        return next('/auth/download-app'); // Redirect to login if not authenticated
    }
    next();
});

export default router;
