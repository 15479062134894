/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createReadmeAuthResponseFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type ReadmeAuthResponse } from '../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/auth/readme
 */
export interface ReadmeRequestBuilder extends BaseRequestBuilder<ReadmeRequestBuilder> {
    /**
     * Returns a URL that authenticates the user with Readme.com and enables use of their JWT token for API access.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ReadmeAuthResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ReadmeAuthResponse | undefined>;
    /**
     * Returns a URL that authenticates the user with Readme.com and enables use of their JWT token for API access.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const ReadmeRequestBuilderUriTemplate = "{+baseurl}/api/auth/readme";
/**
 * Metadata for all the requests in the request builder.
 */
export const ReadmeRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: ReadmeRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createReadmeAuthResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
