/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createOkFromDiscriminatorValue, serializeAddTermRequest, serializeDeleteTermRequest, serializeOk, type AddTermRequest, type DeleteTermRequest, type ErrorResponse, type InternalErrorResponse, type Ok } from '../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/terms
 */
export interface TermsRequestBuilder extends BaseRequestBuilder<TermsRequestBuilder> {
    /**
     * Delete Term.  Acceptable values are: NET15, NET30, NET45, NET60, NET90, NET120, CREDIT, CASH, COD, PREPAID
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     */
     delete(body: DeleteTermRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * Add a new Term for an Organization.  Acceptable values are: NET15, NET30, NET45, NET60, NET90, NET120, CREDIT, CASH, COD, PREPAID
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: AddTermRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * Delete Term.  Acceptable values are: NET15, NET30, NET45, NET60, NET90, NET120, CREDIT, CASH, COD, PREPAID
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toDeleteRequestInformation(body: DeleteTermRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Add a new Term for an Organization.  Acceptable values are: NET15, NET30, NET45, NET60, NET90, NET120, CREDIT, CASH, COD, PREPAID
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: AddTermRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const TermsRequestBuilderUriTemplate = "{+baseurl}/api/organizations/terms";
/**
 * Metadata for all the requests in the request builder.
 */
export const TermsRequestBuilderRequestsMetadata: RequestsMetadata = {
    delete: {
        uriTemplate: TermsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeDeleteTermRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
    post: {
        uriTemplate: TermsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeAddTermRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
