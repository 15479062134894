/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCompleteCheckoutResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, serializeCompleteCheckoutRequest, serializeCompleteCheckoutResponse, type CompleteCheckoutRequest, type CompleteCheckoutResponse, type ErrorResponse, type InternalErrorResponse } from '../../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/carts/{customerNumber-id}/complete-checkout
 */
export interface CompleteCheckoutRequestBuilder extends BaseRequestBuilder<CompleteCheckoutRequestBuilder> {
    /**
     * Create an order and change the status of the shopping cart to closed.
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<CompleteCheckoutResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {ErrorResponse} error when the service returns a 409 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: CompleteCheckoutRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<CompleteCheckoutResponse | undefined>;
    /**
     * Create an order and change the status of the shopping cart to closed.
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: CompleteCheckoutRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const CompleteCheckoutRequestBuilderUriTemplate = "{+baseurl}/api/carts/{customerNumber%2Did}/complete-checkout";
/**
 * Metadata for all the requests in the request builder.
 */
export const CompleteCheckoutRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: CompleteCheckoutRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            409: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCompleteCheckoutResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCompleteCheckoutRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
