<script setup lang="ts">

import { ref } from 'vue';

import { useLayout } from '@/layout/composables/layout';
import { useAuthStore } from '@/stores/authStore';
import { handleGlobalError } from '@/composables/globalErrorHandler';
import { useRouter } from 'vue-router';

const visibleFull = ref(false);
const authStore = useAuthStore()
const { layoutState } = useLayout();
const router = useRouter();

const logout = async () => {
    await authStore.logout();
}

const manageSubscriptionURL = ref('');
const manageSubscription = async () => {
    layoutState.profileSidebarVisible = false;
    try {
        const response = await authStore.getPortalSessionLink();
        manageSubscriptionURL.value = response?.sessionUrl ?? '';
        visibleFull.value = true;
    }
    catch (error) {
        handleGlobalError(error);
    }
}

const initializePendo = () => {
    window.pendo.initialize({
        visitor: {
            id: authStore.user?.id,
            email: authStore.user?.email,
            firstName: authStore.user?.given_name,
            lastName: authStore.user?.family_name,
        },
        account: {
            id: authStore.user?.id,
            accountName: authStore.user?.email,
            payingStatus: 'Free',
            businessTier: 'Free',
            industry: 'Tech',
            location: 'US',
        }
    });
}
initializePendo();

const goToApiAccess = (event: Event) =>{
    event.preventDefault();
    layoutState.profileSidebarVisible = false;
    router.push({ name: 'api-access' });
}

</script>

<template>
    <Drawer v-model:visible="visibleFull" :baseZIndex="1000" position="full">
        <iframe :src="manageSubscriptionURL" style="width: 100%; height: 100%; border: none;"></iframe>
    </Drawer>
    <Drawer v-model:visible="layoutState.profileSidebarVisible" position="right"
        class="layout-profile-sidebar w-full sm:w-[25rem]">
        <div class="flex flex-col mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Welcome</span>
            <span class="text-surface-500 dark:text-surface-400 font-medium mb-8">{{ authStore.user?.given_name }}
                {{ authStore.user?.family_name }}</span>

            <ul class="list-none m-0 p-0">
                <li>
                    <a
                        class="cursor-pointer flex mb-4 p-4 items-center border border-surface-200 dark:border-surface-700 rounded hover:bg-surface-100 dark:hover:bg-surface-800 transition-colors duration-150">
                        <span>
                            <i class="pi pi-user text-xl text-primary"></i>
                        </span>
                        <div class="ml-4">
                            <span class="mb-2 font-semibold">Profile</span>
                            <p class="text-surface-500 dark:text-surface-400 m-0">Edit Your Profile</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex mb-4 p-4 items-center border border-surface-200 dark:border-surface-700 rounded hover:bg-surface-100 dark:hover:bg-surface-800 transition-colors duration-150"
                        @click="manageSubscription">
                        <span>
                            <i class="pi pi-credit-card text-xl text-primary"></i>
                        </span>
                        <div class="ml-4">
                            <span class="mb-2 font-semibold">Manage Subscription</span>
                        </div>
                    </a>
                </li>
                <li>
                    <router-link to="/api-access" @click.native="goToApiAccess" class="cursor-pointer flex mb-4 p-4 items-center border border-surface-200 dark:border-surface-700 rounded hover:bg-surface-100 dark:hover:bg-surface-800 transition-colors duration-150"
                        >
                        <span>
                            <i class="pi pi-cog text-xl text-primary"></i>
                        </span>
                        <div class="ml-4">
                            <span class="mb-2 font-semibold">API Access</span>
                        </div>
                    </router-link>
                </li>
                <li>
                    <a class="cursor-pointer flex mb-4 p-4 items-center border border-surface-200 dark:border-surface-700 rounded hover:bg-surface-100 dark:hover:bg-surface-800 transition-colors duration-150"
                        @click="logout">
                        <span>
                            <i class="pi pi-power-off text-xl text-primary"></i>
                        </span>
                        <div class="ml-4">
                            <span class="mb-2 font-semibold">Sign Out</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </Drawer>
</template>
