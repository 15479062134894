/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse } from '../../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/customers/{customerNumber}/deactivate
 */
export interface DeactivateRequestBuilder extends BaseRequestBuilder<DeactivateRequestBuilder> {
    /**
     * Deactivate a Customer by CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     patch(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * Deactivate a Customer by CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPatchRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const DeactivateRequestBuilderUriTemplate = "{+baseurl}/api/customers/{customerNumber}/deactivate";
/**
 * Metadata for all the requests in the request builder.
 */
export const DeactivateRequestBuilderRequestsMetadata: RequestsMetadata = {
    patch: {
        uriTemplate: DeactivateRequestBuilderUriTemplate,
        responseBodyContentType: "application/json, application/problem+json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendNoResponseContent",
    },
};
/* tslint:enable */
/* eslint-enable */
