import { ref, computed, UnwrapRef, Ref } from 'vue';

import { defineStore } from 'pinia';
import { getKindeClient } from '@/utils/kindeAuth'; // Import the method to get the initialized Kinde client
import { GetOrganizationResponse, ListUserOrganizationsResponse, Organization } from '@/api-client/models';
import { ApiClientFactory } from '@/stores/apiClientFactory';
import { saveCurrentOrg, getCurrentOrg } from '@/stores/localStorageService';
import { handleGlobalError } from '@/composables/globalErrorHandler';

export const useAuthStore = defineStore('auth', () => {
  // State
  const isAuthenticated = ref(false);
  const user = ref<any | null>(null);

  const organizations = ref<Array<Organization>>([]);
  const organization = ref<GetOrganizationResponse>({});
  const roles = ref<Array<string>>([]);
  const admin = ref(false);
  const accessToken: Ref<UnwrapRef<string>> = ref('');
  const currentOrgId = ref('');
  const currentOrg = ref('');

  const clientFactory = new ApiClientFactory();
  const client = clientFactory.createClient();


  // Actions
  const checkAuth = async () => {
    try {
      const kindeClient = getKindeClient();
      // Fetch user information
      const fetchedUser = await kindeClient.getUser();
      user.value = fetchedUser;
      isAuthenticated.value = !!fetchedUser;

      //added from previous iteration
      accessToken.value = <string>await kindeClient.getToken();

      currentOrgId.value = <string>kindeClient.getClaim("org_code")?.value;
      currentOrg.value = <string>kindeClient.getClaim("org_name")?.value;
      const roles: any = kindeClient.getClaim("roles")?.value;

      for (const role of roles) {
        if (role.key === 'admin') {
          admin.value = true;
          break;
        }
      }


    } catch (error) {
      console.error('Authentication error:', error);
      isAuthenticated.value = false;
      user.value = null;
    }
  };

  const login = async () => {
    const kindeClient = getKindeClient();
    // Start the login flow (correct method for PKCE SDK)
    await kindeClient.login({
      app_state: {
        redirectTo: window.location
      }
    }); // Correct method
  };

  const logout = async () => {
    const kindeClient = getKindeClient();
    // Logout and clear the user session
    await kindeClient.logout();
    isAuthenticated.value = false;
    user.value = null;
  };

  // Handle the redirect callback after login
  const handleRedirectCallback = async () => {
    try {
      const kindeClient = getKindeClient();
      // Parse the redirect to extract the user data and tokens
      const response = await kindeClient.handleRedirectCallback();
      if (response.user) {
        user.value = response.user;
        isAuthenticated.value = true;
      }
    } catch (error) {
      console.error('Error handling redirect callback:', error);
    }
  };

  // Getters
  const getUser = computed(() => user.value);

  const getAuthorizationToken = async () => {
    return accessToken.value;
  };

  const getPortalSessionLink = async () => {
    try {
        const response = await client.api.organizations.subscriptions.portalSessionLink.get();
        return response;
    } catch (error: any) {
        handleGlobalError(error);
    }
};

const getReadmeLoginUrl = async () => {
    try {
        const response = await client.api.auth.readme.get();
        return response;
    } catch (error: any) {
        handleGlobalError(error);
    }
};
const changeOrganization = (org_id: string) => {
  //TODO: verify this 
  saveCurrentOrg(org_id);
  currentOrgId.value = org_id;
  getOrganization();
};
const getOrganization = async () => {
  try {
      const response = await client.api.organization.get();
      console.log('getOrganization:', response);
      organization.value = response?.organization ?? {};
  } catch (error: any) {
      handleGlobalError(error);
  }
};

const getOrganizations = async () => {
  try {
      const response: ListUserOrganizationsResponse | undefined = await client.api.users.organizations.get();
      organizations.value = response?.organizations ?? [];
      console.log('getOrganizations:', response);

      currentOrgId.value = organizations.value[0].identityOrganizationId ?? '';
      saveCurrentOrg(currentOrgId.value);
  } catch (error: any) {
      handleGlobalError(error);
  }
};

  return {
    isAuthenticated,
    user,
    admin,
    checkAuth,
    login,
    logout,
    handleRedirectCallback,
    getUser,
    getAuthorizationToken,
    getPortalSessionLink,
    getReadmeLoginUrl,
    changeOrganization,
    currentOrg,
    currentOrgId

  };
});
