/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCreateOrderResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createPagedResultOfIOrderFromDiscriminatorValue, serializeCreateOrderRequest, serializeCreateOrderResponse, type CreateOrderRequest, type CreateOrderResponse, type ErrorResponse, type InternalErrorResponse, type PagedResultOfIOrder } from '../../models/index.js';
// @ts-ignore
import { OrdersItemRequestBuilderNavigationMetadata, OrdersItemRequestBuilderRequestsMetadata, type OrdersItemRequestBuilder } from './item/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/orders
 */
export interface OrdersRequestBuilder extends BaseRequestBuilder<OrdersRequestBuilder> {
    /**
     * Gets an item from the MomentumAPI.api.orders.item collection
     * @param id Unique identifier of the item
     * @returns {OrdersItemRequestBuilder}
     */
     byId(id: string) : OrdersItemRequestBuilder;
    /**
     * Return a list of orders applying a list of filters
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<PagedResultOfIOrder>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<OrdersRequestBuilderGetQueryParameters> | undefined) : Promise<PagedResultOfIOrder | undefined>;
    /**
     * Create an order
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<CreateOrderResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {ErrorResponse} error when the service returns a 409 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: CreateOrderRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<CreateOrderResponse | undefined>;
    /**
     * Return a list of orders applying a list of filters
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<OrdersRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
    /**
     * Create an order
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: CreateOrderRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Return a list of orders applying a list of filters
 */
export interface OrdersRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the filters for the request, this is optionalAcceptable operators are: starts with, contains, notcontains, endswith, equals, notequals, gt, get, lt, lte
     */
    filters?: string[];
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    /**
     * Gets or sets the field to sort on. Default value is "CreatedAt".
     */
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const OrdersRequestBuilderUriTemplate = "{+baseurl}/api/orders?filters={filters}&pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const OrdersRequestBuilderNavigationMetadata: Record<Exclude<keyof OrdersRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byId: {
        requestsMetadata: OrdersItemRequestBuilderRequestsMetadata,
        navigationMetadata: OrdersItemRequestBuilderNavigationMetadata,
        pathParametersMappings: ["id"],
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const OrdersRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: OrdersRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createPagedResultOfIOrderFromDiscriminatorValue,
    },
    post: {
        uriTemplate: OrdersRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            409: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCreateOrderResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateOrderRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
