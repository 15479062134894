<script setup lang="ts">
import { ref } from 'vue';

import AppSubMenu from './AppSubMenu.vue';

const model = ref([
    {
        items: [
            {
                label: 'Dashboard',
                icon: 'pi pi-fw pi-home',
                to: '/'
            },
            {
                label: 'Customers',
                icon: 'pi pi-fw pi-users',
                to: '/customers'
            },
            {
                label: 'Products',
                icon: 'pi pi-fw pi-list',
                to: '/products'
            }
            ,
            {
                label: 'Sales Reps',
                icon: 'pi pi-fw pi-user',
                to: '/sales-reps'
            }
        ]
    },
]);
</script>

<template>
    <AppSubMenu :model="model" />
</template>

<style lang="scss" scoped></style>
