/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCheckoutPageResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type CheckoutPageResponse, type ErrorResponse, type InternalErrorResponse } from '../../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/subscriptions/checkout-page
 */
export interface CheckoutPageRequestBuilder extends BaseRequestBuilder<CheckoutPageRequestBuilder> {
    /**
     * Gets the checkout page for the Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<CheckoutPageResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<CheckoutPageRequestBuilderGetQueryParameters> | undefined) : Promise<CheckoutPageResponse | undefined>;
    /**
     * Gets the checkout page for the Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<CheckoutPageRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Gets the checkout page for the Organization
 */
export interface CheckoutPageRequestBuilderGetQueryParameters {
    /**
     * The number of users for the subscription
     */
    numberOfUsers?: number;
    /**
     * The unique identifier for the subscription plan
     */
    planId?: string;
}
/**
 * Uri template for the request builder.
 */
export const CheckoutPageRequestBuilderUriTemplate = "{+baseurl}/api/organizations/subscriptions/checkout-page?numberOfUsers={numberOfUsers}&planId={planId}";
/**
 * Metadata for all the requests in the request builder.
 */
export const CheckoutPageRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: CheckoutPageRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCheckoutPageResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
