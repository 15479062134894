/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createShoppingCartFromDiscriminatorValue, serializeAddItemRequest, serializeShoppingCart, type AddItemRequest, type ErrorResponse, type InternalErrorResponse, type ShoppingCart } from '../../../../../models/index.js';
// @ts-ignore
import { type WithSkuItemRequestBuilder, WithSkuItemRequestBuilderRequestsMetadata } from './item/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/carts/{customerNumber-id}/{shoppingCartId}/items
 */
export interface ItemsRequestBuilder extends BaseRequestBuilder<ItemsRequestBuilder> {
    /**
     * Gets an item from the MomentumAPI.api.carts.item.item.items.item collection
     * @param sku Gets or sets the SKU of the product to be updated in the cart.
     * @returns {WithSkuItemRequestBuilder}
     */
     bySku(sku: string) : WithSkuItemRequestBuilder;
    /**
     * Add an item to a ShoppingCart
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ShoppingCart>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: AddItemRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ShoppingCart | undefined>;
    /**
     * Add an item to a ShoppingCart
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: AddItemRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const ItemsRequestBuilderUriTemplate = "{+baseurl}/api/carts/{customerNumber%2Did}/{shoppingCartId}/items";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const ItemsRequestBuilderNavigationMetadata: Record<Exclude<keyof ItemsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    bySku: {
        requestsMetadata: WithSkuItemRequestBuilderRequestsMetadata,
        pathParametersMappings: ["sku"],
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const ItemsRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: ItemsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createShoppingCartFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeAddItemRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
