import router from '@/router';
import createKindeClient from '@kinde-oss/kinde-auth-pkce-js';

let kindeClient: any;

export const initializeKindeClient = async () => {
  kindeClient = await createKindeClient({
    client_id: process.env.VITE_AUTH_CLIENTID,
    domain: process.env.VITE_AUTH_DOMAIN as string,
    redirect_uri: window.location.origin + '/auth/callback',
    logout_uri: import.meta.env.VITE_BASE_URL,
    audience: process.env.VITE_AUTH_API_AUDIENCE,
    is_dangerously_use_local_storage: true,
    on_redirect_callback: (user, appState) => {
      if (user) {
      } else {
        // render logged out view
        console.log('Login Fail')
        setTimeout(() => {
          router.push('/home')
        }, 5000);
      }
    }
  });
};

export const getKindeClient = () => kindeClient;



