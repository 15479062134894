/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createUntypedNodeFromDiscriminatorValue, type AdditionalDataHolder, type ApiError, type Parsable, type ParseNode, type SerializationWriter, type UntypedNode } from '@microsoft/kiota-abstractions';

export interface AccountSetup extends Parsable {
    /**
     * The isComplete property
     */
    isComplete?: boolean | null;
    /**
     * The nextStep property
     */
    nextStep?: Step | null;
    /**
     * The percentageComplete property
     */
    percentageComplete?: number | null;
    /**
     * The steps property
     */
    steps?: Step[] | null;
}
export interface ActivateSalesRepRequest extends Parsable {
    /**
     * OrganizationSalesRepId 
     */
    organizationSalesRepId?: string | null;
}
export interface AddItemRequest extends Parsable {
    /**
     * The quantity property
     */
    quantity?: number | null;
    /**
     * The sku property
     */
    sku?: string | null;
}
export interface AddPriceTierRequest extends Parsable {
    /**
     * The currencyCode property
     */
    currencyCode?: string | null;
    /**
     * The name property
     */
    name?: string | null;
}
export interface Address extends Parsable {
    /**
     * The address1 property
     */
    address1?: string | null;
    /**
     * The address2 property
     */
    address2?: string | null;
    /**
     * The addressType property
     */
    addressType?: AddressType | null;
    /**
     * The city property
     */
    city?: string | null;
    /**
     * The code property
     */
    code?: string | null;
    /**
     * The country property
     */
    country?: string | null;
    /**
     * The default property
     */
    defaultEscaped?: boolean | null;
    /**
     * The externalId property
     */
    externalId?: string | null;
    /**
     * The latitude property
     */
    latitude?: number | null;
    /**
     * The longitude property
     */
    longitude?: number | null;
    /**
     * The stateProvince property
     */
    stateProvince?: string | null;
    /**
     * The zipPostalCode property
     */
    zipPostalCode?: string | null;
}
export type AddressType = (typeof AddressTypeObject)[keyof typeof AddressTypeObject];
export interface AddTermRequest extends Parsable {
    /**
     * The term property
     */
    term?: Term | null;
}
export interface ApiAccess extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The clientId property
     */
    clientId?: string | null;
    /**
     * The clientSecret property
     */
    clientSecret?: string | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The orgCode property
     */
    orgCode?: string | null;
}
/**
 * Represents a request for API authentication.
 */
export interface ApiAuthenticationRequest extends Parsable {
    /**
     * The clientId property
     */
    clientId?: string | null;
    /**
     * The clientSecret property
     */
    clientSecret?: string | null;
    /**
     * The orgCode property
     */
    orgCode?: string | null;
}
export interface ApiAuthenticationResponse extends Parsable {
    /**
     * The accessToken property
     */
    accessToken?: string | null;
    /**
     * The expiresIn property
     */
    expiresIn?: number | null;
    /**
     * The tokenType property
     */
    tokenType?: string | null;
}
export interface BaseEntity extends Parsable {
    /**
     * The active property
     */
    active?: boolean | null;
    /**
     * The createdAt property
     */
    createdAt?: Date | null;
    /**
     * The updatedAt property
     */
    updatedAt?: Date | null;
}
export interface Card extends Parsable {
    /**
     * The card_type property
     */
    cardType?: string | null;
    /**
     * The created_at property
     */
    createdAt?: number | null;
    /**
     * The customer_id property
     */
    customerId?: string | null;
    /**
     * The expiry_month property
     */
    expiryMonth?: number | null;
    /**
     * The expiry_year property
     */
    expiryYear?: number | null;
    /**
     * The funding_type property
     */
    fundingType?: string | null;
    /**
     * The gateway property
     */
    gateway?: string | null;
    /**
     * The gateway_account_id property
     */
    gatewayAccountId?: string | null;
    /**
     * The iin property
     */
    iin?: number | null;
    /**
     * The ip_address property
     */
    ipAddress?: string | null;
    /**
     * The last4 property
     */
    last4?: number | null;
    /**
     * The masked_number property
     */
    maskedNumber?: string | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The payment_source_id property
     */
    paymentSourceId?: string | null;
    /**
     * The resource_version property
     */
    resourceVersion?: number | null;
    /**
     * The status property
     */
    status?: string | null;
    /**
     * The updated_at property
     */
    updatedAt?: number | null;
}
export interface CartItem extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The imageName property
     */
    imageName?: string | null;
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number | null;
    /**
     * The price property
     */
    price?: number | null;
    /**
     * The priceFormatted property
     */
    priceFormatted?: string | null;
    /**
     * The productName property
     */
    productName?: string | null;
    /**
     * The quantity property
     */
    quantity?: number | null;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number | null;
    /**
     * The sku property
     */
    sku?: string | null;
    /**
     * The subTotal property
     */
    subTotal?: number | null;
    /**
     * The subTotalFormatted property
     */
    subTotalFormatted?: string | null;
}
export interface CheckoutPageResponse extends Parsable {
    /**
     * The createdAt property
     */
    createdAt?: number | null;
    /**
     * The embed property
     */
    embed?: boolean | null;
    /**
     * The expiresAt property
     */
    expiresAt?: number | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The state property
     */
    state?: string | null;
    /**
     * The type property
     */
    type?: string | null;
    /**
     * The url property
     */
    url?: string | null;
}
export interface CompleteCheckoutRequest extends Parsable {
    /**
     * The channel property
     */
    channel?: string | null;
    /**
     * The customerNumber property
     */
    customerNumber?: string | null;
    /**
     * The userType property
     */
    userType?: UserTypes | null;
}
export interface CompleteCheckoutResponse extends Parsable {
    /**
     * The confirmationId property
     */
    confirmationId?: string | null;
    /**
     * The orderNumber property
     */
    orderNumber?: string | null;
}
export interface ConfigResponse extends Parsable {
    /**
     * The mapsApiKey property
     */
    mapsApiKey?: string | null;
}
export interface Contact extends Parsable {
    /**
     * The contactType property
     */
    contactType?: ContactType | null;
    /**
     * The default property
     */
    defaultEscaped?: boolean | null;
    /**
     * The emailAddress property
     */
    emailAddress?: string | null;
    /**
     * The externalId property
     */
    externalId?: string | null;
    /**
     * The fax property
     */
    fax?: string | null;
    /**
     * The firstName property
     */
    firstName?: string | null;
    /**
     * The fullName property
     */
    fullName?: string | null;
    /**
     * The lastName property
     */
    lastName?: string | null;
    /**
     * The phone property
     */
    phone?: string | null;
    /**
     * The title property
     */
    title?: string | null;
}
export type ContactType = (typeof ContactTypeObject)[keyof typeof ContactTypeObject];
export interface Content extends Parsable {
    /**
     * The card property
     */
    card?: Card | null;
    /**
     * The customer property
     */
    customer?: Customer | null;
    /**
     * The invoice property
     */
    invoice?: Invoice | null;
    /**
     * The subscription property
     */
    subscription?: Subscription2 | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {AccountSetup}
 */
// @ts-ignore
export function createAccountSetupFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAccountSetup;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ActivateSalesRepRequest}
 */
// @ts-ignore
export function createActivateSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoActivateSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {AddItemRequest}
 */
// @ts-ignore
export function createAddItemRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAddItemRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {AddPriceTierRequest}
 */
// @ts-ignore
export function createAddPriceTierRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAddPriceTierRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Address}
 */
// @ts-ignore
export function createAddressFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAddress;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {AddTermRequest}
 */
// @ts-ignore
export function createAddTermRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAddTermRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ApiAccess}
 */
// @ts-ignore
export function createApiAccessFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoApiAccess;
}
export interface CreateApiAccessResponse extends Parsable {
    /**
     * The apiAccess property
     */
    apiAccess?: ApiAccess | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ApiAuthenticationRequest}
 */
// @ts-ignore
export function createApiAuthenticationRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoApiAuthenticationRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ApiAuthenticationResponse}
 */
// @ts-ignore
export function createApiAuthenticationResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoApiAuthenticationResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {BaseEntity}
 */
// @ts-ignore
export function createBaseEntityFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoBaseEntity;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Card}
 */
// @ts-ignore
export function createCardFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCard;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CartItem}
 */
// @ts-ignore
export function createCartItemFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCartItem;
}
export interface CreateCartRequest extends Parsable {
    /**
     * The orderType property
     */
    orderType?: string | null;
    /**
     * The quantity property
     */
    quantity?: number | null;
    /**
     * The sku property
     */
    sku?: string | null;
    /**
     * The userId property
     */
    userId?: string | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CheckoutPageResponse}
 */
// @ts-ignore
export function createCheckoutPageResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCheckoutPageResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CompleteCheckoutRequest}
 */
// @ts-ignore
export function createCompleteCheckoutRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCompleteCheckoutRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CompleteCheckoutResponse}
 */
// @ts-ignore
export function createCompleteCheckoutResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCompleteCheckoutResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ConfigResponse}
 */
// @ts-ignore
export function createConfigResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoConfigResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Contact}
 */
// @ts-ignore
export function createContactFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoContact;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Content}
 */
// @ts-ignore
export function createContentFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoContent;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateApiAccessResponse}
 */
// @ts-ignore
export function createCreateApiAccessResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateApiAccessResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateCartRequest}
 */
// @ts-ignore
export function createCreateCartRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateCartRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateCustomerRequest}
 */
// @ts-ignore
export function createCreateCustomerRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateCustomerRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateManyCustomersRequest}
 */
// @ts-ignore
export function createCreateManyCustomersRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateManyCustomersRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateManyProductsRequest}
 */
// @ts-ignore
export function createCreateManyProductsRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateManyProductsRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrderRequest}
 */
// @ts-ignore
export function createCreateOrderRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrderRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrderResponse}
 */
// @ts-ignore
export function createCreateOrderResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrderResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrganizationRequest}
 */
// @ts-ignore
export function createCreateOrganizationRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrganizationRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrganizationResponse}
 */
// @ts-ignore
export function createCreateOrganizationResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrganizationResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping}
 */
// @ts-ignore
export function createCreateOrUpdateDataMappingRequest_HeaderMappingField_MappingFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrUpdateDataMappingRequest_HeaderMappingField}
 */
// @ts-ignore
export function createCreateOrUpdateDataMappingRequest_HeaderMappingFieldFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrUpdateDataMappingRequest}
 */
// @ts-ignore
export function createCreateOrUpdateDataMappingRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrUpdateDataMappingRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateProductRequest}
 */
// @ts-ignore
export function createCreateProductRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateProductRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateSalesRepRequest}
 */
// @ts-ignore
export function createCreateSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateUserRequest}
 */
// @ts-ignore
export function createCreateUserRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateUserRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateUserResponse}
 */
// @ts-ignore
export function createCreateUserResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateUserResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Customer2}
 */
// @ts-ignore
export function createCustomer2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCustomer2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Customer3}
 */
// @ts-ignore
export function createCustomer3FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCustomer3;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Customer}
 */
// @ts-ignore
export function createCustomerFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCustomer;
}
export interface CreateCustomerRequest extends Parsable {
    /**
     * The active property
     */
    active?: boolean | null;
    /**
     * The addresses property
     */
    addresses?: Address[] | null;
    /**
     * The companyEmail property
     */
    companyEmail?: string | null;
    /**
     * The companyFax property
     */
    companyFax?: string | null;
    /**
     * The companyName property
     */
    companyName?: string | null;
    /**
     * The companyPhone property
     */
    companyPhone?: string | null;
    /**
     * The contacts property
     */
    contacts?: Contact[] | null;
    /**
     * The customerNumber property
     */
    customerNumber?: string | null;
    /**
     * The pricing property
     */
    pricing?: string | null;
    /**
     * The repNumber property
     */
    repNumber?: string | null;
    /**
     * The terms property
     */
    terms?: string | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DataMapping}
 */
// @ts-ignore
export function createDataMappingFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDataMapping;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DeactivateSalesRepRequest}
 */
// @ts-ignore
export function createDeactivateSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDeactivateSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DeletePriceTierRequest}
 */
// @ts-ignore
export function createDeletePriceTierRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDeletePriceTierRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DeleteSalesRepRequest}
 */
// @ts-ignore
export function createDeleteSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDeleteSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DeleteTermRequest}
 */
// @ts-ignore
export function createDeleteTermRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDeleteTermRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {EnrichM2MResponse}
 */
// @ts-ignore
export function createEnrichM2MResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoEnrichM2MResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {EntitlementsResponse}
 */
// @ts-ignore
export function createEntitlementsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoEntitlementsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ErrorResponse_errors}
 */
// @ts-ignore
export function createErrorResponse_errorsFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoErrorResponse_errors;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ErrorResponse}
 */
// @ts-ignore
export function createErrorResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoErrorResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ExistsResponse}
 */
// @ts-ignore
export function createExistsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoExistsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {GetApiAccessResponse}
 */
// @ts-ignore
export function createGetApiAccessResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoGetApiAccessResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {GetOrganizationResponse}
 */
// @ts-ignore
export function createGetOrganizationResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoGetOrganizationResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {HeaderColumnMap}
 */
// @ts-ignore
export function createHeaderColumnMapFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoHeaderColumnMap;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {HeaderMapping}
 */
// @ts-ignore
export function createHeaderMappingFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoHeaderMapping;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ImageAssignment}
 */
// @ts-ignore
export function createImageAssignmentFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoImageAssignment;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {IngAddress}
 */
// @ts-ignore
export function createIngAddressFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoIngAddress;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {InternalErrorResponse}
 */
// @ts-ignore
export function createInternalErrorResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoInternalErrorResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Invoice}
 */
// @ts-ignore
export function createInvoiceFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoInvoice;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {IOrder}
 */
// @ts-ignore
export function createIOrderFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoIOrder;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {LineItem}
 */
// @ts-ignore
export function createLineItemFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoLineItem;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {LinkedPayment}
 */
// @ts-ignore
export function createLinkedPaymentFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoLinkedPayment;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Link}
 */
// @ts-ignore
export function createLinkFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoLink;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListOfEntitlements}
 */
// @ts-ignore
export function createListOfEntitlementsFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListOfEntitlements;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListOrganizationSalesRepsResponse}
 */
// @ts-ignore
export function createListOrganizationSalesRepsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListOrganizationSalesRepsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListSalesRepOrganizationsResponse}
 */
// @ts-ignore
export function createListSalesRepOrganizationsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListSalesRepOrganizationsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListUserOrganizationsResponse}
 */
// @ts-ignore
export function createListUserOrganizationsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListUserOrganizationsResponse;
}
export interface CreateManyCustomersRequest extends Parsable {
    /**
     * The customers property
     */
    customers?: CreateCustomerRequest[] | null;
}
export interface CreateManyProductsRequest extends Parsable {
    /**
     * The products property
     */
    products?: CreateProductRequest[] | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {NotFound}
 */
// @ts-ignore
export function createNotFoundFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoNotFound;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Ok}
 */
// @ts-ignore
export function createOkFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOk;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {OrderDetailsResponse}
 */
// @ts-ignore
export function createOrderDetailsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrderDetailsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Order}
 */
// @ts-ignore
export function createOrderFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrder;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {OrderItem2}
 */
// @ts-ignore
export function createOrderItem2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrderItem2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {OrderItem}
 */
// @ts-ignore
export function createOrderItemFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrderItem;
}
export interface CreateOrderRequest extends Parsable {
    /**
     * The channel property
     */
    channel?: string | null;
    /**
     * The currencyCode property
     */
    currencyCode?: string | null;
    /**
     * The customer property
     */
    customer?: Customer2 | null;
    /**
     * The externalId property
     */
    externalId?: string | null;
    /**
     * The items property
     */
    items?: OrderItem2[] | null;
    /**
     * The notes property
     */
    notes?: string | null;
    /**
     * The orderStatus property
     */
    orderStatus?: string | null;
    /**
     * The orderType property
     */
    orderType?: string | null;
    /**
     * The originationCartId property
     */
    originationCartId?: string | null;
    /**
     * The poNumber property
     */
    poNumber?: string | null;
    /**
     * The priceTier property
     */
    priceTier?: string | null;
    /**
     * The primarySalesRep property
     */
    primarySalesRep?: SalesRep2 | null;
    /**
     * The shippingDetails property
     */
    shippingDetails?: ShippingDetails2 | null;
    /**
     * The takenBySalesRep property
     */
    takenBySalesRep?: SalesRep2 | null;
    /**
     * The tax property
     */
    tax?: number | null;
    /**
     * The terms property
     */
    terms?: string | null;
    /**
     * The user property
     */
    user?: User2 | null;
}
export interface CreateOrderResponse extends Parsable {
    /**
     * The data property
     */
    data?: Order | null;
    /**
     * The links property
     */
    links?: Link[] | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Organization2}
 */
// @ts-ignore
export function createOrganization2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrganization2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Organization}
 */
// @ts-ignore
export function createOrganizationFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrganization;
}
export interface CreateOrganizationRequest extends Parsable {
    /**
     * The organization property
     */
    organization?: Organization2 | null;
    /**
     * The user property
     */
    user?: User | null;
}
export interface CreateOrganizationResponse extends Parsable {
    /**
     * The identityOrganizationId property
     */
    identityOrganizationId?: string | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The slug property
     */
    slug?: string | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {OrganizationSalesRep}
 */
// @ts-ignore
export function createOrganizationSalesRepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrganizationSalesRep;
}
export interface CreateOrUpdateDataMappingRequest extends Parsable {
    /**
     * The headerMappingFields property
     */
    headerMappingFields?: CreateOrUpdateDataMappingRequest_HeaderMappingField[] | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The invertActiveProperty property
     */
    invertActiveProperty?: boolean | null;
    /**
     * The name property
     */
    name?: string | null;
}
export interface CreateOrUpdateDataMappingRequest_HeaderMappingField extends Parsable {
    /**
     * The header property
     */
    header?: string | null;
    /**
     * The mapping property
     */
    mapping?: CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping | null;
}
export interface CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping extends Parsable {
    /**
     * The mappedHeaderName property
     */
    mappedHeaderName?: string | null;
    /**
     * The uploadedHeaderName property
     */
    uploadedHeaderName?: string | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PagedResultOfCustomer}
 */
// @ts-ignore
export function createPagedResultOfCustomerFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPagedResultOfCustomer;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PagedResultOfIOrder}
 */
// @ts-ignore
export function createPagedResultOfIOrderFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPagedResultOfIOrder;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PagedResultOfProduct}
 */
// @ts-ignore
export function createPagedResultOfProductFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPagedResultOfProduct;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PaymentMethod}
 */
// @ts-ignore
export function createPaymentMethodFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPaymentMethod;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PortalSessionLinkResponse}
 */
// @ts-ignore
export function createPortalSessionLinkResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPortalSessionLinkResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PriceTier}
 */
// @ts-ignore
export function createPriceTierFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPriceTier;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ProblemDetails_Error}
 */
// @ts-ignore
export function createProblemDetails_ErrorFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProblemDetails_Error;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ProblemDetails}
 */
// @ts-ignore
export function createProblemDetailsFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProblemDetails;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Product_dynamicProperties}
 */
// @ts-ignore
export function createProduct_dynamicPropertiesFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProduct_dynamicProperties;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Product}
 */
// @ts-ignore
export function createProductFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProduct;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ProductPrice}
 */
// @ts-ignore
export function createProductPriceFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProductPrice;
}
export interface CreateProductRequest extends Parsable {
    /**
     * The active property
     */
    active?: boolean | null;
    /**
     * The category property
     */
    category?: string | null;
    /**
     * The description property
     */
    description?: string | null;
    /**
     * The dimensions property
     */
    dimensions?: string | null;
    /**
     * The dimensionsUnit property
     */
    dimensionsUnit?: string | null;
    /**
     * The imageName property
     */
    imageName?: string | null;
    /**
     * The images property
     */
    images?: string[] | null;
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The price property
     */
    price?: number | null;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number | null;
    /**
     * The quantityOnHand property
     */
    quantityOnHand?: number | null;
    /**
     * The sku property
     */
    sku?: string | null;
    /**
     * The upc property
     */
    upc?: string | null;
    /**
     * The weight property
     */
    weight?: number | null;
    /**
     * The weightUnit property
     */
    weightUnit?: string | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ReadmeAuthResponse}
 */
// @ts-ignore
export function createReadmeAuthResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoReadmeAuthResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Rep}
 */
// @ts-ignore
export function createRepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoRep;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SalesRep2}
 */
// @ts-ignore
export function createSalesRep2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSalesRep2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SalesRep}
 */
// @ts-ignore
export function createSalesRepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSalesRep;
}
/**
 * Represents a request to create a Sales Representative.
 */
export interface CreateSalesRepRequest extends Parsable {
    /**
     * Gets or sets the email of the sales representative.
     */
    email?: string | null;
    /**
     * Gets or sets the first name of the sales representative.
     */
    firstName?: string | null;
    /**
     * Gets or sets the last name of the sales representative.
     */
    lastName?: string | null;
    /**
     * Gets or sets the phone number of the sales representative.
     */
    phoneNumber?: string | null;
    /**
     * Gets or sets the representative number of the sales representative.
     */
    repNumber?: string | null;
    /**
     * Gets or sets the roles of the sales representative.
     */
    roles?: string[] | null;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ShippingDetails2}
 */
// @ts-ignore
export function createShippingDetails2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoShippingDetails2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ShippingDetails}
 */
// @ts-ignore
export function createShippingDetailsFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoShippingDetails;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ShoppingCart}
 */
// @ts-ignore
export function createShoppingCartFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoShoppingCart;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Step}
 */
// @ts-ignore
export function createStepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoStep;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Subscription2}
 */
// @ts-ignore
export function createSubscription2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscription2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SubscriptionCreatedRequest}
 */
// @ts-ignore
export function createSubscriptionCreatedRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscriptionCreatedRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SubscriptionEntitlement}
 */
// @ts-ignore
export function createSubscriptionEntitlementFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscriptionEntitlement;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Subscription}
 */
// @ts-ignore
export function createSubscriptionFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscription;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SubscriptionItem}
 */
// @ts-ignore
export function createSubscriptionItemFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscriptionItem;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UpdateCustomerRequest}
 */
// @ts-ignore
export function createUpdateCustomerRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUpdateCustomerRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UpdateItemRequest}
 */
// @ts-ignore
export function createUpdateItemRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUpdateItemRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UpdatePriceTierRequest}
 */
// @ts-ignore
export function createUpdatePriceTierRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUpdatePriceTierRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UpdateShippingRequest}
 */
// @ts-ignore
export function createUpdateShippingRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUpdateShippingRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {User2}
 */
// @ts-ignore
export function createUser2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUser2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {User3}
 */
// @ts-ignore
export function createUser3FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUser3;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UserExistsResponse}
 */
// @ts-ignore
export function createUserExistsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUserExistsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {User}
 */
// @ts-ignore
export function createUserFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUser;
}
export interface CreateUserRequest extends Parsable {
    /**
     * Gets or sets the email of the sales representative.
     */
    email?: string | null;
    /**
     * Gets or sets the first name of the sales representative.
     */
    firstName?: string | null;
    /**
     * Gets or sets the Identity Organization Id.
     */
    identityOrganizationId?: string | null;
    /**
     * Gets or sets the last name of the sales representative.
     */
    lastName?: string | null;
    /**
     * Gets or sets the password of the sales representative, this will be ignored if they already have an existing identity account.
     */
    password?: string | null;
    /**
     * Gets or sets the phone number of the sales representative.
     */
    phoneNumber?: string | null;
}
export interface CreateUserResponse extends Parsable {
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The firstName property
     */
    firstName?: string | null;
    /**
     * The lastName property
     */
    lastName?: string | null;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string | null;
}
export type CurrencyCode = (typeof CurrencyCodeObject)[keyof typeof CurrencyCodeObject];
export interface Customer extends Parsable {
    /**
     * The allow_direct_debit property
     */
    allowDirectDebit?: boolean | null;
    /**
     * The auto_collection property
     */
    autoCollection?: string | null;
    /**
     * The billing_address property
     */
    billingAddress?: IngAddress | null;
    /**
     * The card_status property
     */
    cardStatus?: string | null;
    /**
     * The channel property
     */
    channel?: string | null;
    /**
     * The created_at property
     */
    createdAt?: number | null;
    /**
     * The created_from_ip property
     */
    createdFromIp?: string | null;
    /**
     * The deleted property
     */
    deleted?: boolean | null;
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The excess_payments property
     */
    excessPayments?: number | null;
    /**
     * The first_name property
     */
    firstName?: string | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The last_name property
     */
    lastName?: string | null;
    /**
     * The mrr property
     */
    mrr?: number | null;
    /**
     * The net_term_days property
     */
    netTermDays?: number | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The payment_method property
     */
    paymentMethod?: PaymentMethod | null;
    /**
     * The pii_cleared property
     */
    piiCleared?: string | null;
    /**
     * The preferred_currency_code property
     */
    preferredCurrencyCode?: string | null;
    /**
     * The primary_payment_source_id property
     */
    primaryPaymentSourceId?: string | null;
    /**
     * The promotional_credits property
     */
    promotionalCredits?: number | null;
    /**
     * The refundable_credits property
     */
    refundableCredits?: number | null;
    /**
     * The resource_version property
     */
    resourceVersion?: number | null;
    /**
     * The taxability property
     */
    taxability?: string | null;
    /**
     * The unbilled_charges property
     */
    unbilledCharges?: number | null;
    /**
     * The updated_at property
     */
    updatedAt?: number | null;
}
export interface Customer2 extends Parsable {
    /**
     * The billingAddress property
     */
    billingAddress?: Address | null;
    /**
     * The companyEmail property
     */
    companyEmail?: string | null;
    /**
     * The companyFax property
     */
    companyFax?: string | null;
    /**
     * The companyName property
     */
    companyName?: string | null;
    /**
     * The companyPhone property
     */
    companyPhone?: string | null;
    /**
     * The customerNumber property
     */
    customerNumber?: string | null;
    /**
     * The shippingAddress property
     */
    shippingAddress?: Address | null;
}
export interface Customer3 extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The addresses property
     */
    addresses?: Address[] | null;
    /**
     * The companyEmail property
     */
    companyEmail?: string | null;
    /**
     * The companyFax property
     */
    companyFax?: string | null;
    /**
     * The companyName property
     */
    companyName?: string | null;
    /**
     * The companyPhone property
     */
    companyPhone?: string | null;
    /**
     * The contacts property
     */
    contacts?: Contact[] | null;
    /**
     * The customerId property
     */
    customerId?: string | null;
    /**
     * The customerNumber property
     */
    customerNumber?: string | null;
    /**
     * The defaultBillingAddress property
     */
    defaultBillingAddress?: Address | null;
    /**
     * The defaultShippingAddress property
     */
    defaultShippingAddress?: Address | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The pricing property
     */
    pricing?: string | null;
    /**
     * The repNumber property
     */
    repNumber?: string | null;
    /**
     * The terms property
     */
    terms?: string | null;
}
export interface DataMapping extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The description property
     */
    description?: string | null;
    /**
     * The headerMappingFields property
     */
    headerMappingFields?: HeaderColumnMap[] | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The invertActiveProperty property
     */
    invertActiveProperty?: boolean | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
}
export interface DeactivateSalesRepRequest extends Parsable {
    /**
     * OrganizationSalesRepId 
     */
    organizationSalesRepId?: string | null;
}
export interface DeletePriceTierRequest extends Parsable {
    /**
     * The name property
     */
    name?: string | null;
}
export interface DeleteSalesRepRequest extends Parsable {
    /**
     * OrganizationSalesRepId 
     */
    organizationSalesRepId?: string | null;
}
export interface DeleteTermRequest extends Parsable {
    /**
     * The term property
     */
    term?: Term | null;
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoAccountSetup(accountSetup: Partial<AccountSetup> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "isComplete": n => { accountSetup.isComplete = n.getBooleanValue(); },
        "nextStep": n => { accountSetup.nextStep = n.getObjectValue<Step>(createStepFromDiscriminatorValue); },
        "percentageComplete": n => { accountSetup.percentageComplete = n.getNumberValue(); },
        "steps": n => { accountSetup.steps = n.getCollectionOfObjectValues<Step>(createStepFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoActivateSalesRepRequest(activateSalesRepRequest: Partial<ActivateSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizationSalesRepId": n => { activateSalesRepRequest.organizationSalesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoAddItemRequest(addItemRequest: Partial<AddItemRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "quantity": n => { addItemRequest.quantity = n.getNumberValue(); },
        "sku": n => { addItemRequest.sku = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoAddPriceTierRequest(addPriceTierRequest: Partial<AddPriceTierRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currencyCode": n => { addPriceTierRequest.currencyCode = n.getStringValue(); },
        "name": n => { addPriceTierRequest.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoAddress(address: Partial<Address> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "address1": n => { address.address1 = n.getStringValue(); },
        "address2": n => { address.address2 = n.getStringValue(); },
        "addressType": n => { address.addressType = n.getEnumValue<AddressType>(AddressTypeObject); },
        "city": n => { address.city = n.getStringValue(); },
        "code": n => { address.code = n.getStringValue(); },
        "country": n => { address.country = n.getStringValue(); },
        "default": n => { address.defaultEscaped = n.getBooleanValue(); },
        "externalId": n => { address.externalId = n.getStringValue(); },
        "latitude": n => { address.latitude = n.getNumberValue(); },
        "longitude": n => { address.longitude = n.getNumberValue(); },
        "stateProvince": n => { address.stateProvince = n.getStringValue(); },
        "zipPostalCode": n => { address.zipPostalCode = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoAddTermRequest(addTermRequest: Partial<AddTermRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "term": n => { addTermRequest.term = n.getEnumValue<Term>(TermObject); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoApiAccess(apiAccess: Partial<ApiAccess> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(apiAccess),
        "clientId": n => { apiAccess.clientId = n.getStringValue(); },
        "clientSecret": n => { apiAccess.clientSecret = n.getStringValue(); },
        "id": n => { apiAccess.id = n.getStringValue(); },
        "organizationId": n => { apiAccess.organizationId = n.getStringValue(); },
        "orgCode": n => { apiAccess.orgCode = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoApiAuthenticationRequest(apiAuthenticationRequest: Partial<ApiAuthenticationRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "clientId": n => { apiAuthenticationRequest.clientId = n.getStringValue() ?? "sdaf43tfsdg45dg"; },
        "clientSecret": n => { apiAuthenticationRequest.clientSecret = n.getStringValue() ?? "QsFdas%4F1asr23tgb675%3"; },
        "orgCode": n => { apiAuthenticationRequest.orgCode = n.getStringValue() ?? "org_12345"; },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoApiAuthenticationResponse(apiAuthenticationResponse: Partial<ApiAuthenticationResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "accessToken": n => { apiAuthenticationResponse.accessToken = n.getStringValue(); },
        "expiresIn": n => { apiAuthenticationResponse.expiresIn = n.getNumberValue(); },
        "tokenType": n => { apiAuthenticationResponse.tokenType = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoBaseEntity(baseEntity: Partial<BaseEntity> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { baseEntity.active = n.getBooleanValue(); },
        "createdAt": n => { baseEntity.createdAt = n.getDateValue(); },
        "updatedAt": n => { baseEntity.updatedAt = n.getDateValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCard(card: Partial<Card> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "card_type": n => { card.cardType = n.getStringValue(); },
        "created_at": n => { card.createdAt = n.getNumberValue(); },
        "customer_id": n => { card.customerId = n.getStringValue(); },
        "expiry_month": n => { card.expiryMonth = n.getNumberValue(); },
        "expiry_year": n => { card.expiryYear = n.getNumberValue(); },
        "funding_type": n => { card.fundingType = n.getStringValue(); },
        "gateway": n => { card.gateway = n.getStringValue(); },
        "gateway_account_id": n => { card.gatewayAccountId = n.getStringValue(); },
        "iin": n => { card.iin = n.getNumberValue(); },
        "ip_address": n => { card.ipAddress = n.getStringValue(); },
        "last4": n => { card.last4 = n.getNumberValue(); },
        "masked_number": n => { card.maskedNumber = n.getStringValue(); },
        "object": n => { card.object = n.getStringValue(); },
        "payment_source_id": n => { card.paymentSourceId = n.getStringValue(); },
        "resource_version": n => { card.resourceVersion = n.getNumberValue(); },
        "status": n => { card.status = n.getStringValue(); },
        "updated_at": n => { card.updatedAt = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCartItem(cartItem: Partial<CartItem> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(cartItem),
        "imageName": n => { cartItem.imageName = n.getStringValue(); },
        "minimumOrderQuantity": n => { cartItem.minimumOrderQuantity = n.getNumberValue(); },
        "price": n => { cartItem.price = n.getNumberValue(); },
        "priceFormatted": n => { cartItem.priceFormatted = n.getStringValue(); },
        "productName": n => { cartItem.productName = n.getStringValue(); },
        "quantity": n => { cartItem.quantity = n.getNumberValue(); },
        "quantityIncrement": n => { cartItem.quantityIncrement = n.getNumberValue(); },
        "sku": n => { cartItem.sku = n.getStringValue(); },
        "subTotal": n => { cartItem.subTotal = n.getNumberValue(); },
        "subTotalFormatted": n => { cartItem.subTotalFormatted = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCheckoutPageResponse(checkoutPageResponse: Partial<CheckoutPageResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "createdAt": n => { checkoutPageResponse.createdAt = n.getNumberValue(); },
        "embed": n => { checkoutPageResponse.embed = n.getBooleanValue(); },
        "expiresAt": n => { checkoutPageResponse.expiresAt = n.getNumberValue(); },
        "id": n => { checkoutPageResponse.id = n.getStringValue(); },
        "state": n => { checkoutPageResponse.state = n.getStringValue(); },
        "type": n => { checkoutPageResponse.type = n.getStringValue(); },
        "url": n => { checkoutPageResponse.url = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCompleteCheckoutRequest(completeCheckoutRequest: Partial<CompleteCheckoutRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "channel": n => { completeCheckoutRequest.channel = n.getStringValue(); },
        "customerNumber": n => { completeCheckoutRequest.customerNumber = n.getStringValue(); },
        "userType": n => { completeCheckoutRequest.userType = n.getEnumValue<UserTypes>(UserTypesObject); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCompleteCheckoutResponse(completeCheckoutResponse: Partial<CompleteCheckoutResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "confirmationId": n => { completeCheckoutResponse.confirmationId = n.getStringValue(); },
        "orderNumber": n => { completeCheckoutResponse.orderNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoConfigResponse(configResponse: Partial<ConfigResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "mapsApiKey": n => { configResponse.mapsApiKey = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoContact(contact: Partial<Contact> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "contactType": n => { contact.contactType = n.getEnumValue<ContactType>(ContactTypeObject); },
        "default": n => { contact.defaultEscaped = n.getBooleanValue(); },
        "emailAddress": n => { contact.emailAddress = n.getStringValue(); },
        "externalId": n => { contact.externalId = n.getStringValue(); },
        "fax": n => { contact.fax = n.getStringValue(); },
        "firstName": n => { contact.firstName = n.getStringValue(); },
        "fullName": n => { contact.fullName = n.getStringValue(); },
        "lastName": n => { contact.lastName = n.getStringValue(); },
        "phone": n => { contact.phone = n.getStringValue(); },
        "title": n => { contact.title = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoContent(content: Partial<Content> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "card": n => { content.card = n.getObjectValue<Card>(createCardFromDiscriminatorValue); },
        "customer": n => { content.customer = n.getObjectValue<Customer>(createCustomerFromDiscriminatorValue); },
        "invoice": n => { content.invoice = n.getObjectValue<Invoice>(createInvoiceFromDiscriminatorValue); },
        "subscription": n => { content.subscription = n.getObjectValue<Subscription2>(createSubscription2FromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateApiAccessResponse(createApiAccessResponse: Partial<CreateApiAccessResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "apiAccess": n => { createApiAccessResponse.apiAccess = n.getObjectValue<ApiAccess>(createApiAccessFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateCartRequest(createCartRequest: Partial<CreateCartRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "orderType": n => { createCartRequest.orderType = n.getStringValue(); },
        "quantity": n => { createCartRequest.quantity = n.getNumberValue(); },
        "sku": n => { createCartRequest.sku = n.getStringValue(); },
        "userId": n => { createCartRequest.userId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateCustomerRequest(createCustomerRequest: Partial<CreateCustomerRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { createCustomerRequest.active = n.getBooleanValue(); },
        "addresses": n => { createCustomerRequest.addresses = n.getCollectionOfObjectValues<Address>(createAddressFromDiscriminatorValue); },
        "companyEmail": n => { createCustomerRequest.companyEmail = n.getStringValue(); },
        "companyFax": n => { createCustomerRequest.companyFax = n.getStringValue(); },
        "companyName": n => { createCustomerRequest.companyName = n.getStringValue(); },
        "companyPhone": n => { createCustomerRequest.companyPhone = n.getStringValue(); },
        "contacts": n => { createCustomerRequest.contacts = n.getCollectionOfObjectValues<Contact>(createContactFromDiscriminatorValue); },
        "customerNumber": n => { createCustomerRequest.customerNumber = n.getStringValue(); },
        "pricing": n => { createCustomerRequest.pricing = n.getStringValue(); },
        "repNumber": n => { createCustomerRequest.repNumber = n.getStringValue(); },
        "terms": n => { createCustomerRequest.terms = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateManyCustomersRequest(createManyCustomersRequest: Partial<CreateManyCustomersRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "customers": n => { createManyCustomersRequest.customers = n.getCollectionOfObjectValues<CreateCustomerRequest>(createCreateCustomerRequestFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateManyProductsRequest(createManyProductsRequest: Partial<CreateManyProductsRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "products": n => { createManyProductsRequest.products = n.getCollectionOfObjectValues<CreateProductRequest>(createCreateProductRequestFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateOrderRequest(createOrderRequest: Partial<CreateOrderRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "channel": n => { createOrderRequest.channel = n.getStringValue(); },
        "currencyCode": n => { createOrderRequest.currencyCode = n.getStringValue(); },
        "customer": n => { createOrderRequest.customer = n.getObjectValue<Customer2>(createCustomer2FromDiscriminatorValue); },
        "externalId": n => { createOrderRequest.externalId = n.getStringValue(); },
        "items": n => { createOrderRequest.items = n.getCollectionOfObjectValues<OrderItem2>(createOrderItem2FromDiscriminatorValue); },
        "notes": n => { createOrderRequest.notes = n.getStringValue(); },
        "orderStatus": n => { createOrderRequest.orderStatus = n.getStringValue(); },
        "orderType": n => { createOrderRequest.orderType = n.getStringValue(); },
        "originationCartId": n => { createOrderRequest.originationCartId = n.getStringValue(); },
        "poNumber": n => { createOrderRequest.poNumber = n.getStringValue(); },
        "priceTier": n => { createOrderRequest.priceTier = n.getStringValue(); },
        "primarySalesRep": n => { createOrderRequest.primarySalesRep = n.getObjectValue<SalesRep2>(createSalesRep2FromDiscriminatorValue); },
        "shippingDetails": n => { createOrderRequest.shippingDetails = n.getObjectValue<ShippingDetails2>(createShippingDetails2FromDiscriminatorValue); },
        "takenBySalesRep": n => { createOrderRequest.takenBySalesRep = n.getObjectValue<SalesRep2>(createSalesRep2FromDiscriminatorValue); },
        "tax": n => { createOrderRequest.tax = n.getNumberValue(); },
        "terms": n => { createOrderRequest.terms = n.getStringValue(); },
        "user": n => { createOrderRequest.user = n.getObjectValue<User2>(createUser2FromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateOrderResponse(createOrderResponse: Partial<CreateOrderResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "data": n => { createOrderResponse.data = n.getObjectValue<Order>(createOrderFromDiscriminatorValue); },
        "links": n => { createOrderResponse.links = n.getCollectionOfObjectValues<Link>(createLinkFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateOrganizationRequest(createOrganizationRequest: Partial<CreateOrganizationRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organization": n => { createOrganizationRequest.organization = n.getObjectValue<Organization2>(createOrganization2FromDiscriminatorValue); },
        "user": n => { createOrganizationRequest.user = n.getObjectValue<User>(createUserFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateOrganizationResponse(createOrganizationResponse: Partial<CreateOrganizationResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "identityOrganizationId": n => { createOrganizationResponse.identityOrganizationId = n.getStringValue(); },
        "name": n => { createOrganizationResponse.name = n.getStringValue(); },
        "organizationId": n => { createOrganizationResponse.organizationId = n.getStringValue(); },
        "slug": n => { createOrganizationResponse.slug = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateOrUpdateDataMappingRequest(createOrUpdateDataMappingRequest: Partial<CreateOrUpdateDataMappingRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "headerMappingFields": n => { createOrUpdateDataMappingRequest.headerMappingFields = n.getCollectionOfObjectValues<CreateOrUpdateDataMappingRequest_HeaderMappingField>(createCreateOrUpdateDataMappingRequest_HeaderMappingFieldFromDiscriminatorValue); },
        "id": n => { createOrUpdateDataMappingRequest.id = n.getStringValue(); },
        "invertActiveProperty": n => { createOrUpdateDataMappingRequest.invertActiveProperty = n.getBooleanValue(); },
        "name": n => { createOrUpdateDataMappingRequest.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField(createOrUpdateDataMappingRequest_HeaderMappingField: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "header": n => { createOrUpdateDataMappingRequest_HeaderMappingField.header = n.getStringValue(); },
        "mapping": n => { createOrUpdateDataMappingRequest_HeaderMappingField.mapping = n.getObjectValue<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping>(createCreateOrUpdateDataMappingRequest_HeaderMappingField_MappingFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping(createOrUpdateDataMappingRequest_HeaderMappingField_Mapping: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "mappedHeaderName": n => { createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.mappedHeaderName = n.getStringValue(); },
        "uploadedHeaderName": n => { createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.uploadedHeaderName = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateProductRequest(createProductRequest: Partial<CreateProductRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { createProductRequest.active = n.getBooleanValue(); },
        "category": n => { createProductRequest.category = n.getStringValue(); },
        "description": n => { createProductRequest.description = n.getStringValue(); },
        "dimensions": n => { createProductRequest.dimensions = n.getStringValue(); },
        "dimensionsUnit": n => { createProductRequest.dimensionsUnit = n.getStringValue(); },
        "imageName": n => { createProductRequest.imageName = n.getStringValue(); },
        "images": n => { createProductRequest.images = n.getCollectionOfPrimitiveValues<string>(); },
        "minimumOrderQuantity": n => { createProductRequest.minimumOrderQuantity = n.getNumberValue(); },
        "name": n => { createProductRequest.name = n.getStringValue(); },
        "price": n => { createProductRequest.price = n.getNumberValue(); },
        "quantityIncrement": n => { createProductRequest.quantityIncrement = n.getNumberValue(); },
        "quantityOnHand": n => { createProductRequest.quantityOnHand = n.getNumberValue(); },
        "sku": n => { createProductRequest.sku = n.getStringValue(); },
        "upc": n => { createProductRequest.upc = n.getStringValue(); },
        "weight": n => { createProductRequest.weight = n.getNumberValue(); },
        "weightUnit": n => { createProductRequest.weightUnit = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateSalesRepRequest(createSalesRepRequest: Partial<CreateSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { createSalesRepRequest.email = n.getStringValue(); },
        "firstName": n => { createSalesRepRequest.firstName = n.getStringValue(); },
        "lastName": n => { createSalesRepRequest.lastName = n.getStringValue(); },
        "phoneNumber": n => { createSalesRepRequest.phoneNumber = n.getStringValue(); },
        "repNumber": n => { createSalesRepRequest.repNumber = n.getStringValue(); },
        "roles": n => { createSalesRepRequest.roles = n.getCollectionOfPrimitiveValues<string>(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateUserRequest(createUserRequest: Partial<CreateUserRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { createUserRequest.email = n.getStringValue(); },
        "firstName": n => { createUserRequest.firstName = n.getStringValue(); },
        "identityOrganizationId": n => { createUserRequest.identityOrganizationId = n.getStringValue(); },
        "lastName": n => { createUserRequest.lastName = n.getStringValue(); },
        "password": n => { createUserRequest.password = n.getStringValue(); },
        "phoneNumber": n => { createUserRequest.phoneNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCreateUserResponse(createUserResponse: Partial<CreateUserResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { createUserResponse.email = n.getStringValue(); },
        "firstName": n => { createUserResponse.firstName = n.getStringValue(); },
        "lastName": n => { createUserResponse.lastName = n.getStringValue(); },
        "phoneNumber": n => { createUserResponse.phoneNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCustomer(customer: Partial<Customer> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "allow_direct_debit": n => { customer.allowDirectDebit = n.getBooleanValue(); },
        "auto_collection": n => { customer.autoCollection = n.getStringValue(); },
        "billing_address": n => { customer.billingAddress = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "card_status": n => { customer.cardStatus = n.getStringValue(); },
        "channel": n => { customer.channel = n.getStringValue(); },
        "created_at": n => { customer.createdAt = n.getNumberValue(); },
        "created_from_ip": n => { customer.createdFromIp = n.getStringValue(); },
        "deleted": n => { customer.deleted = n.getBooleanValue(); },
        "email": n => { customer.email = n.getStringValue(); },
        "excess_payments": n => { customer.excessPayments = n.getNumberValue(); },
        "first_name": n => { customer.firstName = n.getStringValue(); },
        "id": n => { customer.id = n.getStringValue(); },
        "last_name": n => { customer.lastName = n.getStringValue(); },
        "mrr": n => { customer.mrr = n.getNumberValue(); },
        "net_term_days": n => { customer.netTermDays = n.getNumberValue(); },
        "object": n => { customer.object = n.getStringValue(); },
        "payment_method": n => { customer.paymentMethod = n.getObjectValue<PaymentMethod>(createPaymentMethodFromDiscriminatorValue); },
        "pii_cleared": n => { customer.piiCleared = n.getStringValue(); },
        "preferred_currency_code": n => { customer.preferredCurrencyCode = n.getStringValue(); },
        "primary_payment_source_id": n => { customer.primaryPaymentSourceId = n.getStringValue(); },
        "promotional_credits": n => { customer.promotionalCredits = n.getNumberValue(); },
        "refundable_credits": n => { customer.refundableCredits = n.getNumberValue(); },
        "resource_version": n => { customer.resourceVersion = n.getNumberValue(); },
        "taxability": n => { customer.taxability = n.getStringValue(); },
        "unbilled_charges": n => { customer.unbilledCharges = n.getNumberValue(); },
        "updated_at": n => { customer.updatedAt = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCustomer2(customer2: Partial<Customer2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "billingAddress": n => { customer2.billingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "companyEmail": n => { customer2.companyEmail = n.getStringValue(); },
        "companyFax": n => { customer2.companyFax = n.getStringValue(); },
        "companyName": n => { customer2.companyName = n.getStringValue(); },
        "companyPhone": n => { customer2.companyPhone = n.getStringValue(); },
        "customerNumber": n => { customer2.customerNumber = n.getStringValue(); },
        "shippingAddress": n => { customer2.shippingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoCustomer3(customer3: Partial<Customer3> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(customer3),
        "addresses": n => { customer3.addresses = n.getCollectionOfObjectValues<Address>(createAddressFromDiscriminatorValue); },
        "companyEmail": n => { customer3.companyEmail = n.getStringValue(); },
        "companyFax": n => { customer3.companyFax = n.getStringValue(); },
        "companyName": n => { customer3.companyName = n.getStringValue(); },
        "companyPhone": n => { customer3.companyPhone = n.getStringValue(); },
        "contacts": n => { customer3.contacts = n.getCollectionOfObjectValues<Contact>(createContactFromDiscriminatorValue); },
        "customerId": n => { customer3.customerId = n.getStringValue(); },
        "customerNumber": n => { customer3.customerNumber = n.getStringValue(); },
        "defaultBillingAddress": n => { customer3.defaultBillingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "defaultShippingAddress": n => { customer3.defaultShippingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "organizationId": n => { customer3.organizationId = n.getStringValue(); },
        "pricing": n => { customer3.pricing = n.getStringValue(); },
        "repNumber": n => { customer3.repNumber = n.getStringValue(); },
        "terms": n => { customer3.terms = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoDataMapping(dataMapping: Partial<DataMapping> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(dataMapping),
        "description": n => { dataMapping.description = n.getStringValue(); },
        "headerMappingFields": n => { dataMapping.headerMappingFields = n.getCollectionOfObjectValues<HeaderColumnMap>(createHeaderColumnMapFromDiscriminatorValue); },
        "id": n => { dataMapping.id = n.getStringValue(); },
        "invertActiveProperty": n => { dataMapping.invertActiveProperty = n.getBooleanValue(); },
        "name": n => { dataMapping.name = n.getStringValue(); },
        "organizationId": n => { dataMapping.organizationId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoDeactivateSalesRepRequest(deactivateSalesRepRequest: Partial<DeactivateSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizationSalesRepId": n => { deactivateSalesRepRequest.organizationSalesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoDeletePriceTierRequest(deletePriceTierRequest: Partial<DeletePriceTierRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "name": n => { deletePriceTierRequest.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoDeleteSalesRepRequest(deleteSalesRepRequest: Partial<DeleteSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizationSalesRepId": n => { deleteSalesRepRequest.organizationSalesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoDeleteTermRequest(deleteTermRequest: Partial<DeleteTermRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "term": n => { deleteTermRequest.term = n.getEnumValue<Term>(TermObject); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoEnrichM2MResponse(enrichM2MResponse: Partial<EnrichM2MResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizationId": n => { enrichM2MResponse.organizationId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoEntitlementsResponse(entitlementsResponse: Partial<EntitlementsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "entitlements": n => { entitlementsResponse.entitlements = n.getCollectionOfObjectValues<ListOfEntitlements>(createListOfEntitlementsFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoErrorResponse(errorResponse: Partial<ErrorResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "errors": n => { errorResponse.errors = n.getObjectValue<ErrorResponse_errors>(createErrorResponse_errorsFromDiscriminatorValue); },
        "message": n => { errorResponse.messageEscaped = n.getStringValue() ?? "One or more errors occurred!"; },
        "statusCode": n => { errorResponse.statusCode = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoErrorResponse_errors(errorResponse_errors: Partial<ErrorResponse_errors> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoExistsResponse(existsResponse: Partial<ExistsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "exitsInOrganization": n => { existsResponse.exitsInOrganization = n.getBooleanValue(); },
        "identityExists": n => { existsResponse.identityExists = n.getBooleanValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoGetApiAccessResponse(getApiAccessResponse: Partial<GetApiAccessResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "apiAccess": n => { getApiAccessResponse.apiAccess = n.getObjectValue<ApiAccess>(createApiAccessFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoGetOrganizationResponse(getOrganizationResponse: Partial<GetOrganizationResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "entitlements": n => { getOrganizationResponse.entitlements = n.getCollectionOfObjectValues<ListOfEntitlements>(createListOfEntitlementsFromDiscriminatorValue); },
        "organization": n => { getOrganizationResponse.organization = n.getObjectValue<Organization>(createOrganizationFromDiscriminatorValue); },
        "subscriptions": n => { getOrganizationResponse.subscriptions = n.getCollectionOfObjectValues<Subscription>(createSubscriptionFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoHeaderColumnMap(headerColumnMap: Partial<HeaderColumnMap> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "header": n => { headerColumnMap.header = n.getStringValue(); },
        "mapping": n => { headerColumnMap.mapping = n.getObjectValue<HeaderMapping>(createHeaderMappingFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoHeaderMapping(headerMapping: Partial<HeaderMapping> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "mappedHeaderName": n => { headerMapping.mappedHeaderName = n.getStringValue(); },
        "uploadedHeaderName": n => { headerMapping.uploadedHeaderName = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoImageAssignment(imageAssignment: Partial<ImageAssignment> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "format": n => { imageAssignment.format = n.getEnumValue<ImageFormat>(ImageFormatObject); },
        "imageId": n => { imageAssignment.imageId = n.getStringValue(); },
        "imageName": n => { imageAssignment.imageName = n.getStringValue(); },
        "position": n => { imageAssignment.position = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoIngAddress(ingAddress: Partial<IngAddress> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "city": n => { ingAddress.city = n.getStringValue(); },
        "country": n => { ingAddress.country = n.getStringValue(); },
        "first_name": n => { ingAddress.firstName = n.getStringValue(); },
        "last_name": n => { ingAddress.lastName = n.getStringValue(); },
        "line1": n => { ingAddress.line1 = n.getStringValue(); },
        "object": n => { ingAddress.object = n.getStringValue(); },
        "state": n => { ingAddress.state = n.getStringValue(); },
        "state_code": n => { ingAddress.stateCode = n.getStringValue(); },
        "validation_status": n => { ingAddress.validationStatus = n.getStringValue(); },
        "zip": n => { ingAddress.zip = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoInternalErrorResponse(internalErrorResponse: Partial<InternalErrorResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "code": n => { internalErrorResponse.code = n.getNumberValue(); },
        "note": n => { internalErrorResponse.note = n.getStringValue() ?? "See application log for stack trace."; },
        "reason": n => { internalErrorResponse.reason = n.getStringValue() ?? "Something unexpected has happened"; },
        "status": n => { internalErrorResponse.status = n.getStringValue() ?? "Internal Server Error!"; },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoInvoice(invoice: Partial<Invoice> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "adjustment_credit_notes": n => { invoice.adjustmentCreditNotes = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "amount_adjusted": n => { invoice.amountAdjusted = n.getNumberValue(); },
        "amount_due": n => { invoice.amountDue = n.getNumberValue(); },
        "amount_paid": n => { invoice.amountPaid = n.getNumberValue(); },
        "amount_to_collect": n => { invoice.amountToCollect = n.getNumberValue(); },
        "applied_credits": n => { invoice.appliedCredits = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "base_currency_code": n => { invoice.baseCurrencyCode = n.getStringValue(); },
        "billing_address": n => { invoice.billingAddress = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "channel": n => { invoice.channel = n.getStringValue(); },
        "credits_applied": n => { invoice.creditsApplied = n.getNumberValue(); },
        "currency_code": n => { invoice.currencyCode = n.getStringValue(); },
        "customer_id": n => { invoice.customerId = n.getStringValue(); },
        "date": n => { invoice.date = n.getNumberValue(); },
        "deleted": n => { invoice.deleted = n.getBooleanValue(); },
        "due_date": n => { invoice.dueDate = n.getNumberValue(); },
        "dunning_attempts": n => { invoice.dunningAttempts = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "exchange_rate": n => { invoice.exchangeRate = n.getNumberValue(); },
        "first_invoice": n => { invoice.firstInvoice = n.getBooleanValue(); },
        "generated_at": n => { invoice.generatedAt = n.getNumberValue(); },
        "has_advance_charges": n => { invoice.hasAdvanceCharges = n.getBooleanValue(); },
        "id": n => { invoice.id = n.getNumberValue(); },
        "is_gifted": n => { invoice.isGifted = n.getBooleanValue(); },
        "issued_credit_notes": n => { invoice.issuedCreditNotes = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "line_items": n => { invoice.lineItems = n.getCollectionOfObjectValues<LineItem>(createLineItemFromDiscriminatorValue); },
        "linked_orders": n => { invoice.linkedOrders = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "linked_payments": n => { invoice.linkedPayments = n.getCollectionOfObjectValues<LinkedPayment>(createLinkedPaymentFromDiscriminatorValue); },
        "net_term_days": n => { invoice.netTermDays = n.getNumberValue(); },
        "new_sales_amount": n => { invoice.newSalesAmount = n.getNumberValue(); },
        "object": n => { invoice.object = n.getStringValue(); },
        "paid_at": n => { invoice.paidAt = n.getNumberValue(); },
        "price_type": n => { invoice.priceType = n.getStringValue(); },
        "recurring": n => { invoice.recurring = n.getBooleanValue(); },
        "resource_version": n => { invoice.resourceVersion = n.getNumberValue(); },
        "round_off_amount": n => { invoice.roundOffAmount = n.getNumberValue(); },
        "shipping_address": n => { invoice.shippingAddress = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "status": n => { invoice.status = n.getStringValue(); },
        "subscription_id": n => { invoice.subscriptionId = n.getStringValue(); },
        "sub_total": n => { invoice.subTotal = n.getNumberValue(); },
        "tax": n => { invoice.tax = n.getNumberValue(); },
        "term_finalized": n => { invoice.termFinalized = n.getBooleanValue(); },
        "total": n => { invoice.total = n.getNumberValue(); },
        "updated_at": n => { invoice.updatedAt = n.getNumberValue(); },
        "write_off_amount": n => { invoice.writeOffAmount = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoIOrder(iOrder: Partial<IOrder> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "channel": n => { iOrder.channel = n.getStringValue(); },
        "currencyCode": n => { iOrder.currencyCode = n.getStringValue(); },
        "customer": n => { iOrder.customer = n.getObjectValue<Customer2>(createCustomer2FromDiscriminatorValue); },
        "externalId": n => { iOrder.externalId = n.getStringValue(); },
        "id": n => { iOrder.id = n.getStringValue(); },
        "items": n => { iOrder.items = n.getCollectionOfObjectValues<OrderItem>(createOrderItemFromDiscriminatorValue); },
        "notes": n => { iOrder.notes = n.getStringValue(); },
        "orderDate": n => { iOrder.orderDate = n.getDateValue(); },
        "orderNumber": n => { iOrder.orderNumber = n.getStringValue(); },
        "orderStatus": n => { iOrder.orderStatus = n.getStringValue(); },
        "orderTotal": n => { iOrder.orderTotal = n.getNumberValue(); },
        "orderTotalFormatted": n => { iOrder.orderTotalFormatted = n.getStringValue(); },
        "orderType": n => { iOrder.orderType = n.getStringValue(); },
        "organizationId": n => { iOrder.organizationId = n.getStringValue(); },
        "originationCartId": n => { iOrder.originationCartId = n.getStringValue(); },
        "poNumber": n => { iOrder.poNumber = n.getStringValue(); },
        "priceTier": n => { iOrder.priceTier = n.getStringValue(); },
        "primarySalesRep": n => { iOrder.primarySalesRep = n.getObjectValue<SalesRep2>(createSalesRep2FromDiscriminatorValue); },
        "shippingDetails": n => { iOrder.shippingDetails = n.getObjectValue<ShippingDetails>(createShippingDetailsFromDiscriminatorValue); },
        "shippingFormatted": n => { iOrder.shippingFormatted = n.getStringValue(); },
        "subtotal": n => { iOrder.subtotal = n.getNumberValue(); },
        "subtotalFormatted": n => { iOrder.subtotalFormatted = n.getStringValue(); },
        "takenBySalesRep": n => { iOrder.takenBySalesRep = n.getObjectValue<SalesRep2>(createSalesRep2FromDiscriminatorValue); },
        "tax": n => { iOrder.tax = n.getNumberValue(); },
        "taxFormatted": n => { iOrder.taxFormatted = n.getStringValue(); },
        "terms": n => { iOrder.terms = n.getStringValue(); },
        "user": n => { iOrder.user = n.getObjectValue<User2>(createUser2FromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoLineItem(lineItem: Partial<LineItem> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "amount": n => { lineItem.amount = n.getNumberValue(); },
        "customer_id": n => { lineItem.customerId = n.getStringValue(); },
        "date_from": n => { lineItem.dateFrom = n.getNumberValue(); },
        "date_to": n => { lineItem.dateTo = n.getNumberValue(); },
        "description": n => { lineItem.description = n.getStringValue(); },
        "discount_amount": n => { lineItem.discountAmount = n.getNumberValue(); },
        "entity_id": n => { lineItem.entityId = n.getStringValue(); },
        "entity_type": n => { lineItem.entityType = n.getStringValue(); },
        "id": n => { lineItem.id = n.getStringValue(); },
        "is_taxed": n => { lineItem.isTaxed = n.getBooleanValue(); },
        "item_level_discount_amount": n => { lineItem.itemLevelDiscountAmount = n.getNumberValue(); },
        "object": n => { lineItem.object = n.getStringValue(); },
        "pricing_model": n => { lineItem.pricingModel = n.getStringValue(); },
        "quantity": n => { lineItem.quantity = n.getNumberValue(); },
        "subscription_id": n => { lineItem.subscriptionId = n.getStringValue(); },
        "tax_amount": n => { lineItem.taxAmount = n.getNumberValue(); },
        "tax_exempt_reason": n => { lineItem.taxExemptReason = n.getStringValue(); },
        "unit_amount": n => { lineItem.unitAmount = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoLink(link: Partial<Link> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "action": n => { link.action = n.getStringValue(); },
        "href": n => { link.href = n.getStringValue(); },
        "rel": n => { link.rel = n.getStringValue(); },
        "types": n => { link.types = n.getCollectionOfPrimitiveValues<string>(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoLinkedPayment(linkedPayment: Partial<LinkedPayment> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "applied_amount": n => { linkedPayment.appliedAmount = n.getNumberValue(); },
        "applied_at": n => { linkedPayment.appliedAt = n.getNumberValue(); },
        "txn_amount": n => { linkedPayment.txnAmount = n.getNumberValue(); },
        "txn_date": n => { linkedPayment.txnDate = n.getNumberValue(); },
        "txn_id": n => { linkedPayment.txnId = n.getStringValue(); },
        "txn_status": n => { linkedPayment.txnStatus = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoListOfEntitlements(listOfEntitlements: Partial<ListOfEntitlements> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "subscriptionEntitlement": n => { listOfEntitlements.subscriptionEntitlement = n.getObjectValue<SubscriptionEntitlement>(createSubscriptionEntitlementFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoListOrganizationSalesRepsResponse(listOrganizationSalesRepsResponse: Partial<ListOrganizationSalesRepsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { listOrganizationSalesRepsResponse.active = n.getBooleanValue(); },
        "email": n => { listOrganizationSalesRepsResponse.email = n.getStringValue(); },
        "firstName": n => { listOrganizationSalesRepsResponse.firstName = n.getStringValue(); },
        "lastName": n => { listOrganizationSalesRepsResponse.lastName = n.getStringValue(); },
        "organizationId": n => { listOrganizationSalesRepsResponse.organizationId = n.getStringValue(); },
        "organizationSalesRepId": n => { listOrganizationSalesRepsResponse.organizationSalesRepId = n.getStringValue(); },
        "phoneNumber": n => { listOrganizationSalesRepsResponse.phoneNumber = n.getStringValue(); },
        "repNumber": n => { listOrganizationSalesRepsResponse.repNumber = n.getStringValue(); },
        "salesRepId": n => { listOrganizationSalesRepsResponse.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoListSalesRepOrganizationsResponse(listSalesRepOrganizationsResponse: Partial<ListSalesRepOrganizationsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "salesRepOrganizations": n => { listSalesRepOrganizationsResponse.salesRepOrganizations = n.getCollectionOfObjectValues<OrganizationSalesRep>(createOrganizationSalesRepFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoListUserOrganizationsResponse(listUserOrganizationsResponse: Partial<ListUserOrganizationsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizations": n => { listUserOrganizationsResponse.organizations = n.getCollectionOfObjectValues<Organization>(createOrganizationFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoNotFound(notFound: Partial<NotFound> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "statusCode": n => { notFound.statusCode = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOk(ok: Partial<Ok> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "statusCode": n => { ok.statusCode = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOrder(order: Partial<Order> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "id": n => { order.id = n.getStringValue(); },
        "orderNumber": n => { order.orderNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOrderDetailsResponse(orderDetailsResponse: Partial<OrderDetailsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "data": n => { orderDetailsResponse.data = n.getObjectValue<IOrder>(createIOrderFromDiscriminatorValue); },
        "links": n => { orderDetailsResponse.links = n.getCollectionOfObjectValues<Link>(createLinkFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOrderItem(orderItem: Partial<OrderItem> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "externalId": n => { orderItem.externalId = n.getStringValue(); },
        "imageName": n => { orderItem.imageName = n.getStringValue(); },
        "minimumOrderQuantity": n => { orderItem.minimumOrderQuantity = n.getNumberValue(); },
        "price": n => { orderItem.price = n.getNumberValue(); },
        "priceFormatted": n => { orderItem.priceFormatted = n.getStringValue(); },
        "productName": n => { orderItem.productName = n.getStringValue(); },
        "quantity": n => { orderItem.quantity = n.getNumberValue(); },
        "quantityIncrement": n => { orderItem.quantityIncrement = n.getNumberValue(); },
        "sku": n => { orderItem.sku = n.getStringValue(); },
        "subTotal": n => { orderItem.subTotal = n.getNumberValue(); },
        "subTotalFormatted": n => { orderItem.subTotalFormatted = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOrderItem2(orderItem2: Partial<OrderItem2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "externalId": n => { orderItem2.externalId = n.getStringValue(); },
        "imageName": n => { orderItem2.imageName = n.getStringValue(); },
        "minimumOrderQuantity": n => { orderItem2.minimumOrderQuantity = n.getNumberValue(); },
        "price": n => { orderItem2.price = n.getNumberValue(); },
        "productName": n => { orderItem2.productName = n.getStringValue(); },
        "quantity": n => { orderItem2.quantity = n.getNumberValue(); },
        "quantityIncrement": n => { orderItem2.quantityIncrement = n.getNumberValue(); },
        "sku": n => { orderItem2.sku = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOrganization(organization: Partial<Organization> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(organization),
        "accountSetup": n => { organization.accountSetup = n.getObjectValue<AccountSetup>(createAccountSetupFromDiscriminatorValue); },
        "billingAddress1": n => { organization.billingAddress1 = n.getStringValue(); },
        "billingAddress2": n => { organization.billingAddress2 = n.getStringValue(); },
        "billingCity": n => { organization.billingCity = n.getStringValue(); },
        "billingCountry": n => { organization.billingCountry = n.getStringValue(); },
        "billingPostalCode": n => { organization.billingPostalCode = n.getStringValue(); },
        "billingStateProvince": n => { organization.billingStateProvince = n.getStringValue(); },
        "email": n => { organization.email = n.getStringValue(); },
        "identityOrganizationId": n => { organization.identityOrganizationId = n.getStringValue(); },
        "latitude": n => { organization.latitude = n.getNumberValue(); },
        "logoUrl": n => { organization.logoUrl = n.getStringValue(); },
        "longitude": n => { organization.longitude = n.getNumberValue(); },
        "name": n => { organization.name = n.getStringValue(); },
        "organizationId": n => { organization.organizationId = n.getStringValue(); },
        "phoneNumber": n => { organization.phoneNumber = n.getStringValue(); },
        "priceTiers": n => { organization.priceTiers = n.getCollectionOfObjectValues<PriceTier>(createPriceTierFromDiscriminatorValue); },
        "subscriptions": n => { organization.subscriptions = n.getCollectionOfObjectValues<Subscription>(createSubscriptionFromDiscriminatorValue); },
        "terms": n => { organization.terms = n.getCollectionOfEnumValues<Term>(TermObject); },
        "websiteUrl": n => { organization.websiteUrl = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOrganization2(organization2: Partial<Organization2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "billingAddress1": n => { organization2.billingAddress1 = n.getStringValue(); },
        "billingAddress2": n => { organization2.billingAddress2 = n.getStringValue(); },
        "billingCity": n => { organization2.billingCity = n.getStringValue(); },
        "billingCountry": n => { organization2.billingCountry = n.getStringValue(); },
        "billingPostalCode": n => { organization2.billingPostalCode = n.getStringValue(); },
        "billingStateProvince": n => { organization2.billingStateProvince = n.getStringValue(); },
        "email": n => { organization2.email = n.getStringValue(); },
        "name": n => { organization2.name = n.getStringValue(); },
        "phoneNumber": n => { organization2.phoneNumber = n.getStringValue(); },
        "website": n => { organization2.website = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoOrganizationSalesRep(organizationSalesRep: Partial<OrganizationSalesRep> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(organizationSalesRep),
        "email": n => { organizationSalesRep.email = n.getStringValue(); },
        "firstName": n => { organizationSalesRep.firstName = n.getStringValue(); },
        "identityOrganizationId": n => { organizationSalesRep.identityOrganizationId = n.getStringValue(); },
        "identityProviderUserId": n => { organizationSalesRep.identityProviderUserId = n.getStringValue(); },
        "lastName": n => { organizationSalesRep.lastName = n.getStringValue(); },
        "organizationId": n => { organizationSalesRep.organizationId = n.getStringValue(); },
        "organizationName": n => { organizationSalesRep.organizationName = n.getStringValue(); },
        "organizationSalesRepId": n => { organizationSalesRep.organizationSalesRepId = n.getStringValue(); },
        "phoneNumber": n => { organizationSalesRep.phoneNumber = n.getStringValue(); },
        "repNumber": n => { organizationSalesRep.repNumber = n.getStringValue(); },
        "salesRepId": n => { organizationSalesRep.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoPagedResultOfCustomer(pagedResultOfCustomer: Partial<PagedResultOfCustomer> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currentPage": n => { pagedResultOfCustomer.currentPage = n.getNumberValue(); },
        "data": n => { pagedResultOfCustomer.data = n.getCollectionOfObjectValues<Customer3>(createCustomer3FromDiscriminatorValue); },
        "pageSize": n => { pagedResultOfCustomer.pageSize = n.getNumberValue(); },
        "totalPages": n => { pagedResultOfCustomer.totalPages = n.getNumberValue(); },
        "totalRecords": n => { pagedResultOfCustomer.totalRecords = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoPagedResultOfIOrder(pagedResultOfIOrder: Partial<PagedResultOfIOrder> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currentPage": n => { pagedResultOfIOrder.currentPage = n.getNumberValue(); },
        "data": n => { pagedResultOfIOrder.data = n.getCollectionOfObjectValues<IOrder>(createIOrderFromDiscriminatorValue); },
        "pageSize": n => { pagedResultOfIOrder.pageSize = n.getNumberValue(); },
        "totalPages": n => { pagedResultOfIOrder.totalPages = n.getNumberValue(); },
        "totalRecords": n => { pagedResultOfIOrder.totalRecords = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoPagedResultOfProduct(pagedResultOfProduct: Partial<PagedResultOfProduct> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currentPage": n => { pagedResultOfProduct.currentPage = n.getNumberValue(); },
        "data": n => { pagedResultOfProduct.data = n.getCollectionOfObjectValues<Product>(createProductFromDiscriminatorValue); },
        "pageSize": n => { pagedResultOfProduct.pageSize = n.getNumberValue(); },
        "totalPages": n => { pagedResultOfProduct.totalPages = n.getNumberValue(); },
        "totalRecords": n => { pagedResultOfProduct.totalRecords = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoPaymentMethod(paymentMethod: Partial<PaymentMethod> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "gateway": n => { paymentMethod.gateway = n.getStringValue(); },
        "gateway_account_id": n => { paymentMethod.gatewayAccountId = n.getStringValue(); },
        "object": n => { paymentMethod.object = n.getStringValue(); },
        "reference_id": n => { paymentMethod.referenceId = n.getStringValue(); },
        "status": n => { paymentMethod.status = n.getStringValue(); },
        "type": n => { paymentMethod.type = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoPortalSessionLinkResponse(portalSessionLinkResponse: Partial<PortalSessionLinkResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "sessionUrl": n => { portalSessionLinkResponse.sessionUrl = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoPriceTier(priceTier: Partial<PriceTier> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currencyCode": n => { priceTier.currencyCode = n.getEnumValue<CurrencyCode>(CurrencyCodeObject); },
        "name": n => { priceTier.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoProblemDetails(problemDetails: Partial<ProblemDetails> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "detail": n => { problemDetails.detail = n.getStringValue(); },
        "errors": n => { problemDetails.errors = n.getCollectionOfObjectValues<ProblemDetails_Error>(createProblemDetails_ErrorFromDiscriminatorValue); },
        "instance": n => { problemDetails.instance = n.getStringValue() ?? "/api/route"; },
        "status": n => { problemDetails.status = n.getNumberValue(); },
        "title": n => { problemDetails.title = n.getStringValue() ?? "One or more validation errors occurred."; },
        "traceId": n => { problemDetails.traceId = n.getStringValue() ?? "0HMPNHL0JHL76:00000001"; },
        "type": n => { problemDetails.type = n.getStringValue() ?? "https://www.rfc-editor.org/rfc/rfc7231#section-6.5.1"; },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoProblemDetails_Error(problemDetails_Error: Partial<ProblemDetails_Error> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "code": n => { problemDetails_Error.code = n.getStringValue(); },
        "name": n => { problemDetails_Error.name = n.getStringValue() ?? "Error or field name"; },
        "reason": n => { problemDetails_Error.reason = n.getStringValue() ?? "Error reason"; },
        "severity": n => { problemDetails_Error.severity = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoProduct(product: Partial<Product> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(product),
        "category": n => { product.category = n.getStringValue(); },
        "currencyCode": n => { product.currencyCode = n.getEnumValue<CurrencyCode>(CurrencyCodeObject); },
        "description": n => { product.description = n.getStringValue(); },
        "dimensions": n => { product.dimensions = n.getStringValue(); },
        "dimensionsUnit": n => { product.dimensionsUnit = n.getStringValue(); },
        "dynamicProperties": n => { product.dynamicProperties = n.getObjectValue<Product_dynamicProperties>(createProduct_dynamicPropertiesFromDiscriminatorValue); },
        "imageName": n => { product.imageName = n.getStringValue(); },
        "images": n => { product.images = n.getCollectionOfObjectValues<ImageAssignment>(createImageAssignmentFromDiscriminatorValue); },
        "minimumOrderQuantity": n => { product.minimumOrderQuantity = n.getNumberValue(); },
        "name": n => { product.name = n.getStringValue(); },
        "organizationId": n => { product.organizationId = n.getStringValue(); },
        "price": n => { product.price = n.getNumberValue(); },
        "prices": n => { product.prices = n.getCollectionOfObjectValues<ProductPrice>(createProductPriceFromDiscriminatorValue); },
        "productId": n => { product.productId = n.getStringValue(); },
        "productType": n => { product.productType = n.getEnumValue<ProductType>(ProductTypeObject); },
        "quantityIncrement": n => { product.quantityIncrement = n.getNumberValue(); },
        "quantityOnHand": n => { product.quantityOnHand = n.getNumberValue(); },
        "sku": n => { product.sku = n.getStringValue(); },
        "upc": n => { product.upc = n.getStringValue(); },
        "weight": n => { product.weight = n.getNumberValue(); },
        "weightUnit": n => { product.weightUnit = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoProduct_dynamicProperties(product_dynamicProperties: Partial<Product_dynamicProperties> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoProductPrice(productPrice: Partial<ProductPrice> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "atQuantity": n => { productPrice.atQuantity = n.getNumberValue(); },
        "price": n => { productPrice.price = n.getNumberValue(); },
        "tier": n => { productPrice.tier = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoReadmeAuthResponse(readmeAuthResponse: Partial<ReadmeAuthResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "readmeAuthUrl": n => { readmeAuthResponse.readmeAuthUrl = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoRep(rep: Partial<Rep> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { rep.email = n.getStringValue(); },
        "firstName": n => { rep.firstName = n.getStringValue(); },
        "fullName": n => { rep.fullName = n.getStringValue(); },
        "lastName": n => { rep.lastName = n.getStringValue(); },
        "organizationSalesRepId": n => { rep.organizationSalesRepId = n.getStringValue(); },
        "phoneNumber": n => { rep.phoneNumber = n.getStringValue(); },
        "repNumber": n => { rep.repNumber = n.getStringValue(); },
        "salesRepId": n => { rep.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoSalesRep(salesRep: Partial<SalesRep> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(salesRep),
        "email": n => { salesRep.email = n.getStringValue(); },
        "firstName": n => { salesRep.firstName = n.getStringValue(); },
        "fullName": n => { salesRep.fullName = n.getStringValue(); },
        "identityProvider": n => { salesRep.identityProvider = n.getStringValue(); },
        "identityProviderUserId": n => { salesRep.identityProviderUserId = n.getStringValue(); },
        "lastName": n => { salesRep.lastName = n.getStringValue(); },
        "phoneNumber": n => { salesRep.phoneNumber = n.getStringValue(); },
        "pictureUrl": n => { salesRep.pictureUrl = n.getStringValue(); },
        "salesRepId": n => { salesRep.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoSalesRep2(salesRep2: Partial<SalesRep2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { salesRep2.email = n.getStringValue(); },
        "firstName": n => { salesRep2.firstName = n.getStringValue(); },
        "fullName": n => { salesRep2.fullName = n.getStringValue(); },
        "lastName": n => { salesRep2.lastName = n.getStringValue(); },
        "organizationSalesRepId": n => { salesRep2.organizationSalesRepId = n.getStringValue(); },
        "phoneNumber": n => { salesRep2.phoneNumber = n.getStringValue(); },
        "repNumber": n => { salesRep2.repNumber = n.getStringValue(); },
        "salesRepId": n => { salesRep2.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoShippingDetails(shippingDetails: Partial<ShippingDetails> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "method": n => { shippingDetails.method = n.getStringValue(); },
        "total": n => { shippingDetails.total = n.getNumberValue(); },
        "totalFormatted": n => { shippingDetails.totalFormatted = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoShippingDetails2(shippingDetails2: Partial<ShippingDetails2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "method": n => { shippingDetails2.method = n.getStringValue(); },
        "total": n => { shippingDetails2.total = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoShoppingCart(shoppingCart: Partial<ShoppingCart> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(shoppingCart),
        "billingAddress": n => { shoppingCart.billingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "cartItems": n => { shoppingCart.cartItems = n.getCollectionOfObjectValues<CartItem>(createCartItemFromDiscriminatorValue); },
        "companyEmail": n => { shoppingCart.companyEmail = n.getStringValue(); },
        "companyFax": n => { shoppingCart.companyFax = n.getStringValue(); },
        "companyName": n => { shoppingCart.companyName = n.getStringValue(); },
        "companyPhone": n => { shoppingCart.companyPhone = n.getStringValue(); },
        "currencyCode": n => { shoppingCart.currencyCode = n.getStringValue(); },
        "customerNumber": n => { shoppingCart.customerNumber = n.getStringValue(); },
        "notes": n => { shoppingCart.notes = n.getStringValue(); },
        "orderStatus": n => { shoppingCart.orderStatus = n.getStringValue(); },
        "orderTotal": n => { shoppingCart.orderTotal = n.getNumberValue(); },
        "orderTotalFormatted": n => { shoppingCart.orderTotalFormatted = n.getStringValue(); },
        "orderType": n => { shoppingCart.orderType = n.getStringValue(); },
        "organizationId": n => { shoppingCart.organizationId = n.getStringValue(); },
        "poNumber": n => { shoppingCart.poNumber = n.getStringValue(); },
        "priceTier": n => { shoppingCart.priceTier = n.getStringValue(); },
        "primarySalesRep": n => { shoppingCart.primarySalesRep = n.getObjectValue<Rep>(createRepFromDiscriminatorValue); },
        "shipping": n => { shoppingCart.shipping = n.getNumberValue(); },
        "shippingAddress": n => { shoppingCart.shippingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "shippingFormatted": n => { shoppingCart.shippingFormatted = n.getStringValue(); },
        "shippingMethod": n => { shoppingCart.shippingMethod = n.getStringValue(); },
        "shoppingCartId": n => { shoppingCart.shoppingCartId = n.getStringValue(); },
        "subtotal": n => { shoppingCart.subtotal = n.getNumberValue(); },
        "subtotalFormatted": n => { shoppingCart.subtotalFormatted = n.getStringValue(); },
        "takenBySalesRep": n => { shoppingCart.takenBySalesRep = n.getObjectValue<Rep>(createRepFromDiscriminatorValue); },
        "tax": n => { shoppingCart.tax = n.getNumberValue(); },
        "taxFormatted": n => { shoppingCart.taxFormatted = n.getStringValue(); },
        "terms": n => { shoppingCart.terms = n.getStringValue(); },
        "user": n => { shoppingCart.user = n.getObjectValue<User3>(createUser3FromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoStep(step: Partial<Step> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "isComplete": n => { step.isComplete = n.getBooleanValue(); },
        "name": n => { step.name = n.getStringValue(); },
        "order": n => { step.order = n.getNumberValue(); },
        "stepId": n => { step.stepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoSubscription(subscription: Partial<Subscription> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "id": n => { subscription.id = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoSubscription2(subscription2: Partial<Subscription2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "activated_at": n => { subscription2.activatedAt = n.getNumberValue(); },
        "billing_period": n => { subscription2.billingPeriod = n.getNumberValue(); },
        "billing_period_unit": n => { subscription2.billingPeriodUnit = n.getStringValue(); },
        "channel": n => { subscription2.channel = n.getStringValue(); },
        "created_at": n => { subscription2.createdAt = n.getNumberValue(); },
        "created_from_ip": n => { subscription2.createdFromIp = n.getStringValue(); },
        "currency_code": n => { subscription2.currencyCode = n.getStringValue(); },
        "current_term_end": n => { subscription2.currentTermEnd = n.getNumberValue(); },
        "current_term_start": n => { subscription2.currentTermStart = n.getNumberValue(); },
        "customer_id": n => { subscription2.customerId = n.getStringValue(); },
        "deleted": n => { subscription2.deleted = n.getBooleanValue(); },
        "due_invoices_count": n => { subscription2.dueInvoicesCount = n.getNumberValue(); },
        "has_scheduled_advance_invoices": n => { subscription2.hasScheduledAdvanceInvoices = n.getBooleanValue(); },
        "has_scheduled_changes": n => { subscription2.hasScheduledChanges = n.getBooleanValue(); },
        "id": n => { subscription2.id = n.getStringValue(); },
        "mrr": n => { subscription2.mrr = n.getNumberValue(); },
        "next_billing_at": n => { subscription2.nextBillingAt = n.getNumberValue(); },
        "object": n => { subscription2.object = n.getStringValue(); },
        "resource_version": n => { subscription2.resourceVersion = n.getNumberValue(); },
        "shipping_address": n => { subscription2.shippingAddress = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "started_at": n => { subscription2.startedAt = n.getNumberValue(); },
        "status": n => { subscription2.status = n.getStringValue(); },
        "subscription_items": n => { subscription2.subscriptionItems = n.getCollectionOfObjectValues<SubscriptionItem>(createSubscriptionItemFromDiscriminatorValue); },
        "updated_at": n => { subscription2.updatedAt = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoSubscriptionCreatedRequest(subscriptionCreatedRequest: Partial<SubscriptionCreatedRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "api_version": n => { subscriptionCreatedRequest.apiVersion = n.getStringValue(); },
        "content": n => { subscriptionCreatedRequest.content = n.getObjectValue<Content>(createContentFromDiscriminatorValue); },
        "event_type": n => { subscriptionCreatedRequest.eventType = n.getStringValue(); },
        "id": n => { subscriptionCreatedRequest.id = n.getStringValue(); },
        "object": n => { subscriptionCreatedRequest.object = n.getStringValue(); },
        "occurred_at": n => { subscriptionCreatedRequest.occurredAt = n.getNumberValue(); },
        "source": n => { subscriptionCreatedRequest.source = n.getStringValue(); },
        "webhook_status": n => { subscriptionCreatedRequest.webhookStatus = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoSubscriptionEntitlement(subscriptionEntitlement: Partial<SubscriptionEntitlement> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "featureId": n => { subscriptionEntitlement.featureId = n.getStringValue(); },
        "featureName": n => { subscriptionEntitlement.featureName = n.getStringValue(); },
        "id": n => { subscriptionEntitlement.id = n.getStringValue(); },
        "isOverridden": n => { subscriptionEntitlement.isOverridden = n.getBooleanValue(); },
        "name": n => { subscriptionEntitlement.name = n.getStringValue(); },
        "subscriptionId": n => { subscriptionEntitlement.subscriptionId = n.getStringValue(); },
        "value": n => { subscriptionEntitlement.value = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoSubscriptionItem(subscriptionItem: Partial<SubscriptionItem> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "amount": n => { subscriptionItem.amount = n.getNumberValue(); },
        "free_quantity": n => { subscriptionItem.freeQuantity = n.getNumberValue(); },
        "item_price_id": n => { subscriptionItem.itemPriceId = n.getStringValue(); },
        "item_type": n => { subscriptionItem.itemType = n.getStringValue(); },
        "object": n => { subscriptionItem.object = n.getStringValue(); },
        "quantity": n => { subscriptionItem.quantity = n.getNumberValue(); },
        "unit_price": n => { subscriptionItem.unitPrice = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUpdateCustomerRequest(updateCustomerRequest: Partial<UpdateCustomerRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "billingAddress": n => { updateCustomerRequest.billingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "companyEmail": n => { updateCustomerRequest.companyEmail = n.getStringValue(); },
        "companyFax": n => { updateCustomerRequest.companyFax = n.getStringValue(); },
        "companyName": n => { updateCustomerRequest.companyName = n.getStringValue(); },
        "companyPhone": n => { updateCustomerRequest.companyPhone = n.getStringValue(); },
        "customerNumber": n => { updateCustomerRequest.customerNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUpdateItemRequest(updateItemRequest: Partial<UpdateItemRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "quantity": n => { updateItemRequest.quantity = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUpdatePriceTierRequest(updatePriceTierRequest: Partial<UpdatePriceTierRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currencyCode": n => { updatePriceTierRequest.currencyCode = n.getStringValue(); },
        "newName": n => { updatePriceTierRequest.newName = n.getStringValue(); },
        "oldName": n => { updatePriceTierRequest.oldName = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUpdateShippingRequest(updateShippingRequest: Partial<UpdateShippingRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "method": n => { updateShippingRequest.method = n.getStringValue(); },
        "shippingAddress": n => { updateShippingRequest.shippingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "total": n => { updateShippingRequest.total = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUser(user: Partial<User> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { user.email = n.getStringValue(); },
        "firstName": n => { user.firstName = n.getStringValue(); },
        "lastName": n => { user.lastName = n.getStringValue(); },
        "password": n => { user.password = n.getStringValue(); },
        "phoneNumber": n => { user.phoneNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUser2(user2: Partial<User2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "identityProviderUserId": n => { user2.identityProviderUserId = n.getStringValue(); },
        "userId": n => { user2.userId = n.getStringValue(); },
        "userType": n => { user2.userType = n.getEnumValue<UserTypes>(UserTypesObject); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUser3(user3: Partial<User3> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "identityProviderUserId": n => { user3.identityProviderUserId = n.getStringValue(); },
        "userId": n => { user3.userId = n.getStringValue(); },
        "userType": n => { user3.userType = n.getEnumValue<UserTypes>(UserTypesObject); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
// @ts-ignore
export function deserializeIntoUserExistsResponse(userExistsResponse: Partial<UserExistsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "userExists": n => { userExistsResponse.userExists = n.getBooleanValue(); },
    }
}
export interface EnrichM2MResponse extends Parsable {
    /**
     * The organizationId property
     */
    organizationId?: string | null;
}
export interface EntitlementsResponse extends Parsable {
    /**
     * The entitlements property
     */
    entitlements?: ListOfEntitlements[] | null;
}
export interface ErrorResponse extends ApiError, Parsable {
    /**
     * The errors property
     */
    errors?: ErrorResponse_errors | null;
    /**
     * The message property
     */
    messageEscaped?: string | null;
    /**
     * The statusCode property
     */
    statusCode?: number | null;
}
export interface ErrorResponse_errors extends AdditionalDataHolder, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
}
export interface ExistsResponse extends Parsable {
    /**
     * The exitsInOrganization property
     */
    exitsInOrganization?: boolean | null;
    /**
     * The identityExists property
     */
    identityExists?: boolean | null;
}
export interface GetApiAccessResponse extends Parsable {
    /**
     * The apiAccess property
     */
    apiAccess?: ApiAccess | null;
}
export interface GetOrganizationResponse extends Parsable {
    /**
     * The entitlements property
     */
    entitlements?: ListOfEntitlements[] | null;
    /**
     * The organization property
     */
    organization?: Organization | null;
    /**
     * The subscriptions property
     */
    subscriptions?: Subscription[] | null;
}
export interface HeaderColumnMap extends Parsable {
    /**
     * The header property
     */
    header?: string | null;
    /**
     * The mapping property
     */
    mapping?: HeaderMapping | null;
}
export interface HeaderMapping extends Parsable {
    /**
     * The mappedHeaderName property
     */
    mappedHeaderName?: string | null;
    /**
     * The uploadedHeaderName property
     */
    uploadedHeaderName?: string | null;
}
export interface ImageAssignment extends Parsable {
    /**
     * The format property
     */
    format?: ImageFormat | null;
    /**
     * The imageId property
     */
    imageId?: string | null;
    /**
     * The imageName property
     */
    imageName?: string | null;
    /**
     * The position property
     */
    position?: number | null;
}
export type ImageFormat = (typeof ImageFormatObject)[keyof typeof ImageFormatObject];
export interface IngAddress extends Parsable {
    /**
     * The city property
     */
    city?: string | null;
    /**
     * The country property
     */
    country?: string | null;
    /**
     * The first_name property
     */
    firstName?: string | null;
    /**
     * The last_name property
     */
    lastName?: string | null;
    /**
     * The line1 property
     */
    line1?: string | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The state property
     */
    state?: string | null;
    /**
     * The state_code property
     */
    stateCode?: string | null;
    /**
     * The validation_status property
     */
    validationStatus?: string | null;
    /**
     * The zip property
     */
    zip?: number | null;
}
export interface InternalErrorResponse extends ApiError, Parsable {
    /**
     * The code property
     */
    code?: number | null;
    /**
     * The note property
     */
    note?: string | null;
    /**
     * The reason property
     */
    reason?: string | null;
    /**
     * The status property
     */
    status?: string | null;
}
export interface Invoice extends Parsable {
    /**
     * The adjustment_credit_notes property
     */
    adjustmentCreditNotes?: UntypedNode | null;
    /**
     * The amount_adjusted property
     */
    amountAdjusted?: number | null;
    /**
     * The amount_due property
     */
    amountDue?: number | null;
    /**
     * The amount_paid property
     */
    amountPaid?: number | null;
    /**
     * The amount_to_collect property
     */
    amountToCollect?: number | null;
    /**
     * The applied_credits property
     */
    appliedCredits?: UntypedNode | null;
    /**
     * The base_currency_code property
     */
    baseCurrencyCode?: string | null;
    /**
     * The billing_address property
     */
    billingAddress?: IngAddress | null;
    /**
     * The channel property
     */
    channel?: string | null;
    /**
     * The credits_applied property
     */
    creditsApplied?: number | null;
    /**
     * The currency_code property
     */
    currencyCode?: string | null;
    /**
     * The customer_id property
     */
    customerId?: string | null;
    /**
     * The date property
     */
    date?: number | null;
    /**
     * The deleted property
     */
    deleted?: boolean | null;
    /**
     * The due_date property
     */
    dueDate?: number | null;
    /**
     * The dunning_attempts property
     */
    dunningAttempts?: UntypedNode | null;
    /**
     * The exchange_rate property
     */
    exchangeRate?: number | null;
    /**
     * The first_invoice property
     */
    firstInvoice?: boolean | null;
    /**
     * The generated_at property
     */
    generatedAt?: number | null;
    /**
     * The has_advance_charges property
     */
    hasAdvanceCharges?: boolean | null;
    /**
     * The id property
     */
    id?: number | null;
    /**
     * The is_gifted property
     */
    isGifted?: boolean | null;
    /**
     * The issued_credit_notes property
     */
    issuedCreditNotes?: UntypedNode | null;
    /**
     * The line_items property
     */
    lineItems?: LineItem[] | null;
    /**
     * The linked_orders property
     */
    linkedOrders?: UntypedNode | null;
    /**
     * The linked_payments property
     */
    linkedPayments?: LinkedPayment[] | null;
    /**
     * The net_term_days property
     */
    netTermDays?: number | null;
    /**
     * The new_sales_amount property
     */
    newSalesAmount?: number | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The paid_at property
     */
    paidAt?: number | null;
    /**
     * The price_type property
     */
    priceType?: string | null;
    /**
     * The recurring property
     */
    recurring?: boolean | null;
    /**
     * The resource_version property
     */
    resourceVersion?: number | null;
    /**
     * The round_off_amount property
     */
    roundOffAmount?: number | null;
    /**
     * The shipping_address property
     */
    shippingAddress?: IngAddress | null;
    /**
     * The status property
     */
    status?: string | null;
    /**
     * The subscription_id property
     */
    subscriptionId?: string | null;
    /**
     * The sub_total property
     */
    subTotal?: number | null;
    /**
     * The tax property
     */
    tax?: number | null;
    /**
     * The term_finalized property
     */
    termFinalized?: boolean | null;
    /**
     * The total property
     */
    total?: number | null;
    /**
     * The updated_at property
     */
    updatedAt?: number | null;
    /**
     * The write_off_amount property
     */
    writeOffAmount?: number | null;
}
export interface IOrder extends Parsable {
    /**
     * The channel property
     */
    channel?: string | null;
    /**
     * The currencyCode property
     */
    currencyCode?: string | null;
    /**
     * The customer property
     */
    customer?: Customer2 | null;
    /**
     * The externalId property
     */
    externalId?: string | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The items property
     */
    items?: OrderItem[] | null;
    /**
     * The notes property
     */
    notes?: string | null;
    /**
     * The orderDate property
     */
    orderDate?: Date | null;
    /**
     * The orderNumber property
     */
    orderNumber?: string | null;
    /**
     * The orderStatus property
     */
    orderStatus?: string | null;
    /**
     * The orderTotal property
     */
    orderTotal?: number | null;
    /**
     * The orderTotalFormatted property
     */
    orderTotalFormatted?: string | null;
    /**
     * The orderType property
     */
    orderType?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The originationCartId property
     */
    originationCartId?: string | null;
    /**
     * The poNumber property
     */
    poNumber?: string | null;
    /**
     * The priceTier property
     */
    priceTier?: string | null;
    /**
     * The primarySalesRep property
     */
    primarySalesRep?: SalesRep2 | null;
    /**
     * The shippingDetails property
     */
    shippingDetails?: ShippingDetails | null;
    /**
     * The shippingFormatted property
     */
    shippingFormatted?: string | null;
    /**
     * The subtotal property
     */
    subtotal?: number | null;
    /**
     * The subtotalFormatted property
     */
    subtotalFormatted?: string | null;
    /**
     * The takenBySalesRep property
     */
    takenBySalesRep?: SalesRep2 | null;
    /**
     * The tax property
     */
    tax?: number | null;
    /**
     * The taxFormatted property
     */
    taxFormatted?: string | null;
    /**
     * The terms property
     */
    terms?: string | null;
    /**
     * The user property
     */
    user?: User2 | null;
}
export interface LineItem extends Parsable {
    /**
     * The amount property
     */
    amount?: number | null;
    /**
     * The customer_id property
     */
    customerId?: string | null;
    /**
     * The date_from property
     */
    dateFrom?: number | null;
    /**
     * The date_to property
     */
    dateTo?: number | null;
    /**
     * The description property
     */
    description?: string | null;
    /**
     * The discount_amount property
     */
    discountAmount?: number | null;
    /**
     * The entity_id property
     */
    entityId?: string | null;
    /**
     * The entity_type property
     */
    entityType?: string | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The is_taxed property
     */
    isTaxed?: boolean | null;
    /**
     * The item_level_discount_amount property
     */
    itemLevelDiscountAmount?: number | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The pricing_model property
     */
    pricingModel?: string | null;
    /**
     * The quantity property
     */
    quantity?: number | null;
    /**
     * The subscription_id property
     */
    subscriptionId?: string | null;
    /**
     * The tax_amount property
     */
    taxAmount?: number | null;
    /**
     * The tax_exempt_reason property
     */
    taxExemptReason?: string | null;
    /**
     * The unit_amount property
     */
    unitAmount?: number | null;
}
/**
 * Link class
 */
export interface Link extends Parsable {
    /**
     * Action Specifies the HTTP method used to access the resource
     */
    action?: string | null;
    /**
     * Href Specifies the linked resource’s address
     */
    href?: string | null;
    /**
     * Rel Specifies the relationship between the resource and the resource identified in the link
     */
    rel?: string | null;
    /**
     * The supported MIME types.
     */
    types?: string[] | null;
}
export interface LinkedPayment extends Parsable {
    /**
     * The applied_amount property
     */
    appliedAmount?: number | null;
    /**
     * The applied_at property
     */
    appliedAt?: number | null;
    /**
     * The txn_amount property
     */
    txnAmount?: number | null;
    /**
     * The txn_date property
     */
    txnDate?: number | null;
    /**
     * The txn_id property
     */
    txnId?: string | null;
    /**
     * The txn_status property
     */
    txnStatus?: string | null;
}
export interface ListOfEntitlements extends Parsable {
    /**
     * The subscriptionEntitlement property
     */
    subscriptionEntitlement?: SubscriptionEntitlement | null;
}
export interface ListOrganizationSalesRepsResponse extends Parsable {
    /**
     * The active property
     */
    active?: boolean | null;
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The firstName property
     */
    firstName?: string | null;
    /**
     * The lastName property
     */
    lastName?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The organizationSalesRepId property
     */
    organizationSalesRepId?: string | null;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string | null;
    /**
     * The repNumber property
     */
    repNumber?: string | null;
    /**
     * The salesRepId property
     */
    salesRepId?: string | null;
}
export interface ListSalesRepOrganizationsResponse extends Parsable {
    /**
     * The salesRepOrganizations property
     */
    salesRepOrganizations?: OrganizationSalesRep[] | null;
}
export interface ListUserOrganizationsResponse extends Parsable {
    /**
     * The organizations property
     */
    organizations?: Organization[] | null;
}
export interface NotFound extends ApiError, Parsable {
    /**
     * The statusCode property
     */
    statusCode?: number | null;
}
export interface Ok extends Parsable {
    /**
     * The statusCode property
     */
    statusCode?: number | null;
}
export interface Order extends Parsable {
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The orderNumber property
     */
    orderNumber?: string | null;
}
export interface OrderDetailsResponse extends Parsable {
    /**
     * The data property
     */
    data?: IOrder | null;
    /**
     * The links property
     */
    links?: Link[] | null;
}
export interface OrderItem extends Parsable {
    /**
     * The externalId property
     */
    externalId?: string | null;
    /**
     * The imageName property
     */
    imageName?: string | null;
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number | null;
    /**
     * The price property
     */
    price?: number | null;
    /**
     * The priceFormatted property
     */
    priceFormatted?: string | null;
    /**
     * The productName property
     */
    productName?: string | null;
    /**
     * The quantity property
     */
    quantity?: number | null;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number | null;
    /**
     * The sku property
     */
    sku?: string | null;
    /**
     * The subTotal property
     */
    subTotal?: number | null;
    /**
     * The subTotalFormatted property
     */
    subTotalFormatted?: string | null;
}
export interface OrderItem2 extends Parsable {
    /**
     * The externalId property
     */
    externalId?: string | null;
    /**
     * The imageName property
     */
    imageName?: string | null;
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number | null;
    /**
     * The price property
     */
    price?: number | null;
    /**
     * The productName property
     */
    productName?: string | null;
    /**
     * The quantity property
     */
    quantity?: number | null;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number | null;
    /**
     * The sku property
     */
    sku?: string | null;
}
export interface Organization extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * The accountSetup property
     */
    accountSetup?: AccountSetup | null;
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The billingAddress1 property
     */
    billingAddress1?: string | null;
    /**
     * The billingAddress2 property
     */
    billingAddress2?: string | null;
    /**
     * The billingCity property
     */
    billingCity?: string | null;
    /**
     * The billingCountry property
     */
    billingCountry?: string | null;
    /**
     * The billingPostalCode property
     */
    billingPostalCode?: string | null;
    /**
     * The billingStateProvince property
     */
    billingStateProvince?: string | null;
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The identityOrganizationId property
     */
    identityOrganizationId?: string | null;
    /**
     * The latitude property
     */
    latitude?: number | null;
    /**
     * The logoUrl property
     */
    logoUrl?: string | null;
    /**
     * The longitude property
     */
    longitude?: number | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string | null;
    /**
     * The priceTiers property
     */
    priceTiers?: PriceTier[] | null;
    /**
     * The subscriptions property
     */
    subscriptions?: Subscription[] | null;
    /**
     * The terms property
     */
    terms?: Term[] | null;
    /**
     * The websiteUrl property
     */
    websiteUrl?: string | null;
}
export interface Organization2 extends Parsable {
    /**
     * Gets or sets the billing address of the organization.
     */
    billingAddress1?: string | null;
    /**
     * Gets or sets the second line of the billing address of the organization.
     */
    billingAddress2?: string | null;
    /**
     * Gets or sets the billing city of the organization.
     */
    billingCity?: string | null;
    /**
     * Gets or sets the billing country of the organization.
     */
    billingCountry?: string | null;
    /**
     * Gets or sets the billing postal code of the organization.
     */
    billingPostalCode?: string | null;
    /**
     * Gets or sets the billing state/province of the organization.
     */
    billingStateProvince?: string | null;
    /**
     * Gets or sets the email of the organization.
     */
    email?: string | null;
    /**
     * Gets or sets the name of the organization.
     */
    name?: string | null;
    /**
     * Gets or sets the phone number of the organization. 
     */
    phoneNumber?: string | null;
    /**
     * Gets or sets the website of the organization.
     */
    website?: string | null;
}
export interface OrganizationSalesRep extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The firstName property
     */
    firstName?: string | null;
    /**
     * The identityOrganizationId property
     */
    identityOrganizationId?: string | null;
    /**
     * The identityProviderUserId property
     */
    identityProviderUserId?: string | null;
    /**
     * The lastName property
     */
    lastName?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The organizationName property
     */
    organizationName?: string | null;
    /**
     * The organizationSalesRepId property
     */
    organizationSalesRepId?: string | null;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string | null;
    /**
     * The repNumber property
     */
    repNumber?: string | null;
    /**
     * The salesRepId property
     */
    salesRepId?: string | null;
}
export interface PagedResultOfCustomer extends Parsable {
    /**
     * The currentPage property
     */
    currentPage?: number | null;
    /**
     * The data property
     */
    data?: Customer3[] | null;
    /**
     * The pageSize property
     */
    pageSize?: number | null;
    /**
     * The totalPages property
     */
    totalPages?: number | null;
    /**
     * The totalRecords property
     */
    totalRecords?: number | null;
}
export interface PagedResultOfIOrder extends Parsable {
    /**
     * The currentPage property
     */
    currentPage?: number | null;
    /**
     * The data property
     */
    data?: IOrder[] | null;
    /**
     * The pageSize property
     */
    pageSize?: number | null;
    /**
     * The totalPages property
     */
    totalPages?: number | null;
    /**
     * The totalRecords property
     */
    totalRecords?: number | null;
}
export interface PagedResultOfProduct extends Parsable {
    /**
     * The currentPage property
     */
    currentPage?: number | null;
    /**
     * The data property
     */
    data?: Product[] | null;
    /**
     * The pageSize property
     */
    pageSize?: number | null;
    /**
     * The totalPages property
     */
    totalPages?: number | null;
    /**
     * The totalRecords property
     */
    totalRecords?: number | null;
}
export interface PaymentMethod extends Parsable {
    /**
     * The gateway property
     */
    gateway?: string | null;
    /**
     * The gateway_account_id property
     */
    gatewayAccountId?: string | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The reference_id property
     */
    referenceId?: string | null;
    /**
     * The status property
     */
    status?: string | null;
    /**
     * The type property
     */
    type?: string | null;
}
export interface PortalSessionLinkResponse extends Parsable {
    /**
     * The sessionUrl property
     */
    sessionUrl?: string | null;
}
export interface PriceTier extends Parsable {
    /**
     * The currencyCode property
     */
    currencyCode?: CurrencyCode | null;
    /**
     * The name property
     */
    name?: string | null;
}
export interface ProblemDetails extends ApiError, Parsable {
    /**
     * The detail property
     */
    detail?: string | null;
    /**
     * The errors property
     */
    errors?: ProblemDetails_Error[] | null;
    /**
     * The instance property
     */
    instance?: string | null;
    /**
     * The status property
     */
    status?: number | null;
    /**
     * The title property
     */
    title?: string | null;
    /**
     * The traceId property
     */
    traceId?: string | null;
    /**
     * The type property
     */
    type?: string | null;
}
export interface ProblemDetails_Error extends Parsable {
    /**
     * The code property
     */
    code?: string | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The reason property
     */
    reason?: string | null;
    /**
     * The severity property
     */
    severity?: string | null;
}
export interface Product extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The category property
     */
    category?: string | null;
    /**
     * The currencyCode property
     */
    currencyCode?: CurrencyCode | null;
    /**
     * The description property
     */
    description?: string | null;
    /**
     * The dimensions property
     */
    dimensions?: string | null;
    /**
     * The dimensionsUnit property
     */
    dimensionsUnit?: string | null;
    /**
     * The dynamicProperties property
     */
    dynamicProperties?: Product_dynamicProperties | null;
    /**
     * The imageName property
     */
    imageName?: string | null;
    /**
     * The images property
     */
    images?: ImageAssignment[] | null;
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The price property
     */
    price?: number | null;
    /**
     * The prices property
     */
    prices?: ProductPrice[] | null;
    /**
     * The productId property
     */
    productId?: string | null;
    /**
     * The productType property
     */
    productType?: ProductType | null;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number | null;
    /**
     * The quantityOnHand property
     */
    quantityOnHand?: number | null;
    /**
     * The sku property
     */
    sku?: string | null;
    /**
     * The upc property
     */
    upc?: string | null;
    /**
     * The weight property
     */
    weight?: number | null;
    /**
     * The weightUnit property
     */
    weightUnit?: string | null;
}
export interface Product_dynamicProperties extends AdditionalDataHolder, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
}
export interface ProductPrice extends Parsable {
    /**
     * The atQuantity property
     */
    atQuantity?: number | null;
    /**
     * The price property
     */
    price?: number | null;
    /**
     * The tier property
     */
    tier?: string | null;
}
export type ProductType = (typeof ProductTypeObject)[keyof typeof ProductTypeObject];
export interface ReadmeAuthResponse extends Parsable {
    /**
     * The readmeAuthUrl property
     */
    readmeAuthUrl?: string | null;
}
export interface Rep extends Parsable {
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The firstName property
     */
    firstName?: string | null;
    /**
     * The fullName property
     */
    fullName?: string | null;
    /**
     * The lastName property
     */
    lastName?: string | null;
    /**
     * The organizationSalesRepId property
     */
    organizationSalesRepId?: string | null;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string | null;
    /**
     * The repNumber property
     */
    repNumber?: string | null;
    /**
     * The salesRepId property
     */
    salesRepId?: string | null;
}
export interface SalesRep extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The firstName property
     */
    firstName?: string | null;
    /**
     * The fullName property
     */
    fullName?: string | null;
    /**
     * The identityProvider property
     */
    identityProvider?: string | null;
    /**
     * The identityProviderUserId property
     */
    identityProviderUserId?: string | null;
    /**
     * The lastName property
     */
    lastName?: string | null;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string | null;
    /**
     * The pictureUrl property
     */
    pictureUrl?: string | null;
    /**
     * The salesRepId property
     */
    salesRepId?: string | null;
}
export interface SalesRep2 extends Parsable {
    /**
     * The email property
     */
    email?: string | null;
    /**
     * The firstName property
     */
    firstName?: string | null;
    /**
     * The fullName property
     */
    fullName?: string | null;
    /**
     * The lastName property
     */
    lastName?: string | null;
    /**
     * The organizationSalesRepId property
     */
    organizationSalesRepId?: string | null;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string | null;
    /**
     * The repNumber property
     */
    repNumber?: string | null;
    /**
     * The salesRepId property
     */
    salesRepId?: string | null;
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeAccountSetup(writer: SerializationWriter, accountSetup: Partial<AccountSetup> | undefined | null = {}) : void {
    if (accountSetup) {
        writer.writeBooleanValue("isComplete", accountSetup.isComplete);
        writer.writeObjectValue<Step>("nextStep", accountSetup.nextStep, serializeStep);
        writer.writeNumberValue("percentageComplete", accountSetup.percentageComplete);
        writer.writeCollectionOfObjectValues<Step>("steps", accountSetup.steps, serializeStep);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeActivateSalesRepRequest(writer: SerializationWriter, activateSalesRepRequest: Partial<ActivateSalesRepRequest> | undefined | null = {}) : void {
    if (activateSalesRepRequest) {
        writer.writeStringValue("organizationSalesRepId", activateSalesRepRequest.organizationSalesRepId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeAddItemRequest(writer: SerializationWriter, addItemRequest: Partial<AddItemRequest> | undefined | null = {}) : void {
    if (addItemRequest) {
        writer.writeNumberValue("quantity", addItemRequest.quantity);
        writer.writeStringValue("sku", addItemRequest.sku);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeAddPriceTierRequest(writer: SerializationWriter, addPriceTierRequest: Partial<AddPriceTierRequest> | undefined | null = {}) : void {
    if (addPriceTierRequest) {
        writer.writeStringValue("currencyCode", addPriceTierRequest.currencyCode);
        writer.writeStringValue("name", addPriceTierRequest.name);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeAddress(writer: SerializationWriter, address: Partial<Address> | undefined | null = {}) : void {
    if (address) {
        writer.writeStringValue("address1", address.address1);
        writer.writeStringValue("address2", address.address2);
        writer.writeEnumValue<AddressType>("addressType", address.addressType);
        writer.writeStringValue("city", address.city);
        writer.writeStringValue("code", address.code);
        writer.writeStringValue("country", address.country);
        writer.writeBooleanValue("default", address.defaultEscaped);
        writer.writeStringValue("externalId", address.externalId);
        writer.writeNumberValue("latitude", address.latitude);
        writer.writeNumberValue("longitude", address.longitude);
        writer.writeStringValue("stateProvince", address.stateProvince);
        writer.writeStringValue("zipPostalCode", address.zipPostalCode);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeAddTermRequest(writer: SerializationWriter, addTermRequest: Partial<AddTermRequest> | undefined | null = {}) : void {
    if (addTermRequest) {
        writer.writeEnumValue<Term>("term", addTermRequest.term);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeApiAccess(writer: SerializationWriter, apiAccess: Partial<ApiAccess> | undefined | null = {}) : void {
    if (apiAccess) {
        serializeBaseEntity(writer, apiAccess)
        writer.writeStringValue("clientId", apiAccess.clientId);
        writer.writeStringValue("clientSecret", apiAccess.clientSecret);
        writer.writeStringValue("id", apiAccess.id);
        writer.writeStringValue("organizationId", apiAccess.organizationId);
        writer.writeStringValue("orgCode", apiAccess.orgCode);
        writer.writeAdditionalData(apiAccess.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeApiAuthenticationRequest(writer: SerializationWriter, apiAuthenticationRequest: Partial<ApiAuthenticationRequest> | undefined | null = {}) : void {
    if (apiAuthenticationRequest) {
        writer.writeStringValue("clientId", apiAuthenticationRequest.clientId ?? "sdaf43tfsdg45dg");
        writer.writeStringValue("clientSecret", apiAuthenticationRequest.clientSecret ?? "QsFdas%4F1asr23tgb675%3");
        writer.writeStringValue("orgCode", apiAuthenticationRequest.orgCode ?? "org_12345");
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeApiAuthenticationResponse(writer: SerializationWriter, apiAuthenticationResponse: Partial<ApiAuthenticationResponse> | undefined | null = {}) : void {
    if (apiAuthenticationResponse) {
        writer.writeStringValue("accessToken", apiAuthenticationResponse.accessToken);
        writer.writeNumberValue("expiresIn", apiAuthenticationResponse.expiresIn);
        writer.writeStringValue("tokenType", apiAuthenticationResponse.tokenType);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeBaseEntity(writer: SerializationWriter, baseEntity: Partial<BaseEntity> | undefined | null = {}) : void {
    if (baseEntity) {
        writer.writeBooleanValue("active", baseEntity.active);
        writer.writeDateValue("createdAt", baseEntity.createdAt);
        writer.writeDateValue("updatedAt", baseEntity.updatedAt);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCard(writer: SerializationWriter, card: Partial<Card> | undefined | null = {}) : void {
    if (card) {
        writer.writeStringValue("card_type", card.cardType);
        writer.writeNumberValue("created_at", card.createdAt);
        writer.writeStringValue("customer_id", card.customerId);
        writer.writeNumberValue("expiry_month", card.expiryMonth);
        writer.writeNumberValue("expiry_year", card.expiryYear);
        writer.writeStringValue("funding_type", card.fundingType);
        writer.writeStringValue("gateway", card.gateway);
        writer.writeStringValue("gateway_account_id", card.gatewayAccountId);
        writer.writeNumberValue("iin", card.iin);
        writer.writeStringValue("ip_address", card.ipAddress);
        writer.writeNumberValue("last4", card.last4);
        writer.writeStringValue("masked_number", card.maskedNumber);
        writer.writeStringValue("object", card.object);
        writer.writeStringValue("payment_source_id", card.paymentSourceId);
        writer.writeNumberValue("resource_version", card.resourceVersion);
        writer.writeStringValue("status", card.status);
        writer.writeNumberValue("updated_at", card.updatedAt);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCartItem(writer: SerializationWriter, cartItem: Partial<CartItem> | undefined | null = {}) : void {
    if (cartItem) {
        serializeBaseEntity(writer, cartItem)
        writer.writeStringValue("imageName", cartItem.imageName);
        writer.writeNumberValue("minimumOrderQuantity", cartItem.minimumOrderQuantity);
        writer.writeNumberValue("price", cartItem.price);
        writer.writeStringValue("priceFormatted", cartItem.priceFormatted);
        writer.writeStringValue("productName", cartItem.productName);
        writer.writeNumberValue("quantity", cartItem.quantity);
        writer.writeNumberValue("quantityIncrement", cartItem.quantityIncrement);
        writer.writeStringValue("sku", cartItem.sku);
        writer.writeNumberValue("subTotal", cartItem.subTotal);
        writer.writeStringValue("subTotalFormatted", cartItem.subTotalFormatted);
        writer.writeAdditionalData(cartItem.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCheckoutPageResponse(writer: SerializationWriter, checkoutPageResponse: Partial<CheckoutPageResponse> | undefined | null = {}) : void {
    if (checkoutPageResponse) {
        writer.writeNumberValue("createdAt", checkoutPageResponse.createdAt);
        writer.writeBooleanValue("embed", checkoutPageResponse.embed);
        writer.writeNumberValue("expiresAt", checkoutPageResponse.expiresAt);
        writer.writeStringValue("id", checkoutPageResponse.id);
        writer.writeStringValue("state", checkoutPageResponse.state);
        writer.writeStringValue("type", checkoutPageResponse.type);
        writer.writeStringValue("url", checkoutPageResponse.url);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCompleteCheckoutRequest(writer: SerializationWriter, completeCheckoutRequest: Partial<CompleteCheckoutRequest> | undefined | null = {}) : void {
    if (completeCheckoutRequest) {
        writer.writeStringValue("channel", completeCheckoutRequest.channel);
        writer.writeStringValue("customerNumber", completeCheckoutRequest.customerNumber);
        writer.writeEnumValue<UserTypes>("userType", completeCheckoutRequest.userType);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCompleteCheckoutResponse(writer: SerializationWriter, completeCheckoutResponse: Partial<CompleteCheckoutResponse> | undefined | null = {}) : void {
    if (completeCheckoutResponse) {
        writer.writeStringValue("confirmationId", completeCheckoutResponse.confirmationId);
        writer.writeStringValue("orderNumber", completeCheckoutResponse.orderNumber);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeConfigResponse(writer: SerializationWriter, configResponse: Partial<ConfigResponse> | undefined | null = {}) : void {
    if (configResponse) {
        writer.writeStringValue("mapsApiKey", configResponse.mapsApiKey);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeContact(writer: SerializationWriter, contact: Partial<Contact> | undefined | null = {}) : void {
    if (contact) {
        writer.writeEnumValue<ContactType>("contactType", contact.contactType);
        writer.writeBooleanValue("default", contact.defaultEscaped);
        writer.writeStringValue("emailAddress", contact.emailAddress);
        writer.writeStringValue("externalId", contact.externalId);
        writer.writeStringValue("fax", contact.fax);
        writer.writeStringValue("firstName", contact.firstName);
        writer.writeStringValue("fullName", contact.fullName);
        writer.writeStringValue("lastName", contact.lastName);
        writer.writeStringValue("phone", contact.phone);
        writer.writeStringValue("title", contact.title);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeContent(writer: SerializationWriter, content: Partial<Content> | undefined | null = {}) : void {
    if (content) {
        writer.writeObjectValue<Card>("card", content.card, serializeCard);
        writer.writeObjectValue<Customer>("customer", content.customer, serializeCustomer);
        writer.writeObjectValue<Invoice>("invoice", content.invoice, serializeInvoice);
        writer.writeObjectValue<Subscription2>("subscription", content.subscription, serializeSubscription2);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateApiAccessResponse(writer: SerializationWriter, createApiAccessResponse: Partial<CreateApiAccessResponse> | undefined | null = {}) : void {
    if (createApiAccessResponse) {
        writer.writeObjectValue<ApiAccess>("apiAccess", createApiAccessResponse.apiAccess, serializeApiAccess);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateCartRequest(writer: SerializationWriter, createCartRequest: Partial<CreateCartRequest> | undefined | null = {}) : void {
    if (createCartRequest) {
        writer.writeStringValue("orderType", createCartRequest.orderType);
        writer.writeNumberValue("quantity", createCartRequest.quantity);
        writer.writeStringValue("sku", createCartRequest.sku);
        writer.writeStringValue("userId", createCartRequest.userId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateCustomerRequest(writer: SerializationWriter, createCustomerRequest: Partial<CreateCustomerRequest> | undefined | null = {}) : void {
    if (createCustomerRequest) {
        writer.writeBooleanValue("active", createCustomerRequest.active);
        writer.writeCollectionOfObjectValues<Address>("addresses", createCustomerRequest.addresses, serializeAddress);
        writer.writeStringValue("companyEmail", createCustomerRequest.companyEmail);
        writer.writeStringValue("companyFax", createCustomerRequest.companyFax);
        writer.writeStringValue("companyName", createCustomerRequest.companyName);
        writer.writeStringValue("companyPhone", createCustomerRequest.companyPhone);
        writer.writeCollectionOfObjectValues<Contact>("contacts", createCustomerRequest.contacts, serializeContact);
        writer.writeStringValue("customerNumber", createCustomerRequest.customerNumber);
        writer.writeStringValue("pricing", createCustomerRequest.pricing);
        writer.writeStringValue("repNumber", createCustomerRequest.repNumber);
        writer.writeStringValue("terms", createCustomerRequest.terms);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateManyCustomersRequest(writer: SerializationWriter, createManyCustomersRequest: Partial<CreateManyCustomersRequest> | undefined | null = {}) : void {
    if (createManyCustomersRequest) {
        writer.writeCollectionOfObjectValues<CreateCustomerRequest>("customers", createManyCustomersRequest.customers, serializeCreateCustomerRequest);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateManyProductsRequest(writer: SerializationWriter, createManyProductsRequest: Partial<CreateManyProductsRequest> | undefined | null = {}) : void {
    if (createManyProductsRequest) {
        writer.writeCollectionOfObjectValues<CreateProductRequest>("products", createManyProductsRequest.products, serializeCreateProductRequest);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateOrderRequest(writer: SerializationWriter, createOrderRequest: Partial<CreateOrderRequest> | undefined | null = {}) : void {
    if (createOrderRequest) {
        writer.writeStringValue("channel", createOrderRequest.channel);
        writer.writeStringValue("currencyCode", createOrderRequest.currencyCode);
        writer.writeObjectValue<Customer2>("customer", createOrderRequest.customer, serializeCustomer2);
        writer.writeStringValue("externalId", createOrderRequest.externalId);
        writer.writeCollectionOfObjectValues<OrderItem2>("items", createOrderRequest.items, serializeOrderItem2);
        writer.writeStringValue("notes", createOrderRequest.notes);
        writer.writeStringValue("orderStatus", createOrderRequest.orderStatus);
        writer.writeStringValue("orderType", createOrderRequest.orderType);
        writer.writeStringValue("originationCartId", createOrderRequest.originationCartId);
        writer.writeStringValue("poNumber", createOrderRequest.poNumber);
        writer.writeStringValue("priceTier", createOrderRequest.priceTier);
        writer.writeObjectValue<SalesRep2>("primarySalesRep", createOrderRequest.primarySalesRep, serializeSalesRep2);
        writer.writeObjectValue<ShippingDetails2>("shippingDetails", createOrderRequest.shippingDetails, serializeShippingDetails2);
        writer.writeObjectValue<SalesRep2>("takenBySalesRep", createOrderRequest.takenBySalesRep, serializeSalesRep2);
        writer.writeNumberValue("tax", createOrderRequest.tax);
        writer.writeStringValue("terms", createOrderRequest.terms);
        writer.writeObjectValue<User2>("user", createOrderRequest.user, serializeUser2);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateOrderResponse(writer: SerializationWriter, createOrderResponse: Partial<CreateOrderResponse> | undefined | null = {}) : void {
    if (createOrderResponse) {
        writer.writeObjectValue<Order>("data", createOrderResponse.data, serializeOrder);
        writer.writeCollectionOfObjectValues<Link>("links", createOrderResponse.links, serializeLink);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateOrganizationRequest(writer: SerializationWriter, createOrganizationRequest: Partial<CreateOrganizationRequest> | undefined | null = {}) : void {
    if (createOrganizationRequest) {
        writer.writeObjectValue<Organization2>("organization", createOrganizationRequest.organization, serializeOrganization2);
        writer.writeObjectValue<User>("user", createOrganizationRequest.user, serializeUser);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateOrganizationResponse(writer: SerializationWriter, createOrganizationResponse: Partial<CreateOrganizationResponse> | undefined | null = {}) : void {
    if (createOrganizationResponse) {
        writer.writeStringValue("identityOrganizationId", createOrganizationResponse.identityOrganizationId);
        writer.writeStringValue("name", createOrganizationResponse.name);
        writer.writeStringValue("organizationId", createOrganizationResponse.organizationId);
        writer.writeStringValue("slug", createOrganizationResponse.slug);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateOrUpdateDataMappingRequest(writer: SerializationWriter, createOrUpdateDataMappingRequest: Partial<CreateOrUpdateDataMappingRequest> | undefined | null = {}) : void {
    if (createOrUpdateDataMappingRequest) {
        writer.writeCollectionOfObjectValues<CreateOrUpdateDataMappingRequest_HeaderMappingField>("headerMappingFields", createOrUpdateDataMappingRequest.headerMappingFields, serializeCreateOrUpdateDataMappingRequest_HeaderMappingField);
        writer.writeStringValue("id", createOrUpdateDataMappingRequest.id);
        writer.writeBooleanValue("invertActiveProperty", createOrUpdateDataMappingRequest.invertActiveProperty);
        writer.writeStringValue("name", createOrUpdateDataMappingRequest.name);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateOrUpdateDataMappingRequest_HeaderMappingField(writer: SerializationWriter, createOrUpdateDataMappingRequest_HeaderMappingField: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField> | undefined | null = {}) : void {
    if (createOrUpdateDataMappingRequest_HeaderMappingField) {
        writer.writeStringValue("header", createOrUpdateDataMappingRequest_HeaderMappingField.header);
        writer.writeObjectValue<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping>("mapping", createOrUpdateDataMappingRequest_HeaderMappingField.mapping, serializeCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping(writer: SerializationWriter, createOrUpdateDataMappingRequest_HeaderMappingField_Mapping: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping> | undefined | null = {}) : void {
    if (createOrUpdateDataMappingRequest_HeaderMappingField_Mapping) {
        writer.writeStringValue("mappedHeaderName", createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.mappedHeaderName);
        writer.writeStringValue("uploadedHeaderName", createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.uploadedHeaderName);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateProductRequest(writer: SerializationWriter, createProductRequest: Partial<CreateProductRequest> | undefined | null = {}) : void {
    if (createProductRequest) {
        writer.writeBooleanValue("active", createProductRequest.active);
        writer.writeStringValue("category", createProductRequest.category);
        writer.writeStringValue("description", createProductRequest.description);
        writer.writeStringValue("dimensions", createProductRequest.dimensions);
        writer.writeStringValue("dimensionsUnit", createProductRequest.dimensionsUnit);
        writer.writeStringValue("imageName", createProductRequest.imageName);
        writer.writeCollectionOfPrimitiveValues<string>("images", createProductRequest.images);
        writer.writeNumberValue("minimumOrderQuantity", createProductRequest.minimumOrderQuantity);
        writer.writeStringValue("name", createProductRequest.name);
        writer.writeNumberValue("price", createProductRequest.price);
        writer.writeNumberValue("quantityIncrement", createProductRequest.quantityIncrement);
        writer.writeNumberValue("quantityOnHand", createProductRequest.quantityOnHand);
        writer.writeStringValue("sku", createProductRequest.sku);
        writer.writeStringValue("upc", createProductRequest.upc);
        writer.writeNumberValue("weight", createProductRequest.weight);
        writer.writeStringValue("weightUnit", createProductRequest.weightUnit);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateSalesRepRequest(writer: SerializationWriter, createSalesRepRequest: Partial<CreateSalesRepRequest> | undefined | null = {}) : void {
    if (createSalesRepRequest) {
        writer.writeStringValue("email", createSalesRepRequest.email);
        writer.writeStringValue("firstName", createSalesRepRequest.firstName);
        writer.writeStringValue("lastName", createSalesRepRequest.lastName);
        writer.writeStringValue("phoneNumber", createSalesRepRequest.phoneNumber);
        writer.writeStringValue("repNumber", createSalesRepRequest.repNumber);
        writer.writeCollectionOfPrimitiveValues<string>("roles", createSalesRepRequest.roles);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateUserRequest(writer: SerializationWriter, createUserRequest: Partial<CreateUserRequest> | undefined | null = {}) : void {
    if (createUserRequest) {
        writer.writeStringValue("email", createUserRequest.email);
        writer.writeStringValue("firstName", createUserRequest.firstName);
        writer.writeStringValue("identityOrganizationId", createUserRequest.identityOrganizationId);
        writer.writeStringValue("lastName", createUserRequest.lastName);
        writer.writeStringValue("password", createUserRequest.password);
        writer.writeStringValue("phoneNumber", createUserRequest.phoneNumber);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCreateUserResponse(writer: SerializationWriter, createUserResponse: Partial<CreateUserResponse> | undefined | null = {}) : void {
    if (createUserResponse) {
        writer.writeStringValue("email", createUserResponse.email);
        writer.writeStringValue("firstName", createUserResponse.firstName);
        writer.writeStringValue("lastName", createUserResponse.lastName);
        writer.writeStringValue("phoneNumber", createUserResponse.phoneNumber);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCustomer(writer: SerializationWriter, customer: Partial<Customer> | undefined | null = {}) : void {
    if (customer) {
        writer.writeBooleanValue("allow_direct_debit", customer.allowDirectDebit);
        writer.writeStringValue("auto_collection", customer.autoCollection);
        writer.writeObjectValue<IngAddress>("billing_address", customer.billingAddress, serializeIngAddress);
        writer.writeStringValue("card_status", customer.cardStatus);
        writer.writeStringValue("channel", customer.channel);
        writer.writeNumberValue("created_at", customer.createdAt);
        writer.writeStringValue("created_from_ip", customer.createdFromIp);
        writer.writeBooleanValue("deleted", customer.deleted);
        writer.writeStringValue("email", customer.email);
        writer.writeNumberValue("excess_payments", customer.excessPayments);
        writer.writeStringValue("first_name", customer.firstName);
        writer.writeStringValue("id", customer.id);
        writer.writeStringValue("last_name", customer.lastName);
        writer.writeNumberValue("mrr", customer.mrr);
        writer.writeNumberValue("net_term_days", customer.netTermDays);
        writer.writeStringValue("object", customer.object);
        writer.writeObjectValue<PaymentMethod>("payment_method", customer.paymentMethod, serializePaymentMethod);
        writer.writeStringValue("pii_cleared", customer.piiCleared);
        writer.writeStringValue("preferred_currency_code", customer.preferredCurrencyCode);
        writer.writeStringValue("primary_payment_source_id", customer.primaryPaymentSourceId);
        writer.writeNumberValue("promotional_credits", customer.promotionalCredits);
        writer.writeNumberValue("refundable_credits", customer.refundableCredits);
        writer.writeNumberValue("resource_version", customer.resourceVersion);
        writer.writeStringValue("taxability", customer.taxability);
        writer.writeNumberValue("unbilled_charges", customer.unbilledCharges);
        writer.writeNumberValue("updated_at", customer.updatedAt);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCustomer2(writer: SerializationWriter, customer2: Partial<Customer2> | undefined | null = {}) : void {
    if (customer2) {
        writer.writeObjectValue<Address>("billingAddress", customer2.billingAddress, serializeAddress);
        writer.writeStringValue("companyEmail", customer2.companyEmail);
        writer.writeStringValue("companyFax", customer2.companyFax);
        writer.writeStringValue("companyName", customer2.companyName);
        writer.writeStringValue("companyPhone", customer2.companyPhone);
        writer.writeStringValue("customerNumber", customer2.customerNumber);
        writer.writeObjectValue<Address>("shippingAddress", customer2.shippingAddress, serializeAddress);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeCustomer3(writer: SerializationWriter, customer3: Partial<Customer3> | undefined | null = {}) : void {
    if (customer3) {
        serializeBaseEntity(writer, customer3)
        writer.writeCollectionOfObjectValues<Address>("addresses", customer3.addresses, serializeAddress);
        writer.writeStringValue("companyEmail", customer3.companyEmail);
        writer.writeStringValue("companyFax", customer3.companyFax);
        writer.writeStringValue("companyName", customer3.companyName);
        writer.writeStringValue("companyPhone", customer3.companyPhone);
        writer.writeCollectionOfObjectValues<Contact>("contacts", customer3.contacts, serializeContact);
        writer.writeStringValue("customerId", customer3.customerId);
        writer.writeStringValue("customerNumber", customer3.customerNumber);
        writer.writeObjectValue<Address>("defaultBillingAddress", customer3.defaultBillingAddress, serializeAddress);
        writer.writeObjectValue<Address>("defaultShippingAddress", customer3.defaultShippingAddress, serializeAddress);
        writer.writeStringValue("organizationId", customer3.organizationId);
        writer.writeStringValue("pricing", customer3.pricing);
        writer.writeStringValue("repNumber", customer3.repNumber);
        writer.writeStringValue("terms", customer3.terms);
        writer.writeAdditionalData(customer3.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeDataMapping(writer: SerializationWriter, dataMapping: Partial<DataMapping> | undefined | null = {}) : void {
    if (dataMapping) {
        serializeBaseEntity(writer, dataMapping)
        writer.writeStringValue("description", dataMapping.description);
        writer.writeCollectionOfObjectValues<HeaderColumnMap>("headerMappingFields", dataMapping.headerMappingFields, serializeHeaderColumnMap);
        writer.writeStringValue("id", dataMapping.id);
        writer.writeBooleanValue("invertActiveProperty", dataMapping.invertActiveProperty);
        writer.writeStringValue("name", dataMapping.name);
        writer.writeStringValue("organizationId", dataMapping.organizationId);
        writer.writeAdditionalData(dataMapping.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeDeactivateSalesRepRequest(writer: SerializationWriter, deactivateSalesRepRequest: Partial<DeactivateSalesRepRequest> | undefined | null = {}) : void {
    if (deactivateSalesRepRequest) {
        writer.writeStringValue("organizationSalesRepId", deactivateSalesRepRequest.organizationSalesRepId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeDeletePriceTierRequest(writer: SerializationWriter, deletePriceTierRequest: Partial<DeletePriceTierRequest> | undefined | null = {}) : void {
    if (deletePriceTierRequest) {
        writer.writeStringValue("name", deletePriceTierRequest.name);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeDeleteSalesRepRequest(writer: SerializationWriter, deleteSalesRepRequest: Partial<DeleteSalesRepRequest> | undefined | null = {}) : void {
    if (deleteSalesRepRequest) {
        writer.writeStringValue("organizationSalesRepId", deleteSalesRepRequest.organizationSalesRepId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeDeleteTermRequest(writer: SerializationWriter, deleteTermRequest: Partial<DeleteTermRequest> | undefined | null = {}) : void {
    if (deleteTermRequest) {
        writer.writeEnumValue<Term>("term", deleteTermRequest.term);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeEnrichM2MResponse(writer: SerializationWriter, enrichM2MResponse: Partial<EnrichM2MResponse> | undefined | null = {}) : void {
    if (enrichM2MResponse) {
        writer.writeStringValue("organizationId", enrichM2MResponse.organizationId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeEntitlementsResponse(writer: SerializationWriter, entitlementsResponse: Partial<EntitlementsResponse> | undefined | null = {}) : void {
    if (entitlementsResponse) {
        writer.writeCollectionOfObjectValues<ListOfEntitlements>("entitlements", entitlementsResponse.entitlements, serializeListOfEntitlements);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeErrorResponse(writer: SerializationWriter, errorResponse: Partial<ErrorResponse> | undefined | null = {}) : void {
    if (errorResponse) {
        writer.writeObjectValue<ErrorResponse_errors>("errors", errorResponse.errors, serializeErrorResponse_errors);
        writer.writeStringValue("message", errorResponse.messageEscaped ?? "One or more errors occurred!");
        writer.writeNumberValue("statusCode", errorResponse.statusCode);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeErrorResponse_errors(writer: SerializationWriter, errorResponse_errors: Partial<ErrorResponse_errors> | undefined | null = {}) : void {
    if (errorResponse_errors) {
        writer.writeAdditionalData(errorResponse_errors.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeExistsResponse(writer: SerializationWriter, existsResponse: Partial<ExistsResponse> | undefined | null = {}) : void {
    if (existsResponse) {
        writer.writeBooleanValue("exitsInOrganization", existsResponse.exitsInOrganization);
        writer.writeBooleanValue("identityExists", existsResponse.identityExists);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeGetApiAccessResponse(writer: SerializationWriter, getApiAccessResponse: Partial<GetApiAccessResponse> | undefined | null = {}) : void {
    if (getApiAccessResponse) {
        writer.writeObjectValue<ApiAccess>("apiAccess", getApiAccessResponse.apiAccess, serializeApiAccess);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeGetOrganizationResponse(writer: SerializationWriter, getOrganizationResponse: Partial<GetOrganizationResponse> | undefined | null = {}) : void {
    if (getOrganizationResponse) {
        writer.writeCollectionOfObjectValues<ListOfEntitlements>("entitlements", getOrganizationResponse.entitlements, serializeListOfEntitlements);
        writer.writeObjectValue<Organization>("organization", getOrganizationResponse.organization, serializeOrganization);
        writer.writeCollectionOfObjectValues<Subscription>("subscriptions", getOrganizationResponse.subscriptions, serializeSubscription);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeHeaderColumnMap(writer: SerializationWriter, headerColumnMap: Partial<HeaderColumnMap> | undefined | null = {}) : void {
    if (headerColumnMap) {
        writer.writeStringValue("header", headerColumnMap.header);
        writer.writeObjectValue<HeaderMapping>("mapping", headerColumnMap.mapping, serializeHeaderMapping);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeHeaderMapping(writer: SerializationWriter, headerMapping: Partial<HeaderMapping> | undefined | null = {}) : void {
    if (headerMapping) {
        writer.writeStringValue("mappedHeaderName", headerMapping.mappedHeaderName);
        writer.writeStringValue("uploadedHeaderName", headerMapping.uploadedHeaderName);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeImageAssignment(writer: SerializationWriter, imageAssignment: Partial<ImageAssignment> | undefined | null = {}) : void {
    if (imageAssignment) {
        writer.writeEnumValue<ImageFormat>("format", imageAssignment.format);
        writer.writeStringValue("imageId", imageAssignment.imageId);
        writer.writeStringValue("imageName", imageAssignment.imageName);
        writer.writeNumberValue("position", imageAssignment.position);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeIngAddress(writer: SerializationWriter, ingAddress: Partial<IngAddress> | undefined | null = {}) : void {
    if (ingAddress) {
        writer.writeStringValue("city", ingAddress.city);
        writer.writeStringValue("country", ingAddress.country);
        writer.writeStringValue("first_name", ingAddress.firstName);
        writer.writeStringValue("last_name", ingAddress.lastName);
        writer.writeStringValue("line1", ingAddress.line1);
        writer.writeStringValue("object", ingAddress.object);
        writer.writeStringValue("state", ingAddress.state);
        writer.writeStringValue("state_code", ingAddress.stateCode);
        writer.writeStringValue("validation_status", ingAddress.validationStatus);
        writer.writeNumberValue("zip", ingAddress.zip);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeInternalErrorResponse(writer: SerializationWriter, internalErrorResponse: Partial<InternalErrorResponse> | undefined | null = {}) : void {
    if (internalErrorResponse) {
        writer.writeNumberValue("code", internalErrorResponse.code);
        writer.writeStringValue("note", internalErrorResponse.note ?? "See application log for stack trace.");
        writer.writeStringValue("reason", internalErrorResponse.reason ?? "Something unexpected has happened");
        writer.writeStringValue("status", internalErrorResponse.status ?? "Internal Server Error!");
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeInvoice(writer: SerializationWriter, invoice: Partial<Invoice> | undefined | null = {}) : void {
    if (invoice) {
        writer.writeObjectValue("adjustment_credit_notes", invoice.adjustmentCreditNotes);
        writer.writeNumberValue("amount_adjusted", invoice.amountAdjusted);
        writer.writeNumberValue("amount_due", invoice.amountDue);
        writer.writeNumberValue("amount_paid", invoice.amountPaid);
        writer.writeNumberValue("amount_to_collect", invoice.amountToCollect);
        writer.writeObjectValue("applied_credits", invoice.appliedCredits);
        writer.writeStringValue("base_currency_code", invoice.baseCurrencyCode);
        writer.writeObjectValue<IngAddress>("billing_address", invoice.billingAddress, serializeIngAddress);
        writer.writeStringValue("channel", invoice.channel);
        writer.writeNumberValue("credits_applied", invoice.creditsApplied);
        writer.writeStringValue("currency_code", invoice.currencyCode);
        writer.writeStringValue("customer_id", invoice.customerId);
        writer.writeNumberValue("date", invoice.date);
        writer.writeBooleanValue("deleted", invoice.deleted);
        writer.writeNumberValue("due_date", invoice.dueDate);
        writer.writeObjectValue("dunning_attempts", invoice.dunningAttempts);
        writer.writeNumberValue("exchange_rate", invoice.exchangeRate);
        writer.writeBooleanValue("first_invoice", invoice.firstInvoice);
        writer.writeNumberValue("generated_at", invoice.generatedAt);
        writer.writeBooleanValue("has_advance_charges", invoice.hasAdvanceCharges);
        writer.writeNumberValue("id", invoice.id);
        writer.writeBooleanValue("is_gifted", invoice.isGifted);
        writer.writeObjectValue("issued_credit_notes", invoice.issuedCreditNotes);
        writer.writeCollectionOfObjectValues<LineItem>("line_items", invoice.lineItems, serializeLineItem);
        writer.writeObjectValue("linked_orders", invoice.linkedOrders);
        writer.writeCollectionOfObjectValues<LinkedPayment>("linked_payments", invoice.linkedPayments, serializeLinkedPayment);
        writer.writeNumberValue("net_term_days", invoice.netTermDays);
        writer.writeNumberValue("new_sales_amount", invoice.newSalesAmount);
        writer.writeStringValue("object", invoice.object);
        writer.writeNumberValue("paid_at", invoice.paidAt);
        writer.writeStringValue("price_type", invoice.priceType);
        writer.writeBooleanValue("recurring", invoice.recurring);
        writer.writeNumberValue("resource_version", invoice.resourceVersion);
        writer.writeNumberValue("round_off_amount", invoice.roundOffAmount);
        writer.writeObjectValue<IngAddress>("shipping_address", invoice.shippingAddress, serializeIngAddress);
        writer.writeStringValue("status", invoice.status);
        writer.writeStringValue("subscription_id", invoice.subscriptionId);
        writer.writeNumberValue("sub_total", invoice.subTotal);
        writer.writeNumberValue("tax", invoice.tax);
        writer.writeBooleanValue("term_finalized", invoice.termFinalized);
        writer.writeNumberValue("total", invoice.total);
        writer.writeNumberValue("updated_at", invoice.updatedAt);
        writer.writeNumberValue("write_off_amount", invoice.writeOffAmount);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeIOrder(writer: SerializationWriter, iOrder: Partial<IOrder> | undefined | null = {}) : void {
    if (iOrder) {
        writer.writeStringValue("channel", iOrder.channel);
        writer.writeStringValue("currencyCode", iOrder.currencyCode);
        writer.writeObjectValue<Customer2>("customer", iOrder.customer, serializeCustomer2);
        writer.writeStringValue("externalId", iOrder.externalId);
        writer.writeStringValue("id", iOrder.id);
        writer.writeCollectionOfObjectValues<OrderItem>("items", iOrder.items, serializeOrderItem);
        writer.writeStringValue("notes", iOrder.notes);
        writer.writeDateValue("orderDate", iOrder.orderDate);
        writer.writeStringValue("orderNumber", iOrder.orderNumber);
        writer.writeStringValue("orderStatus", iOrder.orderStatus);
        writer.writeNumberValue("orderTotal", iOrder.orderTotal);
        writer.writeStringValue("orderTotalFormatted", iOrder.orderTotalFormatted);
        writer.writeStringValue("orderType", iOrder.orderType);
        writer.writeStringValue("organizationId", iOrder.organizationId);
        writer.writeStringValue("originationCartId", iOrder.originationCartId);
        writer.writeStringValue("poNumber", iOrder.poNumber);
        writer.writeStringValue("priceTier", iOrder.priceTier);
        writer.writeObjectValue<SalesRep2>("primarySalesRep", iOrder.primarySalesRep, serializeSalesRep2);
        writer.writeObjectValue<ShippingDetails>("shippingDetails", iOrder.shippingDetails, serializeShippingDetails);
        writer.writeStringValue("shippingFormatted", iOrder.shippingFormatted);
        writer.writeNumberValue("subtotal", iOrder.subtotal);
        writer.writeStringValue("subtotalFormatted", iOrder.subtotalFormatted);
        writer.writeObjectValue<SalesRep2>("takenBySalesRep", iOrder.takenBySalesRep, serializeSalesRep2);
        writer.writeNumberValue("tax", iOrder.tax);
        writer.writeStringValue("taxFormatted", iOrder.taxFormatted);
        writer.writeStringValue("terms", iOrder.terms);
        writer.writeObjectValue<User2>("user", iOrder.user, serializeUser2);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeLineItem(writer: SerializationWriter, lineItem: Partial<LineItem> | undefined | null = {}) : void {
    if (lineItem) {
        writer.writeNumberValue("amount", lineItem.amount);
        writer.writeStringValue("customer_id", lineItem.customerId);
        writer.writeNumberValue("date_from", lineItem.dateFrom);
        writer.writeNumberValue("date_to", lineItem.dateTo);
        writer.writeStringValue("description", lineItem.description);
        writer.writeNumberValue("discount_amount", lineItem.discountAmount);
        writer.writeStringValue("entity_id", lineItem.entityId);
        writer.writeStringValue("entity_type", lineItem.entityType);
        writer.writeStringValue("id", lineItem.id);
        writer.writeBooleanValue("is_taxed", lineItem.isTaxed);
        writer.writeNumberValue("item_level_discount_amount", lineItem.itemLevelDiscountAmount);
        writer.writeStringValue("object", lineItem.object);
        writer.writeStringValue("pricing_model", lineItem.pricingModel);
        writer.writeNumberValue("quantity", lineItem.quantity);
        writer.writeStringValue("subscription_id", lineItem.subscriptionId);
        writer.writeNumberValue("tax_amount", lineItem.taxAmount);
        writer.writeStringValue("tax_exempt_reason", lineItem.taxExemptReason);
        writer.writeNumberValue("unit_amount", lineItem.unitAmount);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeLink(writer: SerializationWriter, link: Partial<Link> | undefined | null = {}) : void {
    if (link) {
        writer.writeStringValue("action", link.action);
        writer.writeStringValue("href", link.href);
        writer.writeStringValue("rel", link.rel);
        writer.writeCollectionOfPrimitiveValues<string>("types", link.types);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeLinkedPayment(writer: SerializationWriter, linkedPayment: Partial<LinkedPayment> | undefined | null = {}) : void {
    if (linkedPayment) {
        writer.writeNumberValue("applied_amount", linkedPayment.appliedAmount);
        writer.writeNumberValue("applied_at", linkedPayment.appliedAt);
        writer.writeNumberValue("txn_amount", linkedPayment.txnAmount);
        writer.writeNumberValue("txn_date", linkedPayment.txnDate);
        writer.writeStringValue("txn_id", linkedPayment.txnId);
        writer.writeStringValue("txn_status", linkedPayment.txnStatus);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeListOfEntitlements(writer: SerializationWriter, listOfEntitlements: Partial<ListOfEntitlements> | undefined | null = {}) : void {
    if (listOfEntitlements) {
        writer.writeObjectValue<SubscriptionEntitlement>("subscriptionEntitlement", listOfEntitlements.subscriptionEntitlement, serializeSubscriptionEntitlement);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeListOrganizationSalesRepsResponse(writer: SerializationWriter, listOrganizationSalesRepsResponse: Partial<ListOrganizationSalesRepsResponse> | undefined | null = {}) : void {
    if (listOrganizationSalesRepsResponse) {
        writer.writeBooleanValue("active", listOrganizationSalesRepsResponse.active);
        writer.writeStringValue("email", listOrganizationSalesRepsResponse.email);
        writer.writeStringValue("firstName", listOrganizationSalesRepsResponse.firstName);
        writer.writeStringValue("lastName", listOrganizationSalesRepsResponse.lastName);
        writer.writeStringValue("organizationId", listOrganizationSalesRepsResponse.organizationId);
        writer.writeStringValue("organizationSalesRepId", listOrganizationSalesRepsResponse.organizationSalesRepId);
        writer.writeStringValue("phoneNumber", listOrganizationSalesRepsResponse.phoneNumber);
        writer.writeStringValue("repNumber", listOrganizationSalesRepsResponse.repNumber);
        writer.writeStringValue("salesRepId", listOrganizationSalesRepsResponse.salesRepId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeListSalesRepOrganizationsResponse(writer: SerializationWriter, listSalesRepOrganizationsResponse: Partial<ListSalesRepOrganizationsResponse> | undefined | null = {}) : void {
    if (listSalesRepOrganizationsResponse) {
        writer.writeCollectionOfObjectValues<OrganizationSalesRep>("salesRepOrganizations", listSalesRepOrganizationsResponse.salesRepOrganizations, serializeOrganizationSalesRep);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeListUserOrganizationsResponse(writer: SerializationWriter, listUserOrganizationsResponse: Partial<ListUserOrganizationsResponse> | undefined | null = {}) : void {
    if (listUserOrganizationsResponse) {
        writer.writeCollectionOfObjectValues<Organization>("organizations", listUserOrganizationsResponse.organizations, serializeOrganization);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeNotFound(writer: SerializationWriter, notFound: Partial<NotFound> | undefined | null = {}) : void {
    if (notFound) {
        writer.writeNumberValue("statusCode", notFound.statusCode);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOk(writer: SerializationWriter, ok: Partial<Ok> | undefined | null = {}) : void {
    if (ok) {
        writer.writeNumberValue("statusCode", ok.statusCode);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOrder(writer: SerializationWriter, order: Partial<Order> | undefined | null = {}) : void {
    if (order) {
        writer.writeStringValue("id", order.id);
        writer.writeStringValue("orderNumber", order.orderNumber);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOrderDetailsResponse(writer: SerializationWriter, orderDetailsResponse: Partial<OrderDetailsResponse> | undefined | null = {}) : void {
    if (orderDetailsResponse) {
        writer.writeObjectValue<IOrder>("data", orderDetailsResponse.data, serializeIOrder);
        writer.writeCollectionOfObjectValues<Link>("links", orderDetailsResponse.links, serializeLink);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOrderItem(writer: SerializationWriter, orderItem: Partial<OrderItem> | undefined | null = {}) : void {
    if (orderItem) {
        writer.writeStringValue("externalId", orderItem.externalId);
        writer.writeStringValue("imageName", orderItem.imageName);
        writer.writeNumberValue("minimumOrderQuantity", orderItem.minimumOrderQuantity);
        writer.writeNumberValue("price", orderItem.price);
        writer.writeStringValue("priceFormatted", orderItem.priceFormatted);
        writer.writeStringValue("productName", orderItem.productName);
        writer.writeNumberValue("quantity", orderItem.quantity);
        writer.writeNumberValue("quantityIncrement", orderItem.quantityIncrement);
        writer.writeStringValue("sku", orderItem.sku);
        writer.writeNumberValue("subTotal", orderItem.subTotal);
        writer.writeStringValue("subTotalFormatted", orderItem.subTotalFormatted);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOrderItem2(writer: SerializationWriter, orderItem2: Partial<OrderItem2> | undefined | null = {}) : void {
    if (orderItem2) {
        writer.writeStringValue("externalId", orderItem2.externalId);
        writer.writeStringValue("imageName", orderItem2.imageName);
        writer.writeNumberValue("minimumOrderQuantity", orderItem2.minimumOrderQuantity);
        writer.writeNumberValue("price", orderItem2.price);
        writer.writeStringValue("productName", orderItem2.productName);
        writer.writeNumberValue("quantity", orderItem2.quantity);
        writer.writeNumberValue("quantityIncrement", orderItem2.quantityIncrement);
        writer.writeStringValue("sku", orderItem2.sku);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOrganization(writer: SerializationWriter, organization: Partial<Organization> | undefined | null = {}) : void {
    if (organization) {
        serializeBaseEntity(writer, organization)
        writer.writeObjectValue<AccountSetup>("accountSetup", organization.accountSetup, serializeAccountSetup);
        writer.writeStringValue("billingAddress1", organization.billingAddress1);
        writer.writeStringValue("billingAddress2", organization.billingAddress2);
        writer.writeStringValue("billingCity", organization.billingCity);
        writer.writeStringValue("billingCountry", organization.billingCountry);
        writer.writeStringValue("billingPostalCode", organization.billingPostalCode);
        writer.writeStringValue("billingStateProvince", organization.billingStateProvince);
        writer.writeStringValue("email", organization.email);
        writer.writeStringValue("identityOrganizationId", organization.identityOrganizationId);
        writer.writeNumberValue("latitude", organization.latitude);
        writer.writeStringValue("logoUrl", organization.logoUrl);
        writer.writeNumberValue("longitude", organization.longitude);
        writer.writeStringValue("name", organization.name);
        writer.writeStringValue("organizationId", organization.organizationId);
        writer.writeStringValue("phoneNumber", organization.phoneNumber);
        writer.writeCollectionOfObjectValues<PriceTier>("priceTiers", organization.priceTiers, serializePriceTier);
        writer.writeCollectionOfObjectValues<Subscription>("subscriptions", organization.subscriptions, serializeSubscription);
        if(organization.terms)
        writer.writeEnumValue<Term>("terms", ...organization.terms);
        writer.writeStringValue("websiteUrl", organization.websiteUrl);
        writer.writeAdditionalData(organization.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOrganization2(writer: SerializationWriter, organization2: Partial<Organization2> | undefined | null = {}) : void {
    if (organization2) {
        writer.writeStringValue("billingAddress1", organization2.billingAddress1);
        writer.writeStringValue("billingAddress2", organization2.billingAddress2);
        writer.writeStringValue("billingCity", organization2.billingCity);
        writer.writeStringValue("billingCountry", organization2.billingCountry);
        writer.writeStringValue("billingPostalCode", organization2.billingPostalCode);
        writer.writeStringValue("billingStateProvince", organization2.billingStateProvince);
        writer.writeStringValue("email", organization2.email);
        writer.writeStringValue("name", organization2.name);
        writer.writeStringValue("phoneNumber", organization2.phoneNumber);
        writer.writeStringValue("website", organization2.website);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeOrganizationSalesRep(writer: SerializationWriter, organizationSalesRep: Partial<OrganizationSalesRep> | undefined | null = {}) : void {
    if (organizationSalesRep) {
        serializeBaseEntity(writer, organizationSalesRep)
        writer.writeStringValue("email", organizationSalesRep.email);
        writer.writeStringValue("firstName", organizationSalesRep.firstName);
        writer.writeStringValue("identityOrganizationId", organizationSalesRep.identityOrganizationId);
        writer.writeStringValue("identityProviderUserId", organizationSalesRep.identityProviderUserId);
        writer.writeStringValue("lastName", organizationSalesRep.lastName);
        writer.writeStringValue("organizationId", organizationSalesRep.organizationId);
        writer.writeStringValue("organizationName", organizationSalesRep.organizationName);
        writer.writeStringValue("organizationSalesRepId", organizationSalesRep.organizationSalesRepId);
        writer.writeStringValue("phoneNumber", organizationSalesRep.phoneNumber);
        writer.writeStringValue("repNumber", organizationSalesRep.repNumber);
        writer.writeStringValue("salesRepId", organizationSalesRep.salesRepId);
        writer.writeAdditionalData(organizationSalesRep.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializePagedResultOfCustomer(writer: SerializationWriter, pagedResultOfCustomer: Partial<PagedResultOfCustomer> | undefined | null = {}) : void {
    if (pagedResultOfCustomer) {
        writer.writeNumberValue("currentPage", pagedResultOfCustomer.currentPage);
        writer.writeCollectionOfObjectValues<Customer3>("data", pagedResultOfCustomer.data, serializeCustomer3);
        writer.writeNumberValue("pageSize", pagedResultOfCustomer.pageSize);
        writer.writeNumberValue("totalPages", pagedResultOfCustomer.totalPages);
        writer.writeNumberValue("totalRecords", pagedResultOfCustomer.totalRecords);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializePagedResultOfIOrder(writer: SerializationWriter, pagedResultOfIOrder: Partial<PagedResultOfIOrder> | undefined | null = {}) : void {
    if (pagedResultOfIOrder) {
        writer.writeNumberValue("currentPage", pagedResultOfIOrder.currentPage);
        writer.writeCollectionOfObjectValues<IOrder>("data", pagedResultOfIOrder.data, serializeIOrder);
        writer.writeNumberValue("pageSize", pagedResultOfIOrder.pageSize);
        writer.writeNumberValue("totalPages", pagedResultOfIOrder.totalPages);
        writer.writeNumberValue("totalRecords", pagedResultOfIOrder.totalRecords);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializePagedResultOfProduct(writer: SerializationWriter, pagedResultOfProduct: Partial<PagedResultOfProduct> | undefined | null = {}) : void {
    if (pagedResultOfProduct) {
        writer.writeNumberValue("currentPage", pagedResultOfProduct.currentPage);
        writer.writeCollectionOfObjectValues<Product>("data", pagedResultOfProduct.data, serializeProduct);
        writer.writeNumberValue("pageSize", pagedResultOfProduct.pageSize);
        writer.writeNumberValue("totalPages", pagedResultOfProduct.totalPages);
        writer.writeNumberValue("totalRecords", pagedResultOfProduct.totalRecords);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializePaymentMethod(writer: SerializationWriter, paymentMethod: Partial<PaymentMethod> | undefined | null = {}) : void {
    if (paymentMethod) {
        writer.writeStringValue("gateway", paymentMethod.gateway);
        writer.writeStringValue("gateway_account_id", paymentMethod.gatewayAccountId);
        writer.writeStringValue("object", paymentMethod.object);
        writer.writeStringValue("reference_id", paymentMethod.referenceId);
        writer.writeStringValue("status", paymentMethod.status);
        writer.writeStringValue("type", paymentMethod.type);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializePortalSessionLinkResponse(writer: SerializationWriter, portalSessionLinkResponse: Partial<PortalSessionLinkResponse> | undefined | null = {}) : void {
    if (portalSessionLinkResponse) {
        writer.writeStringValue("sessionUrl", portalSessionLinkResponse.sessionUrl);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializePriceTier(writer: SerializationWriter, priceTier: Partial<PriceTier> | undefined | null = {}) : void {
    if (priceTier) {
        writer.writeEnumValue<CurrencyCode>("currencyCode", priceTier.currencyCode);
        writer.writeStringValue("name", priceTier.name);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeProblemDetails(writer: SerializationWriter, problemDetails: Partial<ProblemDetails> | undefined | null = {}) : void {
    if (problemDetails) {
        writer.writeStringValue("detail", problemDetails.detail);
        writer.writeCollectionOfObjectValues<ProblemDetails_Error>("errors", problemDetails.errors, serializeProblemDetails_Error);
        writer.writeStringValue("instance", problemDetails.instance ?? "/api/route");
        writer.writeNumberValue("status", problemDetails.status);
        writer.writeStringValue("title", problemDetails.title ?? "One or more validation errors occurred.");
        writer.writeStringValue("traceId", problemDetails.traceId ?? "0HMPNHL0JHL76:00000001");
        writer.writeStringValue("type", problemDetails.type ?? "https://www.rfc-editor.org/rfc/rfc7231#section-6.5.1");
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeProblemDetails_Error(writer: SerializationWriter, problemDetails_Error: Partial<ProblemDetails_Error> | undefined | null = {}) : void {
    if (problemDetails_Error) {
        writer.writeStringValue("code", problemDetails_Error.code);
        writer.writeStringValue("name", problemDetails_Error.name ?? "Error or field name");
        writer.writeStringValue("reason", problemDetails_Error.reason ?? "Error reason");
        writer.writeStringValue("severity", problemDetails_Error.severity);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeProduct(writer: SerializationWriter, product: Partial<Product> | undefined | null = {}) : void {
    if (product) {
        serializeBaseEntity(writer, product)
        writer.writeStringValue("category", product.category);
        writer.writeEnumValue<CurrencyCode>("currencyCode", product.currencyCode);
        writer.writeStringValue("description", product.description);
        writer.writeStringValue("dimensions", product.dimensions);
        writer.writeStringValue("dimensionsUnit", product.dimensionsUnit);
        writer.writeObjectValue<Product_dynamicProperties>("dynamicProperties", product.dynamicProperties, serializeProduct_dynamicProperties);
        writer.writeStringValue("imageName", product.imageName);
        writer.writeCollectionOfObjectValues<ImageAssignment>("images", product.images, serializeImageAssignment);
        writer.writeNumberValue("minimumOrderQuantity", product.minimumOrderQuantity);
        writer.writeStringValue("name", product.name);
        writer.writeStringValue("organizationId", product.organizationId);
        writer.writeNumberValue("price", product.price);
        writer.writeCollectionOfObjectValues<ProductPrice>("prices", product.prices, serializeProductPrice);
        writer.writeStringValue("productId", product.productId);
        writer.writeEnumValue<ProductType>("productType", product.productType);
        writer.writeNumberValue("quantityIncrement", product.quantityIncrement);
        writer.writeNumberValue("quantityOnHand", product.quantityOnHand);
        writer.writeStringValue("sku", product.sku);
        writer.writeStringValue("upc", product.upc);
        writer.writeNumberValue("weight", product.weight);
        writer.writeStringValue("weightUnit", product.weightUnit);
        writer.writeAdditionalData(product.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeProduct_dynamicProperties(writer: SerializationWriter, product_dynamicProperties: Partial<Product_dynamicProperties> | undefined | null = {}) : void {
    if (product_dynamicProperties) {
        writer.writeAdditionalData(product_dynamicProperties.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeProductPrice(writer: SerializationWriter, productPrice: Partial<ProductPrice> | undefined | null = {}) : void {
    if (productPrice) {
        writer.writeNumberValue("atQuantity", productPrice.atQuantity);
        writer.writeNumberValue("price", productPrice.price);
        writer.writeStringValue("tier", productPrice.tier);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeReadmeAuthResponse(writer: SerializationWriter, readmeAuthResponse: Partial<ReadmeAuthResponse> | undefined | null = {}) : void {
    if (readmeAuthResponse) {
        writer.writeStringValue("readmeAuthUrl", readmeAuthResponse.readmeAuthUrl);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeRep(writer: SerializationWriter, rep: Partial<Rep> | undefined | null = {}) : void {
    if (rep) {
        writer.writeStringValue("email", rep.email);
        writer.writeStringValue("firstName", rep.firstName);
        writer.writeStringValue("fullName", rep.fullName);
        writer.writeStringValue("lastName", rep.lastName);
        writer.writeStringValue("organizationSalesRepId", rep.organizationSalesRepId);
        writer.writeStringValue("phoneNumber", rep.phoneNumber);
        writer.writeStringValue("repNumber", rep.repNumber);
        writer.writeStringValue("salesRepId", rep.salesRepId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeSalesRep(writer: SerializationWriter, salesRep: Partial<SalesRep> | undefined | null = {}) : void {
    if (salesRep) {
        serializeBaseEntity(writer, salesRep)
        writer.writeStringValue("email", salesRep.email);
        writer.writeStringValue("firstName", salesRep.firstName);
        writer.writeStringValue("fullName", salesRep.fullName);
        writer.writeStringValue("identityProvider", salesRep.identityProvider);
        writer.writeStringValue("identityProviderUserId", salesRep.identityProviderUserId);
        writer.writeStringValue("lastName", salesRep.lastName);
        writer.writeStringValue("phoneNumber", salesRep.phoneNumber);
        writer.writeStringValue("pictureUrl", salesRep.pictureUrl);
        writer.writeStringValue("salesRepId", salesRep.salesRepId);
        writer.writeAdditionalData(salesRep.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeSalesRep2(writer: SerializationWriter, salesRep2: Partial<SalesRep2> | undefined | null = {}) : void {
    if (salesRep2) {
        writer.writeStringValue("email", salesRep2.email);
        writer.writeStringValue("firstName", salesRep2.firstName);
        writer.writeStringValue("fullName", salesRep2.fullName);
        writer.writeStringValue("lastName", salesRep2.lastName);
        writer.writeStringValue("organizationSalesRepId", salesRep2.organizationSalesRepId);
        writer.writeStringValue("phoneNumber", salesRep2.phoneNumber);
        writer.writeStringValue("repNumber", salesRep2.repNumber);
        writer.writeStringValue("salesRepId", salesRep2.salesRepId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeShippingDetails(writer: SerializationWriter, shippingDetails: Partial<ShippingDetails> | undefined | null = {}) : void {
    if (shippingDetails) {
        writer.writeStringValue("method", shippingDetails.method);
        writer.writeNumberValue("total", shippingDetails.total);
        writer.writeStringValue("totalFormatted", shippingDetails.totalFormatted);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeShippingDetails2(writer: SerializationWriter, shippingDetails2: Partial<ShippingDetails2> | undefined | null = {}) : void {
    if (shippingDetails2) {
        writer.writeStringValue("method", shippingDetails2.method);
        writer.writeNumberValue("total", shippingDetails2.total);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeShoppingCart(writer: SerializationWriter, shoppingCart: Partial<ShoppingCart> | undefined | null = {}) : void {
    if (shoppingCart) {
        serializeBaseEntity(writer, shoppingCart)
        writer.writeObjectValue<Address>("billingAddress", shoppingCart.billingAddress, serializeAddress);
        writer.writeCollectionOfObjectValues<CartItem>("cartItems", shoppingCart.cartItems, serializeCartItem);
        writer.writeStringValue("companyEmail", shoppingCart.companyEmail);
        writer.writeStringValue("companyFax", shoppingCart.companyFax);
        writer.writeStringValue("companyName", shoppingCart.companyName);
        writer.writeStringValue("companyPhone", shoppingCart.companyPhone);
        writer.writeStringValue("currencyCode", shoppingCart.currencyCode);
        writer.writeStringValue("customerNumber", shoppingCart.customerNumber);
        writer.writeStringValue("notes", shoppingCart.notes);
        writer.writeStringValue("orderStatus", shoppingCart.orderStatus);
        writer.writeNumberValue("orderTotal", shoppingCart.orderTotal);
        writer.writeStringValue("orderTotalFormatted", shoppingCart.orderTotalFormatted);
        writer.writeStringValue("orderType", shoppingCart.orderType);
        writer.writeStringValue("organizationId", shoppingCart.organizationId);
        writer.writeStringValue("poNumber", shoppingCart.poNumber);
        writer.writeStringValue("priceTier", shoppingCart.priceTier);
        writer.writeObjectValue<Rep>("primarySalesRep", shoppingCart.primarySalesRep, serializeRep);
        writer.writeNumberValue("shipping", shoppingCart.shipping);
        writer.writeObjectValue<Address>("shippingAddress", shoppingCart.shippingAddress, serializeAddress);
        writer.writeStringValue("shippingFormatted", shoppingCart.shippingFormatted);
        writer.writeStringValue("shippingMethod", shoppingCart.shippingMethod);
        writer.writeStringValue("shoppingCartId", shoppingCart.shoppingCartId);
        writer.writeNumberValue("subtotal", shoppingCart.subtotal);
        writer.writeStringValue("subtotalFormatted", shoppingCart.subtotalFormatted);
        writer.writeObjectValue<Rep>("takenBySalesRep", shoppingCart.takenBySalesRep, serializeRep);
        writer.writeNumberValue("tax", shoppingCart.tax);
        writer.writeStringValue("taxFormatted", shoppingCart.taxFormatted);
        writer.writeStringValue("terms", shoppingCart.terms);
        writer.writeObjectValue<User3>("user", shoppingCart.user, serializeUser3);
        writer.writeAdditionalData(shoppingCart.additionalData);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeStep(writer: SerializationWriter, step: Partial<Step> | undefined | null = {}) : void {
    if (step) {
        writer.writeBooleanValue("isComplete", step.isComplete);
        writer.writeStringValue("name", step.name);
        writer.writeNumberValue("order", step.order);
        writer.writeStringValue("stepId", step.stepId);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeSubscription(writer: SerializationWriter, subscription: Partial<Subscription> | undefined | null = {}) : void {
    if (subscription) {
        writer.writeStringValue("id", subscription.id);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeSubscription2(writer: SerializationWriter, subscription2: Partial<Subscription2> | undefined | null = {}) : void {
    if (subscription2) {
        writer.writeNumberValue("activated_at", subscription2.activatedAt);
        writer.writeNumberValue("billing_period", subscription2.billingPeriod);
        writer.writeStringValue("billing_period_unit", subscription2.billingPeriodUnit);
        writer.writeStringValue("channel", subscription2.channel);
        writer.writeNumberValue("created_at", subscription2.createdAt);
        writer.writeStringValue("created_from_ip", subscription2.createdFromIp);
        writer.writeStringValue("currency_code", subscription2.currencyCode);
        writer.writeNumberValue("current_term_end", subscription2.currentTermEnd);
        writer.writeNumberValue("current_term_start", subscription2.currentTermStart);
        writer.writeStringValue("customer_id", subscription2.customerId);
        writer.writeBooleanValue("deleted", subscription2.deleted);
        writer.writeNumberValue("due_invoices_count", subscription2.dueInvoicesCount);
        writer.writeBooleanValue("has_scheduled_advance_invoices", subscription2.hasScheduledAdvanceInvoices);
        writer.writeBooleanValue("has_scheduled_changes", subscription2.hasScheduledChanges);
        writer.writeStringValue("id", subscription2.id);
        writer.writeNumberValue("mrr", subscription2.mrr);
        writer.writeNumberValue("next_billing_at", subscription2.nextBillingAt);
        writer.writeStringValue("object", subscription2.object);
        writer.writeNumberValue("resource_version", subscription2.resourceVersion);
        writer.writeObjectValue<IngAddress>("shipping_address", subscription2.shippingAddress, serializeIngAddress);
        writer.writeNumberValue("started_at", subscription2.startedAt);
        writer.writeStringValue("status", subscription2.status);
        writer.writeCollectionOfObjectValues<SubscriptionItem>("subscription_items", subscription2.subscriptionItems, serializeSubscriptionItem);
        writer.writeNumberValue("updated_at", subscription2.updatedAt);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeSubscriptionCreatedRequest(writer: SerializationWriter, subscriptionCreatedRequest: Partial<SubscriptionCreatedRequest> | undefined | null = {}) : void {
    if (subscriptionCreatedRequest) {
        writer.writeStringValue("api_version", subscriptionCreatedRequest.apiVersion);
        writer.writeObjectValue<Content>("content", subscriptionCreatedRequest.content, serializeContent);
        writer.writeStringValue("event_type", subscriptionCreatedRequest.eventType);
        writer.writeStringValue("id", subscriptionCreatedRequest.id);
        writer.writeStringValue("object", subscriptionCreatedRequest.object);
        writer.writeNumberValue("occurred_at", subscriptionCreatedRequest.occurredAt);
        writer.writeStringValue("source", subscriptionCreatedRequest.source);
        writer.writeStringValue("webhook_status", subscriptionCreatedRequest.webhookStatus);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeSubscriptionEntitlement(writer: SerializationWriter, subscriptionEntitlement: Partial<SubscriptionEntitlement> | undefined | null = {}) : void {
    if (subscriptionEntitlement) {
        writer.writeStringValue("featureId", subscriptionEntitlement.featureId);
        writer.writeStringValue("featureName", subscriptionEntitlement.featureName);
        writer.writeStringValue("id", subscriptionEntitlement.id);
        writer.writeBooleanValue("isOverridden", subscriptionEntitlement.isOverridden);
        writer.writeStringValue("name", subscriptionEntitlement.name);
        writer.writeStringValue("subscriptionId", subscriptionEntitlement.subscriptionId);
        writer.writeStringValue("value", subscriptionEntitlement.value);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeSubscriptionItem(writer: SerializationWriter, subscriptionItem: Partial<SubscriptionItem> | undefined | null = {}) : void {
    if (subscriptionItem) {
        writer.writeNumberValue("amount", subscriptionItem.amount);
        writer.writeNumberValue("free_quantity", subscriptionItem.freeQuantity);
        writer.writeStringValue("item_price_id", subscriptionItem.itemPriceId);
        writer.writeStringValue("item_type", subscriptionItem.itemType);
        writer.writeStringValue("object", subscriptionItem.object);
        writer.writeNumberValue("quantity", subscriptionItem.quantity);
        writer.writeNumberValue("unit_price", subscriptionItem.unitPrice);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUpdateCustomerRequest(writer: SerializationWriter, updateCustomerRequest: Partial<UpdateCustomerRequest> | undefined | null = {}) : void {
    if (updateCustomerRequest) {
        writer.writeObjectValue<Address>("billingAddress", updateCustomerRequest.billingAddress, serializeAddress);
        writer.writeStringValue("companyEmail", updateCustomerRequest.companyEmail);
        writer.writeStringValue("companyFax", updateCustomerRequest.companyFax);
        writer.writeStringValue("companyName", updateCustomerRequest.companyName);
        writer.writeStringValue("companyPhone", updateCustomerRequest.companyPhone);
        writer.writeStringValue("customerNumber", updateCustomerRequest.customerNumber);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUpdateItemRequest(writer: SerializationWriter, updateItemRequest: Partial<UpdateItemRequest> | undefined | null = {}) : void {
    if (updateItemRequest) {
        writer.writeNumberValue("quantity", updateItemRequest.quantity);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUpdatePriceTierRequest(writer: SerializationWriter, updatePriceTierRequest: Partial<UpdatePriceTierRequest> | undefined | null = {}) : void {
    if (updatePriceTierRequest) {
        writer.writeStringValue("currencyCode", updatePriceTierRequest.currencyCode);
        writer.writeStringValue("newName", updatePriceTierRequest.newName);
        writer.writeStringValue("oldName", updatePriceTierRequest.oldName);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUpdateShippingRequest(writer: SerializationWriter, updateShippingRequest: Partial<UpdateShippingRequest> | undefined | null = {}) : void {
    if (updateShippingRequest) {
        writer.writeStringValue("method", updateShippingRequest.method);
        writer.writeObjectValue<Address>("shippingAddress", updateShippingRequest.shippingAddress, serializeAddress);
        writer.writeNumberValue("total", updateShippingRequest.total);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUser(writer: SerializationWriter, user: Partial<User> | undefined | null = {}) : void {
    if (user) {
        writer.writeStringValue("email", user.email);
        writer.writeStringValue("firstName", user.firstName);
        writer.writeStringValue("lastName", user.lastName);
        writer.writeStringValue("password", user.password);
        writer.writeStringValue("phoneNumber", user.phoneNumber);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUser2(writer: SerializationWriter, user2: Partial<User2> | undefined | null = {}) : void {
    if (user2) {
        writer.writeStringValue("identityProviderUserId", user2.identityProviderUserId);
        writer.writeStringValue("userId", user2.userId);
        writer.writeEnumValue<UserTypes>("userType", user2.userType);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUser3(writer: SerializationWriter, user3: Partial<User3> | undefined | null = {}) : void {
    if (user3) {
        writer.writeStringValue("identityProviderUserId", user3.identityProviderUserId);
        writer.writeStringValue("userId", user3.userId);
        writer.writeEnumValue<UserTypes>("userType", user3.userType);
    }
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
// @ts-ignore
export function serializeUserExistsResponse(writer: SerializationWriter, userExistsResponse: Partial<UserExistsResponse> | undefined | null = {}) : void {
    if (userExistsResponse) {
        writer.writeBooleanValue("userExists", userExistsResponse.userExists);
    }
}
export interface ShippingDetails extends Parsable {
    /**
     * The method property
     */
    method?: string | null;
    /**
     * The total property
     */
    total?: number | null;
    /**
     * The totalFormatted property
     */
    totalFormatted?: string | null;
}
export interface ShippingDetails2 extends Parsable {
    /**
     * The method property
     */
    method?: string | null;
    /**
     * The total property
     */
    total?: number | null;
}
export interface ShoppingCart extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The billingAddress property
     */
    billingAddress?: Address | null;
    /**
     * The cartItems property
     */
    cartItems?: CartItem[] | null;
    /**
     * The companyEmail property
     */
    companyEmail?: string | null;
    /**
     * The companyFax property
     */
    companyFax?: string | null;
    /**
     * The companyName property
     */
    companyName?: string | null;
    /**
     * The companyPhone property
     */
    companyPhone?: string | null;
    /**
     * The currencyCode property
     */
    currencyCode?: string | null;
    /**
     * The customerNumber property
     */
    customerNumber?: string | null;
    /**
     * The notes property
     */
    notes?: string | null;
    /**
     * The orderStatus property
     */
    orderStatus?: string | null;
    /**
     * The orderTotal property
     */
    orderTotal?: number | null;
    /**
     * The orderTotalFormatted property
     */
    orderTotalFormatted?: string | null;
    /**
     * The orderType property
     */
    orderType?: string | null;
    /**
     * The organizationId property
     */
    organizationId?: string | null;
    /**
     * The poNumber property
     */
    poNumber?: string | null;
    /**
     * The priceTier property
     */
    priceTier?: string | null;
    /**
     * The primarySalesRep property
     */
    primarySalesRep?: Rep | null;
    /**
     * The shipping property
     */
    shipping?: number | null;
    /**
     * The shippingAddress property
     */
    shippingAddress?: Address | null;
    /**
     * The shippingFormatted property
     */
    shippingFormatted?: string | null;
    /**
     * The shippingMethod property
     */
    shippingMethod?: string | null;
    /**
     * The shoppingCartId property
     */
    shoppingCartId?: string | null;
    /**
     * The subtotal property
     */
    subtotal?: number | null;
    /**
     * The subtotalFormatted property
     */
    subtotalFormatted?: string | null;
    /**
     * The takenBySalesRep property
     */
    takenBySalesRep?: Rep | null;
    /**
     * The tax property
     */
    tax?: number | null;
    /**
     * The taxFormatted property
     */
    taxFormatted?: string | null;
    /**
     * The terms property
     */
    terms?: string | null;
    /**
     * The user property
     */
    user?: User3 | null;
}
export interface Step extends Parsable {
    /**
     * The isComplete property
     */
    isComplete?: boolean | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The order property
     */
    order?: number | null;
    /**
     * The stepId property
     */
    stepId?: string | null;
}
export interface Subscription extends Parsable {
    /**
     * The id property
     */
    id?: string | null;
}
export interface Subscription2 extends Parsable {
    /**
     * The activated_at property
     */
    activatedAt?: number | null;
    /**
     * The billing_period property
     */
    billingPeriod?: number | null;
    /**
     * The billing_period_unit property
     */
    billingPeriodUnit?: string | null;
    /**
     * The channel property
     */
    channel?: string | null;
    /**
     * The created_at property
     */
    createdAt?: number | null;
    /**
     * The created_from_ip property
     */
    createdFromIp?: string | null;
    /**
     * The currency_code property
     */
    currencyCode?: string | null;
    /**
     * The current_term_end property
     */
    currentTermEnd?: number | null;
    /**
     * The current_term_start property
     */
    currentTermStart?: number | null;
    /**
     * The customer_id property
     */
    customerId?: string | null;
    /**
     * The deleted property
     */
    deleted?: boolean | null;
    /**
     * The due_invoices_count property
     */
    dueInvoicesCount?: number | null;
    /**
     * The has_scheduled_advance_invoices property
     */
    hasScheduledAdvanceInvoices?: boolean | null;
    /**
     * The has_scheduled_changes property
     */
    hasScheduledChanges?: boolean | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The mrr property
     */
    mrr?: number | null;
    /**
     * The next_billing_at property
     */
    nextBillingAt?: number | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The resource_version property
     */
    resourceVersion?: number | null;
    /**
     * The shipping_address property
     */
    shippingAddress?: IngAddress | null;
    /**
     * The started_at property
     */
    startedAt?: number | null;
    /**
     * The status property
     */
    status?: string | null;
    /**
     * The subscription_items property
     */
    subscriptionItems?: SubscriptionItem[] | null;
    /**
     * The updated_at property
     */
    updatedAt?: number | null;
}
export interface SubscriptionCreatedRequest extends Parsable {
    /**
     * The api_version property
     */
    apiVersion?: string | null;
    /**
     * The content property
     */
    content?: Content | null;
    /**
     * The event_type property
     */
    eventType?: string | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The occurred_at property
     */
    occurredAt?: number | null;
    /**
     * The source property
     */
    source?: string | null;
    /**
     * The webhook_status property
     */
    webhookStatus?: string | null;
}
export interface SubscriptionEntitlement extends Parsable {
    /**
     * The featureId property
     */
    featureId?: string | null;
    /**
     * The featureName property
     */
    featureName?: string | null;
    /**
     * The id property
     */
    id?: string | null;
    /**
     * The isOverridden property
     */
    isOverridden?: boolean | null;
    /**
     * The name property
     */
    name?: string | null;
    /**
     * The subscriptionId property
     */
    subscriptionId?: string | null;
    /**
     * The value property
     */
    value?: string | null;
}
export interface SubscriptionItem extends Parsable {
    /**
     * The amount property
     */
    amount?: number | null;
    /**
     * The free_quantity property
     */
    freeQuantity?: number | null;
    /**
     * The item_price_id property
     */
    itemPriceId?: string | null;
    /**
     * The item_type property
     */
    itemType?: string | null;
    /**
     * The object property
     */
    object?: string | null;
    /**
     * The quantity property
     */
    quantity?: number | null;
    /**
     * The unit_price property
     */
    unitPrice?: number | null;
}
export type Term = (typeof TermObject)[keyof typeof TermObject];
export interface UpdateCustomerRequest extends Parsable {
    /**
     * The billingAddress property
     */
    billingAddress?: Address | null;
    /**
     * The companyEmail property
     */
    companyEmail?: string | null;
    /**
     * The companyFax property
     */
    companyFax?: string | null;
    /**
     * The companyName property
     */
    companyName?: string | null;
    /**
     * The companyPhone property
     */
    companyPhone?: string | null;
    /**
     * The customerNumber property
     */
    customerNumber?: string | null;
}
/**
 * Represents a request to update an item in a shopping cart.
 */
export interface UpdateItemRequest extends Parsable {
    /**
     * Gets or sets the new quantity of the product in the cart.
     */
    quantity?: number | null;
}
export interface UpdatePriceTierRequest extends Parsable {
    /**
     * The currencyCode property
     */
    currencyCode?: string | null;
    /**
     * The newName property
     */
    newName?: string | null;
    /**
     * The oldName property
     */
    oldName?: string | null;
}
export interface UpdateShippingRequest extends Parsable {
    /**
     * The method property
     */
    method?: string | null;
    /**
     * The shippingAddress property
     */
    shippingAddress?: Address | null;
    /**
     * The total property
     */
    total?: number | null;
}
export interface User extends Parsable {
    /**
     * Gets or sets the email of the sales representative.
     */
    email?: string | null;
    /**
     * Gets or sets the first name of the sales representative.
     */
    firstName?: string | null;
    /**
     * Gets or sets the last name of the sales representative.
     */
    lastName?: string | null;
    /**
     * Gets or sets the password of the sales representative, this will be ignored if they already have an existing identity account.
     */
    password?: string | null;
    /**
     * Gets or sets the phone number of the sales representative.
     */
    phoneNumber?: string | null;
}
export interface User2 extends Parsable {
    /**
     * The identityProviderUserId property
     */
    identityProviderUserId?: string | null;
    /**
     * The userId property
     */
    userId?: string | null;
    /**
     * The userType property
     */
    userType?: UserTypes | null;
}
export interface User3 extends Parsable {
    /**
     * The identityProviderUserId property
     */
    identityProviderUserId?: string | null;
    /**
     * The userId property
     */
    userId?: string | null;
    /**
     * The userType property
     */
    userType?: UserTypes | null;
}
export interface UserExistsResponse extends Parsable {
    /**
     * The userExists property
     */
    userExists?: boolean | null;
}
export type UserTypes = (typeof UserTypesObject)[keyof typeof UserTypesObject];
export const AddressTypeObject = {
    Company: "Company",
    Billing: "Billing",
    Shipping: "Shipping",
} as const;
export const ContactTypeObject = {
    Company: "Company",
    Billing: "Billing",
    Shipping: "Shipping",
    Buyer: "Buyer",
} as const;
export const CurrencyCodeObject = {
    USD: "USD",
    CAD: "CAD",
    MXN: "MXN",
    EUR: "EUR",
} as const;
export const ImageFormatObject = {
    Unknown: "unknown",
    Jpg: "jpg",
    Png: "png",
    Jpeg: "jpeg",
    Webp: "webp",
    Gif: "gif",
    Svg: "svg",
} as const;
export const ProductTypeObject = {
    DefaultEscaped: "Default",
} as const;
export const TermObject = {
    NET15: "NET15",
    NET30: "NET30",
    NET45: "NET45",
    NET60: "NET60",
    NET90: "NET90",
    NET120: "NET120",
    CREDIT: "CREDIT",
    CASH: "CASH",
    COD: "COD",
    PREPAID: "PREPAID",
} as const;
export const UserTypesObject = {
    None: "None",
    SalesRep: "SalesRep",
    Customer: "Customer",
    Employee: "Employee",
} as const;
/* tslint:enable */
/* eslint-enable */
