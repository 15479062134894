/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createUserExistsResponseFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type UserExistsResponse } from '../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/users/exists
 */
export interface ExistsRequestBuilder extends BaseRequestBuilder<ExistsRequestBuilder> {
    /**
     * Check if a user exists
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<UserExistsResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<ExistsRequestBuilderGetQueryParameters> | undefined) : Promise<UserExistsResponse | undefined>;
    /**
     * Check if a user exists
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<ExistsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Check if a user exists
 */
export interface ExistsRequestBuilderGetQueryParameters {
    email?: string;
}
/**
 * Uri template for the request builder.
 */
export const ExistsRequestBuilderUriTemplate = "{+baseurl}/api/users/exists?email={email}";
/**
 * Metadata for all the requests in the request builder.
 */
export const ExistsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: ExistsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createUserExistsResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
