/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createPagedResultOfProductFromDiscriminatorValue, createProductFromDiscriminatorValue, serializeCreateProductRequest, serializeProduct, type CreateProductRequest, type ErrorResponse, type InternalErrorResponse, type PagedResultOfProduct, type Product } from '../../models/index.js';
// @ts-ignore
import { BulkRequestBuilderRequestsMetadata, type BulkRequestBuilder } from './bulk/index.js';
// @ts-ignore
import { type WithSkuItemRequestBuilder, WithSkuItemRequestBuilderNavigationMetadata, WithSkuItemRequestBuilderRequestsMetadata } from './item/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/products
 */
export interface ProductsRequestBuilder extends BaseRequestBuilder<ProductsRequestBuilder> {
    /**
     * The bulk property
     */
    get bulk(): BulkRequestBuilder;
    /**
     * Gets an item from the MomentumAPI.api.products.item collection
     * @param sku Unique identifier of the item
     * @returns {WithSkuItemRequestBuilder}
     */
     bySku(sku: string) : WithSkuItemRequestBuilder;
    /**
     * List Products
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<PagedResultOfProduct>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<ProductsRequestBuilderGetQueryParameters> | undefined) : Promise<PagedResultOfProduct | undefined>;
    /**
     * Create or update a Product using an upsert based on Sku
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Product>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     put(body: CreateProductRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Product | undefined>;
    /**
     * List Products
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<ProductsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
    /**
     * Create or update a Product using an upsert based on Sku
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPutRequestInformation(body: CreateProductRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * List Products
 */
export interface ProductsRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the filters for the request, this is optionalAcceptable operators are: starts with, contains, not contains, ends with, equals, not equals
     */
    filters?: string[];
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    /**
     * Gets or sets the field to sort on. Default value is "RepNumber".
     */
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const ProductsRequestBuilderUriTemplate = "{+baseurl}/api/products?filters={filters}&pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const ProductsRequestBuilderNavigationMetadata: Record<Exclude<keyof ProductsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    bySku: {
        requestsMetadata: WithSkuItemRequestBuilderRequestsMetadata,
        navigationMetadata: WithSkuItemRequestBuilderNavigationMetadata,
        pathParametersMappings: ["sku"],
    },
    bulk: {
        requestsMetadata: BulkRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const ProductsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: ProductsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createPagedResultOfProductFromDiscriminatorValue,
    },
    put: {
        uriTemplate: ProductsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createProductFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateProductRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
