import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { initializeKindeClient } from '@/utils/kindeAuth'; // Import the initialize function
import { useAuthStore } from '@/stores/authStore';
import { createPinia } from 'pinia';
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import { definePreset } from '@primevue/themes';

import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

import '@/assets/styles.scss';
import '@/assets/tailwind.css';

const app = createApp(App);
const pinia = createPinia()

// Handle redirects after login
router.beforeEach(async (to, from, next) => {
    if (to.path === '/auth/callback') {
        const authStore = useAuthStore();
        await authStore.checkAuth(); // Check if the user is authenticated
        return next('/'); // Redirect to home after handling callback
    }
    next();
});

Sentry.init({
    app,
    dsn: "https://67e4fbc58c4534309fc5fdf7682c39ed@o4507896391991296.ingest.us.sentry.io/4508262999130112",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.use(PrimeVue, {
    ripple: true,
    theme: {
        preset: definePreset(Aura, {
            semantic: {
                primary: {
                    50: "{sky.50}",
                    100: "{sky.100}",
                    200: "{sky.200}",
                    300: "{sky.300}",
                    400: "{sky.400}",
                    500: "{sky.500}",
                    600: "{sky.600}",
                    700: "{sky.700}",
                    800: "{sky.800}",
                    900: "{sky.900}",
                    950: "{sky.950}"
                }
            }
        }),
        options: {
            prefix: 'p',
            darkModeSelector: false || 'none',
            cssLayer: false,

        }
    }
});
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);


initializeKindeClient().then(() => {
    // Kinde client is now initialized, so you can proceed with app setup
    app.use(pinia);
    app.use(router);
    app.mount('#app');
});
