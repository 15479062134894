/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createInternalErrorResponseFromDiscriminatorValue, createNotFoundFromDiscriminatorValue, type InternalErrorResponse, type NotFound } from '../../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/customers/{customerNumber}/activate
 */
export interface ActivateRequestBuilder extends BaseRequestBuilder<ActivateRequestBuilder> {
    /**
     * Activate Customer
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @throws {NotFound} error when the service returns a 404 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     patch(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * Activate Customer
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPatchRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const ActivateRequestBuilderUriTemplate = "{+baseurl}/api/customers/{customerNumber}/activate";
/**
 * Metadata for all the requests in the request builder.
 */
export const ActivateRequestBuilderRequestsMetadata: RequestsMetadata = {
    patch: {
        uriTemplate: ActivateRequestBuilderUriTemplate,
        responseBodyContentType: "application/json, application/problem+json",
        errorMappings: {
            404: createNotFoundFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendNoResponseContent",
    },
};
/* tslint:enable */
/* eslint-enable */
