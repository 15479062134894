/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createOkFromDiscriminatorValue, serializeOk, serializeSubscriptionCreatedRequest, type ErrorResponse, type InternalErrorResponse, type Ok, type SubscriptionCreatedRequest } from '../../../../models/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/subscriptions/created
 */
export interface CreatedRequestBuilder extends BaseRequestBuilder<CreatedRequestBuilder> {
    /**
     * Subscription Created
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: SubscriptionCreatedRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * Subscription Created
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: SubscriptionCreatedRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const CreatedRequestBuilderUriTemplate = "{+baseurl}/api/organizations/subscriptions/created";
/**
 * Metadata for all the requests in the request builder.
 */
export const CreatedRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: CreatedRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeSubscriptionCreatedRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
