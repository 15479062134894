/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { OrganizationsRequestBuilderRequestsMetadata, type OrganizationsRequestBuilder } from './organizations/index.js';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/salesreps
 */
export interface SalesrepsRequestBuilder extends BaseRequestBuilder<SalesrepsRequestBuilder> {
    /**
     * The organizations property
     */
    get organizations(): OrganizationsRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const SalesrepsRequestBuilderUriTemplate = "{+baseurl}/api/salesreps";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const SalesrepsRequestBuilderNavigationMetadata: Record<Exclude<keyof SalesrepsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    organizations: {
        requestsMetadata: OrganizationsRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
